import { 
  GET_USER,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
} from '../actions/types';

const INITIAL_STATE = {
  user: [],
  updatingUser: false,
  updateUserOK: '',
  updateUserERROR: ''
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_USER:
      return { ...state, user: action.payload  };
    case UPDATE_USER:
      return { ...state, updatingUser: true, updateUserOK: '', updateUserERROR: ''};
    case UPDATE_USER_SUCCESS:
      return { ...state, updatingUser: false, updateUserOK: action.payload, updateUserERROR: ''};
    case UPDATE_USER_ERROR:
      return { ...state, updatingUser: false, updateUserOK: '', updateUserERROR: action.payload};
    default:
      return state;
  }
}