import React, { Component } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { Bars } from  'react-loader-spinner';
import Select from 'react-select';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

let self = null;

const columns = [
  {
    id: 'date',
    Header: 'Período',
    Cell: row => {
      return (
        <span>
          {self.state.startDate !== ''
            ? 'Desde ' + self.state.startDate.toLocaleDateString()
            : ''}{' '}
          {self.state.endDate !== ''
            ? ' hasta ' + self.state.endDate.toLocaleDateString()
            : ''}
        </span>
      );
    }
  },
  {
    id: 'product',
    Header: 'Producto',
    Cell: row => {
    }
  },
  {
    id: 'amount',
    Header: 'Total',
    Cell: row => {
      return self.state.startDate !== '' ? self.amount : '';
    }
  }
];

class SalesByPeriodProduct extends Component {
  constructor(props) {
    super(props);
    self = this;

    this.state = {
      bookFilter: '',
      startDate: '',
      endDate: ''
    };
  }

  renderSelectProduct = () => {
    if (this.props.books.length > 0) {
      let optionsBook = [
        {
          value: '',
          label: 'Todos'
        }
      ];
      this.props.books.forEach(function(book) {
        optionsBook.push({
          value: book.idBook,
          label: book.books.title
        });
      });
      let selectedOpt = optionsBook.findIndex(
        x => x.value === self.state.bookFilter
      );
      return (
        <Select
          value={optionsBook[selectedOpt]}
          options={optionsBook}
          onChange={this.handleBookChange}
        />
      );
    }
    return <Bars color="#17a2b8" height={30} width={30} />;
  };

  handleBookChange = obj => {
    this.setState({
      bookFilter: obj.value
    });
  };

  renderStatusTable = () => {
    let filteredData = null;

    if (this.props.sales.length > 0) {
      const datos = this.props.sales
        .map(sale => {
          return sale;
        })
        .reduce((acc, currvalue) => {
          const book = currvalue.id;
          const bookCount = acc[book] ? acc[book] + 1 : 1;

          return {
            ...acc,
            [book]: bookCount
          };
        }, {});
      filteredData = datos;
    }

    let myArr = [];

    if (filteredData !== null) {
      myArr.push(filteredData);
    }

    return (
      <ReactTable
        data={myArr}
        columns={columns}
        defaultPageSize={15}
        showPageSizeOptions={false}
        className="-striped"
        previousText="Anterior"
        nextText="Siguiente"
        loadingText="Cargando..."
        noDataText="No se encontraron filas"
        pageText="Pagina"
        ofText="de"
        rowsText="filas"
      />
    );
  };

  clearFilters = () => {
    this.setState({
      bookFilter: '',
      pointOfSaleFilter: '',
      startDate: '',
      endDate: ''
    });
  };

  handleApplyDatePicker = picker => {
    this.setState({
      startDate: picker.startDate._d,
      endDate: picker.endDate._d
    });
  };

  renderDateFilter = () => {
    return (
      <div className="datePickerContainer">
        <DateRangePicker
          showDropdowns
          onApply={(ev, picker) => this.handleApplyDatePicker(picker)}
          locale={{
            cancelLabel: 'Cancelar',
            applyLabel: 'Aceptar',
            daysOfWeek: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
            monthNames: [
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre'
            ]
          }}
        >
          <button className="btn btn-primary">Seleccionar fecha</button>
        </DateRangePicker>
      </div>
    );
  };

  render() {
    return (
      <div className="mainHomeContainer">
        <div className="filterBoxContainer">
          <div className="formGroup">
            <span className="formLabel">Filtrar por producto</span>
            {this.renderSelectProduct()}
          </div>
          <div className="formGroup dateFilterHolder">
            <span className="formLabel">Filtrar por fecha</span>
            {this.renderDateFilter()}
          </div>
          <div className="formGroup datesHolder">
            <span>
              {this.state.startDate === ''
                ? ''
                : `Desde: ${this.state.startDate.toLocaleDateString()}`}
            </span>
            <span>
              {this.state.endDate === ''
                ? ''
                : `Hasta: ${this.state.endDate.toLocaleDateString()}`}
            </span>
          </div>
          <div className="formGroup">
            <br />
            <button className="btn btn-info" onClick={this.clearFilters}>
              Limpiar
            </button>
          </div>
        </div>
        <div className="homeTableHolder">{this.renderStatusTable()}</div>
      </div>
    );
  }
}

export default SalesByPeriodProduct;
