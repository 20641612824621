import React, { Component } from 'react';
import Switch from 'react-switch';
import Modal from 'react-responsive-modal';
import Select from 'react-select';
import { Progress } from 'react-sweet-progress';
import 'react-sweet-progress/lib/style.css';
import $ from 'jquery';
import ReactAudioPlayer from 'react-audio-player';
import { FormGroup, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from './withRouter';
import {
  getBook,
  updateBook,
  imgUpload,
  audioUpload,
  getAllCategories
} from '../actions';
import { Navigate, Link } from 'react-router-dom';
import Header from './Header';
import { Bars } from  'react-loader-spinner';
import logo from '../assets/logoHeader.png';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import './BookEdit.css';
let self = null;


// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};


const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  // padding: `10px`,
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  // minWidth: `800px !important`,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "grey",
  // padding: grid,
  // styles we need to apply on draggables
  ...draggableStyle
});
const grid = 5;

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  // padding: grid,
  // width: '100%',
  // padding: `0 10px 0 0`
});


class BookEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: '',
      audioSample: '',
      audioComplete: [],
      description: '',
      msg: '',
      outstanding: false,
      novelty: false,
      title: '',
      editorial: '',
      image: null,
      wideImage: null,
      checked: false,
      catID: '',
      price: '',
      isbn: '',
      appleIdentifier: '',
      author: '',
      bookStyle: '',
      bookType: '',
      openChapter: false,
      duration: ''
    };

    self = this;
    this.onDragEnd = this.onDragEnd.bind(this);
  }
  componentDidMount() {
    this.props.getAllCategories();
    const { params } = this.props;
    this.props.getBook(params.id, () => {
      this.setState({
        id: params.id,
        title: this.props.book.title,
        editorial: this.props.book.editorial,
        description: this.props.book.description,
        checked: this.props.book.outstanding,
        novelty: this.props.book.novelty,
        image: this.props.book.image,
        wideImage: this.props.book.wideImage,
        audioSample: this.props.book.audioSample,
        audioComplete: this.props.book.audioComplete.map((k, i) => ({
          id: decodeURI(k).split('.mp3').shift().split('%2F').pop() + '.mp3',
          chapter: i,
          content: k
        })),
        catID: this.props.book.catId,
        price: this.props.book.price,
        isbn: this.props.book.isbn,
        appleIdentifier: this.props.book.appleIdentifier,
        author: this.props.book.author,
        bookStyle: this.props.book.bookStyle,
        bookType: this.state.bookType,
        duration: this.props.book.duration
      });
    });
  }

  handleTitleChange = e => {
    this.setState({
      title: e.target.value
    });
  };
  handleEditorialChange = e => {
    this.setState({
      editorial: e.target.value
    });
  };

  handleOptionChange = checked => {
    this.setState({ checked });
  };

  handleNoveltyChange = novelty => {
    this.setState({ novelty });
  };

  handleDescChange = e => {
    this.setState({
      description: e.target.value
    });
  };

  handlePriceChange = e => {
    this.setState({
      price: e.target.value
    });
  };

  handleIsbnChange = e => {
    this.setState({
      isbn: e.target.value
    });
  };

  handleAppleIdentifierChange = e => {
    this.setState({
      appleIdentifier: e.target.value
    });
  };

  handleAuthorChange = e => {
    this.setState({
      author: e.target.value
    });
  };

  handleDurationChange = e => {
    this.setState({
      duration: e.target.value
    });
  };

  handleImageChange = e => {
    this.props.imgUpload(
      e.target.files[0],
      this.state.title,
      '/trailers/',
      '/',
      () => {
        this.setState({
          image: this.props.imageURL
        });
      }
    );
  };

  handleWideImageChange = e => {
    this.props.imgUpload(
      e.target.files[0],
      this.state.title,
      '/trailers/',
      'wide/',
      () => {
        this.setState({
          wideImage: this.props.imageURL
        });
      }
    );
  };

  handleAudioSampleChange = (e, audioPath) => {
    this.props.audioUpload(
      e.target.files[0],
      this.state.title,
      audioPath,
      () => {
        this.setState({
          audioSample: this.props.audioSampleURL
        });
      }
    );
  };

  handleAudioCompleteChange = (e, audioPath) => {
    for (let key in e.target.files) {
      if (e.target.files.hasOwnProperty(key)) {
        this.props.audioUpload(
          e.target.files[key],
          this.state.title,
          audioPath,
          () => {
            this.setState(
              {
                audioComplete: [
                  ...this.state.audioComplete,
                  this.props.audioCompleteURL
                ]
              },
              function() {

              }
            );
          }
        );
      }
    }
  };

  handleSaveClick = () => {
    let obj = {
      audioSample: this.state.audioSample,
      audioComplete: this.state.audioComplete.map(e => e.content),
      description: this.state.description,
      id: this.state.id,
      image: this.state.image,
      wideImage: this.state.wideImage,
      name: this.state.title.toLowerCase(),
      outstanding: this.state.checked,
      novelty: this.state.novelty,
      title: this.state.title,
      editorial: this.state.editorial,
      catId: this.state.catID,
      price: this.state.price,
      isbn: this.state.isbn,
      appleIdentifier: this.state.appleIdentifier,
      author: this.state.author,
      bookStyle: this.state.bookStyle,
      bookType: this.state.bookType,
      duration: this.state.duration
    };
    this.props.updateBook(obj, () => {
      if (this.props.updateMsgOk !== '') {
        this.setMsgClass('successUpdate', this.props.msgOk);
      } else if (this.props.updateMsgError !== '') {
        this.setMsgClass('errorUpdate', this.props.msgError);
      }
    });
  };

  setMsgClass = (status, message) => {
    $('.updateMsg')
      .text(message)
      .addClass(status)
      .slideDown('fast', function() {
        setTimeout(() => {
          self.props.navigate('/books');
        }, 1000);
      });
  };

  renderImgProgressBar = () => {
    if (this.props.imageUploading) {
      return (
        <Progress
          percent={this.props.imgProgress}
          theme={{
            error: {
              symbol: this.props.imgProgress + '%',
              trailColor: 'pink',
              color: 'red'
            },
            default: {
              symbol: this.props.imgProgress + '%',
              trailColor: 'lightblue',
              color: 'blue'
            },
            active: {
              symbol: this.props.imgProgress + '%',
              trailColor: 'yellow',
              color: 'orange'
            },
            success: {
              symbol: this.props.imgProgress + '%',
              trailColor: 'lime',
              color: 'green'
            }
          }}
        />
      );
    }
  };

  renderWideImgProgressBar = () => {
    if (this.props.uploadingWideImg) {
      return (
        <Progress
          percent={this.props.imgProgress}
          theme={{
            error: {
              symbol: this.props.imgProgress + '%',
              trailColor: 'pink',
              color: 'red'
            },
            default: {
              symbol: this.props.imgProgress + '%',
              trailColor: 'lightblue',
              color: 'blue'
            },
            active: {
              symbol: this.props.imgProgress + '%',
              trailColor: 'yellow',
              color: 'orange'
            },
            success: {
              symbol: this.props.imgProgress + '%',
              trailColor: 'lime',
              color: 'green'
            }
          }}
        />
      );
    }
  };

  renderAudioSampleProgressBar = () => {
    if (this.props.audioSampleUploading) {
      return this.renderProgressBar('sample');
    }
  };

  renderAudioCompleteProgressBar = () => {
    if (this.props.audioCompleteUploading) {
      return this.renderProgressBar('complete');
    }
  };

  renderProgressBar = bookType => {
    const WICH_ONE =
      bookType === 'sample'
        ? this.props.audioSampleProgress
        : this.props.audioCompleteProgress;
    return (
      <Progress
        percent={WICH_ONE}
        theme={{
          error: {
            symbol: WICH_ONE + '%',
            trailColor: 'pink',
            color: 'red'
          },
          default: {
            symbol: WICH_ONE + '%',
            trailColor: 'lightblue',
            color: 'blue'
          },
          active: {
            symbol: WICH_ONE + '%',
            trailColor: 'yellow',
            color: 'orange'
          },
          success: {
            symbol: WICH_ONE + '%',
            trailColor: 'lime',
            color: 'green'
          }
        }}
      />
    );
  };

  btnStatusControl = () => {
    if (
      this.state.title === '' ||
      this.props.imageUploading ||
      this.props.audioSampleUploading ||
      this.props.audioCompleteUploading
    ) {
      return true;
    } else {
      return false;
    }
  };

  handleCatChange = obj => {
    this.setState({ catID: obj.value });
  };

  renderSelectCategory = () => {
    if (this.props.categories.length > 0) {
      let optionsCat = [];
      this.props.categories.forEach(function(cat) {
        optionsCat.push({
          value: cat.catId,
          label: cat.categorie.name
        });
      });

      let selectedOpt = optionsCat.findIndex(x => x.value === self.state.catID);

      return (
        <Select
          value={optionsCat[selectedOpt]}
          options={optionsCat}
          onChange={this.handleCatChange}
        />
      );
    }
    return <Bars color="#17a2b8" height={30} width={30} />;
  };

  renderSelectBookStyle = () => {
    const optionsStyle = [
      {
        value: 'Adaptación',
        label: 'Adaptación'
      },
      {
        value: 'Completo',
        label: 'Completo'
      }
    ];

    let selectedOpt = optionsStyle.findIndex(
      x => x.value === self.state.bookStyle
    );

    return (
      <Select
        value={optionsStyle[selectedOpt]}
        options={optionsStyle}
        onChange={this.handleBookStyleChange}
      />
    );
  };
  renderSelectBookType = () => {
    const optionsType = [
      { value: 'Narrado', label: 'Narrado' },
      { value: 'Narrado Plus', label: 'Narrado Plus' },
      { value: 'Dramatizado', label: 'Dramatizado' },
    ];

    let selectedOpt = optionsType.findIndex(
      x => x.value === self.state.bookType
    );

    return (
      <Select
        value={optionsType[selectedOpt]}
        options={optionsType}
        onChange={this.handleBookTypeChange}
      />
    );
  };

  handleBookStyleChange = obj => {
    this.setState({ bookStyle: obj.value });
  };
  handleBookTypeChange = obj => {
    this.setState({ bookType: obj.value });
  };

  renderCloseBtn = () => {
    if (this.props.audioCompleteUploading) {
      return <Bars color="#17a2b8" height={30} width={30} />;
    }
    return (
      <button
        className="btn btn-danger btn-sm"
        onClick={this.handleCloseChapterModal}
      >
        Cerrar
      </button>
    );
  };

  handleCloseChapterModal = () => {
    this.setState({
      openChapter: !this.state.openChapter
    });
  };

  handleDeleteChapter = index => {
    let arr = this.state.audioComplete;
    arr.splice(index, 1);
    this.setState({ audioComplete: arr });
  };

  // dragEnded = (param) => {
  //   const { source, destination } = param;
  //   let _arr = [...comments];
  //   //extracting the source item from the list
  //   const _item = _arr.splice(source.index, 1)[0];
  //   //inserting it at the destination index.
  //   _arr.splice(destination.index, 0, _item);
  //   setComments(_arr);
  // };
  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const audioComplete = reorder(
      this.state.audioComplete,
      result.source.index,
      result.destination.index
    );

    this.setState({
      audioComplete
    });
  };

  renderChapters = () => {
    if (this.state.audioComplete.length === 0) {
      return <span>No hay capítulos cargados actualmente</span>;
    }



    // return this.state.audioComplete.map((audio, index) => {
      return <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {this.state.audioComplete.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <div key={item} className="charpterListItem">
                        <div>
                          <div className="chapterTitle">{item.id}</div>
                        </div>
                        <div className="audioContainer">
                          <ReactAudioPlayer src={item.content} autoPlay={false} controls />
                        </div>
                        <button
                          className="btn btn-danger"
                          onClick={() => {
                            this.handleDeleteChapter(index);
                          }}
                        >
                          Borrar
                        </button>
                      </div>

                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    // });
  };

  renderBookEditForm = () => {
    const { openChapter } = this.state;
    return (
      <div className="componentContainer">
        <Modal open={openChapter} onClose={() => {}} showCloseIcon={false}>
          <div className="formGroupDualChapter">
            <span
              className={this.state.title === '' ? 'warningTitle' : 'hidden'}
            >
              Debe ingresar un titulo antes de continuar
            </span>
            <div className="unGrupo">
              <div className="formGroupDualItem">
                <FormGroup>
                  <Input
                    multiple
                    disabled={this.state.title === '' ? true : false}
                    type="file"
                    onChange={e => this.handleAudioCompleteChange(e, 'books')}
                  />
                </FormGroup>
                {this.renderAudioCompleteProgressBar()}
              </div>
            </div>
            {this.renderCloseBtn()}
          </div>
        </Modal>
        <Header />
        <div className="sectionContainer">
          <div>
            <div className="headerSection">
              <span>Edición de Audiolibro</span>
              <img src={logo} alt="Audiolibros" className="logoHeader" />
            </div>
          </div>
          <div>
            <div className="editFormContainer">
              <div className="formGroup">
                <span className="formLabel">Titulo</span>
                <input
                  className="form-control"
                  type="text"
                  value={this.state.title}
                  onChange={e => this.handleTitleChange(e)}
                />
              </div>
              <div className="formGroup">
                <span className="formLabel">Editorial</span>
                <input
                  className="form-control"
                  type="text"
                  value={this.state.editorial}
                  onChange={e => this.handleEditorialChange(e)}
                />
              </div>

              <div className="formGroup">
                <span className="formLabel">Descripción</span>
                <textarea
                  className="form-control"
                  value={this.state.description}
                  onChange={e => this.handleDescChange(e)}
                />
              </div>

              <div className="formGroup">
                <span className="formLabel">Categoría</span>
                {this.renderSelectCategory()}
              </div>

              <div className="formGroup">
                <span className="formLabel">ISBN</span>
                <input
                  className="form-control"
                  type="text"
                  value={this.state.isbn}
                  onChange={e => this.handleIsbnChange(e)}
                />
              </div>

              <div className="formGroup">
                <span className="formLabel">Identificador Apple</span>
                <input
                  className="form-control"
                  type="text"
                  value={this.state.appleIdentifier}
                  onChange={e => this.handleAppleIdentifierChange(e)}
                />
              </div>

              <div className="formGroup">
                <span className="formLabel">Autor</span>
                <input
                  className="form-control"
                  type="text"
                  value={this.state.author}
                  onChange={e => this.handleAuthorChange(e)}
                />
              </div>

              <div className="formGroup">
                <span className="formLabel">Duración</span>
                <input
                  className="form-control"
                  type="text"
                  value={this.state.duration}
                  onChange={e => this.handleDurationChange(e)}
                />
              </div>

              <div className="formGroup">
                <span className="formLabel">Tipo</span>
                {this.renderSelectBookStyle()}
              </div>

              <div className="formGroup">
                <span className="formLabel">Estilo</span>
                {this.renderSelectBookType()}
              </div>

              <div className="formGroup">
                <span className="formLabel">Precio</span>
                <input
                  className="form-control"
                  type="number"
                  value={this.state.price}
                  onChange={e => this.handlePriceChange(e)}
                />
              </div>

              <div className="formGroup">
                <span className="formLabel">Imagen</span>
                <div className="formGroupDual">
                  <div className="formGroupDualItem">
                    <FormGroup>
                      <Input
                        type="file"
                        onChange={e => this.handleImageChange(e)}
                      />
                    </FormGroup>
                    {this.renderImgProgressBar()}
                  </div>
                  <div className="formGroupDualItem">
                    <img
                      className="editImage"
                      src={this.state.image}
                      alt={this.state.title}
                    />
                  </div>
                </div>
              </div>

              <div className="formGroup">
                <span className="formLabel">Imagen Destacada</span>
                <div className="formGroupDual">
                  <div className="formGroupDualItem">
                    <FormGroup>
                      <Input
                        disabled={this.state.title === '' ? true : false}
                        type="file"
                        onChange={e => this.handleWideImageChange(e)}
                      />
                    </FormGroup>
                    {this.renderWideImgProgressBar()}
                  </div>
                  <div className="formGroupDualItem">
                    <img
                      className="editImage"
                      src={this.state.wideImage}
                      alt={this.state.title}
                    />
                  </div>
                </div>
              </div>

              <div className="formGroup">
                <span className="formLabel">Audio Demo</span>
                <div className="formGroupDual">
                  <div className="formGroupDualItem">
                    <FormGroup>
                      <Input
                        disabled={this.state.title === '' ? true : false}
                        type="file"
                        onChange={e =>
                          this.handleAudioSampleChange(e, 'trailers')
                        }
                      />
                    </FormGroup>
                    {this.renderAudioSampleProgressBar()}
                  </div>
                  <div className="formGroupDualItem">
                    <ReactAudioPlayer
                      src={this.state.audioSample}
                      autoPlay={false}
                      controls
                    />
                  </div>
                </div>
              </div>

              <div className="formGroup">
                <span className="formLabel">Capítulos</span>
                <div>
                  <button
                    className="btn btn-info btn-sm btnAddChapter"
                    onClick={() => {
                      this.setState({ openChapter: !this.state.openChapter });
                    }}
                  >
                    Agregar capítulos
                  </button>
                </div>
                <div className="chaptersContainer">{this.renderChapters()}</div>
              </div>

              <div className="formGroup radioGroupForm">
                <span className="formLabel">Destacado</span>
                <div className="switchContainer">
                  <Switch
                    onChange={this.handleOptionChange}
                    checked={this.state.checked}
                    id="normal-switch"
                  />
                  <span>{this.state.checked ? 'SI' : 'NO'}</span>
                </div>
              </div>

              <div className="formGroup radioGroupForm">
                <span className="formLabel">Novedad</span>
                <div className="switchContainer">
                  <Switch
                    onChange={this.handleNoveltyChange}
                    checked={this.state.novelty}
                    id="normal-switch"
                  />
                  <span>{this.state.novelty ? 'SI' : 'NO'}</span>
                </div>
              </div>

              <div className="keypad">
                <button
                  disabled={this.btnStatusControl()}
                  className="btn btn-success"
                  onClick={this.handleSaveClick}
                >
                  Guardar
                </button>
                <Link to="/books" className="btn btn-danger">
                  Cancelar
                </Link>
                <span className="updateMsg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  renderComponent = () => {
    return this.props.auth ? (
      this.renderBookEditForm()
    ) : (
      <Navigate
        to={{
          pathname: '/login',
          state: { from: this.props.location }
        }}
      />
    );
  };
  render() {
    return this.renderComponent();
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth.authenticated,
    book: state.book.book,
    msgOk: state.bookUpdate.updateMsgOk,
    msgError: state.bookUpdate.updateMsgError,
    imgProgress: state.uploadProgress.imgProgress,
    audioSampleProgress: state.uploadProgress.audioSampleProgress,
    audioCompleteProgress: state.uploadProgress.audioCompleteProgress,
    imageURL: state.imageUpload.imageUrl,
    imageUploading: state.imageUpload.uploadingImg,
    uploadingWideImg: state.imageUpload.uploadingWideImg,
    audioSampleURL: state.audioUpload.audioSampleUrl,
    audioCompleteURL: state.audioUpload.audioCompleteUrl,
    audioSampleUploading: state.audioUpload.uploadingSampleAudio,
    audioCompleteUploading: state.audioUpload.uploadingCompleteAudio,
    categories: state.categories.categories
  };
};

export default connect(
  mapStateToProps,
  {
    getBook,
    updateBook,
    imgUpload,
    audioUpload,
    getAllCategories
  }
)(withRouter(BookEdit));
