import {
    NEW_CAT,
    NEW_CAT_SUCCESS,
    NEW_CAT_ERROR,
    GET_CATEGORIES,
    GET_CATEGORIES_SUCCESS,
    GET_CATEGORIES_ERROR,
    DELETE_CAT,
    DELETE_CAT_SUCCESS,
    DELETE_CAT_ERROR,
    GET_CATEGORY,
    GET_CATEGORY_SUCCESS,
    GET_CATEGORY_ERROR,
    UPDATE_CAT,
    UPDATE_CAT_ERROR,
    UPDATE_CAT_SUCCESS,
    GET_CATEGORIES_LIST,
} from '../actions/types';

const INITIAL_STATE = {
    creatingCat: false,
    loadingCategory: false,
    loadingCategories: false,
    deletingCategory: false,
    updatingCategory: false,
    updateCategoryOK: '',
    updateCategoryERROR: '',
    newCatMsgOK: '',
    newCatMsgERROR: '',
    getCategoriesMsgError: '',
    deleteCategoryMsgOK: '',
    deleteCategoryMsgERROR: '',
    getCategoryMsgOK: '',
    getCategoryMsgERROR: '',
    categories: [],
    category: [],
    categoriesList: null,
};

export default function(state=INITIAL_STATE, action) {
    switch (action.type) {
        case NEW_CAT:
            return { ...state, creatingCat: true, newCatMsgOK: '', newCatMsgERROR: ''};
        case NEW_CAT_SUCCESS:
            return { ...state, creatingCat: false, newCatMsgOK: action.payload};
        case NEW_CAT_ERROR:
            return { ...state, creatingCat: false, newCatMsgERROR: action.payload};
        case GET_CATEGORIES: 
            return { ...state, loadingCategories: true, getCategoriesMsgError: ''};
        case GET_CATEGORIES_SUCCESS: 
            return { ...state, loadingCategories: false, getCategoriesMsgError: '', categories: action.payload};
        case GET_CATEGORIES_ERROR: 
            return { ...state, loadingCategories: false, getCategoriesMsgError: action.payload};
        case DELETE_CAT:
            return { ...state, deletingCategory: true, deleteCategoryMsgERROR: '', deleteCategoryMsgOK: '' };
        case DELETE_CAT_SUCCESS:
            return { ...state, deletingCategory: false, deleteCategoryMsgERROR: '', deleteCategoryMsgOK: 'OK' };
        case DELETE_CAT_ERROR:
            return { ...state, deletingCategory: false, deleteCategoryMsgERROR: 'ERROR', deleteCategoryMsgOK: '' };
        case GET_CATEGORY:
            return { ...state, loadingCategory: true};
        case GET_CATEGORY_SUCCESS:
            return { ...state, loadingCategory: false, category: action.payload, getCategoryMsgOK: action.payload};
        case GET_CATEGORY_ERROR:
            return { ...state, loadingCategory: false, getCategoryMsgError: action.payload};
        case UPDATE_CAT:
            return { ...state, updatingCategory: true, updateCategoryOK: '', updateCategoryERROR: ''};
        case UPDATE_CAT_SUCCESS:
            return { ...state, updatingCategory: false, updateCategoryOK: action.payload, updateCategoryERROR: ''};
        case UPDATE_CAT_ERROR:
            return { ...state, updatingCategory: false, updateCategoryOK: '', updateCategoryERROR: action.payload};
        case GET_CATEGORIES_LIST:
            return { ...state, categoriesList: action.payload };
        default:
            return state;
    }
}