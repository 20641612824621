import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchInput, {createFilter} from 'react-search-input';
import { Navigate, Link } from 'react-router-dom';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import { getAllCategories, deleteCat } from '../actions';
import Header from './Header';
import { Bars, Audio } from  'react-loader-spinner';
import logo from '../assets/logoHeader.png';
import { withRouter } from './withRouter';


const KEYS_TO_FILTERS = ['categorie.name'];
const columns = [
    {
        Header: 'Nombre',
        accessor: 'categorie.name',
    },
    {
        Header: 'Descripción',
        accessor: 'categorie.description'
    },
    {
        id: 'acciones',
        Header: 'Acciones',
        Cell: row => {
            return self.renderTableBtns(row);
        }
    }
];
let self = null;

export class Categories extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            categories: {},
            filterText: '',
            categoryId: null,
        };
        this.handleSearchChange = this.handleSearchChange.bind(this);
        self = this;
    }

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    componentDidMount() {
        this.props.getAllCategories();
    }

    renderTableBtns = (row) => {
        return (
            <div className="catTableBtns">
                {self.handleEditCatClick(row)}
                {self.handleDeleteCatClick(row)}
            </div>
        );
    }

    handleEditCatClick = (row) => {
        return (
            <Link
                to={`/category/${row.original.catId}`}
                className="btn btn-primary btn-sm"
            >
                <FontAwesomeIcon
                    icon='edit'
                    size='1x'
                />
            </Link>
        );
    }

    handleDeleteCatClick = (row) => {
        let data = row;
        if(this.props.deletingCategory && ( this.state.categoryId === data.original.catId )){
            return <div className="deleteLoader"><Audio color="#17a2b8" height={20} width={20} /></div>;
        }
        return (
            <button
                className="btn btn-danger btn-sm"
                onClick={() => self.handleDeleteClick(data)}
            >
                <FontAwesomeIcon
                    icon='trash-alt'
                    size='1x'
                />
            </button>
        );
    }

    handleDeleteClick = (row) => {
        this.setState({
            categoryId: row.original.catId
        }, () => {
            self.props.deleteCat(row.original.catId, () => {
                self.props.getAllCategories();
            });
        });

    }

    handleSearchChange(term) {
        this.setState({filterText: term});
    }

    renderCategoriesTable = () => {
        if(this.props.loadingCategories){
            return <Bars color="#17a2b8" height={30} width={30} />;
        }

        const filteredCategories = this.props.categories.filter(createFilter(this.state.filterText, KEYS_TO_FILTERS));

        return (
            <ReactTable
                data={filteredCategories}
                columns={columns}
                defaultPageSize={15}
                showPageSizeOptions={false}
                className="-striped"
                previousText= 'Anterior'
                nextText= 'Siguiente'
                loadingText= 'Cargando...'
                noDataText= 'No se encontraron filas'
                pageText= 'Pagina'
                ofText= 'de'
                rowsText= 'filas'
            />
        );
    }

    renderLoader = () => {
        return <Bars color="#17a2b8" height={30} width={30} />;
    }

    renderCatListBtn = () => {
        return (
            <div className="catBtnsSubHeaderHolder">
                <button
                    className="btn btn-sm btn-success"
                    onClick={this.handleNewCategoryClick()}
                >
                    Nueva categoría
                </button>
                <Link
                    to={'/categorieslist'}
                    className="btn btn-primary btn-sm"
                >
                    Configuración del listado
                </Link>
            </div>
        );
    }

    renderCategoriesList = () => {
        return (
            <div className="componentContainer">
                <Header />
                <div className="sectionContainer">
                    <div>
                        <div className="headerSection headerBooks">
                            <span>Categorias</span>
                            <img src={logo} alt="Audiolibros" className="logoHeader"/>
                        </div>

                    </div>
                    <div className="booksColumns">
                        <div >
                            <div className="catSubheaderStyle">
                                <div className="catSubSubHeader">
                                    <SearchInput
                                        placeholder="Buscar"
                                        className="search-input appSearchInput"
                                        onChange={this.handleSearchChange}
                                    />
                                </div>
                                {this.renderCatListBtn()}
                            </div>

                            {this.props.loadingCategories ? this.renderLoader() : this.renderCategoriesTable()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    handleNewCategoryClick = () => {
        return () => {
            this.props.navigate('/newcategory');
        };
    }

    renderComponent() {
        return this.props.auth ? (
            this.renderCategoriesList()
        ) : (
            <Navigate
                to={{
                    pathname: '/login',
                    state: { from: this.props.location }
                }}
            />
        );
    }

    render() {
        return this.renderComponent();
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth.authenticated,
    categories: state.categories.categories,
    loadingCategories: state.categories.loadingCategories,
    deletingCategory: state.categories.deletingCategory
});

export default connect(mapStateToProps, { getAllCategories, deleteCat })(withRouter(Categories));
