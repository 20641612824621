// import firebase from 'firebase';
import {
  LOGIN_LOADING,
  LOGIN_OK,
  LOGIN_ERROR,
  LOG_OUT_OK,
  GET_ALL_USERS,
  GET_USER,
  NEW_USER_CREATION_PROCCESS,
  CLEAR_NEW_USER_STATE,
  NEW_USER_OK,
  NEW_USER_ERROR,
  GET_BOOK_OK,
  GET_ALL_BOOKS,
  GET_ALL_BOOKS_SUCCESS,
  BOOK_UPDATED_OK,
  BOOK_UPDATED_ERROR,
  NEW_BOOK_CREATED_OK,
  IMAGE_UPLOAD_PROGRESS,
  DELETE_BOOK_OK,
  DELETE_BOOK_ERROR,
  IMAGE_UPLOADING,
  IMAGE_WIDE_UPLOADING,
  IMAGE_UPLOADED_OK,
  AUDIO_SAMPLE_UPLOADED_OK,
  AUDIO_COMPLETE_UPLOADED_OK,
  AUDIO_COMPLETE_UPLOAD_PROGRESS,
  AUDIO_SAMPLE_UPLOAD_PROGRESS,
  AUDIO_SAMPLE_UPLOADING,
  AUDIO_COMPLETE_UPLOADING,
  GET_ALL_QRCODES,
  GET_ALL_QRCODES_SUCCESS,
  GET_ALL_QRCODES_ERROR,
  NEW_POS,
  NEW_POS_CREATED_ERROR,
  NEW_POS_CREATED_OK,
  GET_ALL_POS,
  GET_ALL_POS_ERROR,
  GET_ALL_POS_SUCCESS,
  GET_POS_OK,
  NEW_BATCH,
  NEW_BATCH_ERROR,
  NEW_BATCH_SUCCESS,
  GET_BATCHES,
  GET_BATCHES_SUCCESS,
  GET_QR_BY_BATCH,
  GET_QR_BY_BATCH_SUCCESS,
  GET_BATCH,
  GET_BATCH_SUCCESS,
  GET_BATCH_ERROR,
  UPDATE_QR_STATUS,
  UPDATE_QR,
  DELETE_QR_BY_BATCH,
  DELETE_QR_BY_BATCH_SUCCESS,
  DELETE_BATCH,
  DELETE_BATCH_SUCCESS,
  NEW_CAT,
  NEW_CAT_ERROR,
  NEW_CAT_SUCCESS,
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_ERROR,
  GET_ALL_NOTIFICATIONS,
  GET_ALL_NOTIFICATIONS_SUCCESS,
  GET_ALL_NOTIFICATIONS_ERROR,
  NEW_NOTIFICATION,
  NEW_NOTIFICATION_SUCCESS,
  NEW_NOTIFICATION_ERROR,
  DELETE_CAT,
  DELETE_CAT_ERROR,
  DELETE_CAT_SUCCESS,
  UPDATE_CAT,
  UPDATE_CAT_ERROR,
  UPDATE_CAT_SUCCESS,
  GET_CATEGORY,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_ERROR,
  GET_CATEGORIES_LIST,
  UPDATE_USER,
  UPDATE_USER_ERROR,
  UPDATE_USER_SUCCESS,
  DELETE_USER,
  DELETE_USER_ERROR,
  DELETE_USER_SUCCESS,
  SAVE_CAT_ORDER,
  SAVE_CAT_ORDER_ERROR,
  SAVE_CAT_ORDER_SUCCESS,
  GET_ALL_QRS_BY_BATCH,
  GET_ALL_SALES,
  GET_SALE,
  GET_SALE_SUCCESS,
  GET_SALE_QR,
  GET_PIN,
  UPDATING_POS,
  POS_UPDATED,
  DELETE_POINT_OF_SALE,
  DELETE_POINT_OF_SALE_ERROR,
  NEW_COMBO_CREATED_OK,
  GET_ALL_COMBOS,
  GET_ALL_COMBOS_SUCCESS,
  DELETE_COMBO_OK,
  DELETE_COMBO_ERROR,
  GET_COMBO_OK,
  COMBO_UPDATE_OK
} from './types';

// import firebase from 'firebase/compat/app';
// import 'firebase/compat/auth';
// import 'firebase/compat/firestore';

// import { database } from '../config/firebase';


import {firestore as database}  from '../config/firebase';
// import {storage as storage}  from '../config/firebase';
import firebase from '../config/firebase';

// const dbStore = storage().ref();
const dbStore = firebase.storage();


// require('../config/firebase');

// import * as database from '../config/firebase';

// import { getFirestore } from "@firebase/firestore"
// const database = firebase.firestore();
// import { initializeApp } from "firebase/app";

// const app = initializeApp(FirebaseConfig);
// export const database = getFirestore(app);


// import { FirebaseConfig } from '../config/key';
// import { initializeApp } from "firebase/app";
// import { getFirestore } from "firebase/firestore"

// const app = initializeApp(FirebaseConfig);
// const database = getFirestore(app);



export const clearNewUserState = () => async dispatch => {
  dispatch({ type: CLEAR_NEW_USER_STATE });
};

export const login = (formProps, callback) => async dispatch => {
  dispatch({ type: LOGIN_LOADING });
  const { email, password } = formProps;
  firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then(response => {
      checkAdmin(response.user.uid, callback, dispatch, response);
    })
    .catch(error => {
      console.log(error);
      dispatch({
        type: LOGIN_ERROR,
        payload: error.message
      });
    });
};

function checkAdmin(id, callback, dispatch, response) {
  database
    .collection('users')
    .where('idUser', '==', id)
    .get()
    .then(snapshot => {
      snapshot.forEach(doc => {
        let datos = doc.data();
        if (datos.authorized) {
          if (datos.isAdmin) {
            dispatch({ type: LOGIN_OK, payload: response.user.refreshToken });
            localStorage.setItem('token', response.user.refreshToken);
            setTimeout(callback, 0);
          } else {
            dispatch({
              type: LOGIN_ERROR,
              payload:
                'This user does not have permission to access the administrator'
            });
          }
        } else {
          dispatch({
            type: LOGIN_ERROR,
            payload: 'Usuario no autorizado'
          });
        }
      });
    })
    .catch(err => {
    });
}

export const logout = (msg, callback) => dispatch => {
  firebase
    .auth()
    .signOut()
    .then(() => {
      localStorage.removeItem('token');
      dispatch({
        type: LOG_OUT_OK,
        patyload: msg
      });
      setTimeout(callback, 0);
    })
    .catch(error => {
    });
};

export const getAllUsers = () => dispatch => {
  let usersArray = [];
  database
    .collection('users')
    .get()
    .then(snapshot => {
      snapshot.forEach(doc => {
        usersArray.push(doc.data());
      });
      dispatch({
        type: GET_ALL_USERS,
        payload: usersArray
      });
    })
    .catch(err => {
    });
};

export const getUser = (id, callback) => dispatch => {
  database
    .collection('users')
    .doc(id)
    .get()
    .then(doc => {
      dispatch({
        type: GET_USER,
        payload: doc.data()
      });
      setTimeout(callback, 0);
    })
    .catch(err => {
    });
};

export const updateUser = (
  id,
  email,
  isAdmin,
  authorized,
  callback
) => dispatch => {
  dispatch({ type: UPDATE_USER });
  database
    .collection('users')
    .doc(id)
    .set(
      {
        email: email,
        isAdmin: isAdmin,
        authorized: authorized
      },
      { merge: true }
    )
    .then(() => {
      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: 'Usuario actualizado con exito'
      });
      setTimeout(callback, 0);
    })
    .catch(err => {
      dispatch({
        type: UPDATE_USER_ERROR,
        payload: 'No se pudo actualizar el usuario'
      });
    });
};

export const addUser = (formProps, callback) => dispatch => {
  dispatch({ type: NEW_USER_CREATION_PROCCESS });
  const { email, password } = formProps;
  firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then(response => {
      addUserToDataBase(response.user, callback, dispatch);
    })
    .catch(error => {
      dispatch({ type: NEW_USER_ERROR, payload: error.message });
    });
};

function addUserToDataBase(user, callback, dispatch) {
  const { uid, email } = user;
  const dbRef = database.collection('users').doc(uid);
  dbRef.set({
    idUser: uid,
    email: email,
    isAdmin: false,
    authorized: true
  });
  dispatch({ type: NEW_USER_OK, payload: 'Usuario creado con exito!!' });
  setTimeout(callback, 0);
}

export const deleteUser = (id, callback) => dispatch => {
  dispatch({ type: DELETE_USER });
  database
    .collection('users')
    .doc(id)
    .set(
      {
        authorized: false
      },
      { merge: true }
    )
    .then(() => {
      dispatch({
        type: DELETE_USER_SUCCESS,
        payload: 'Usuario eliminado con exito'
      });
      setTimeout(callback, 0);
    })
    .catch(err => {
      dispatch({
        type: DELETE_USER_ERROR,
        payload: 'No se pudo eliminar el usuario'
      });
    });
};

export const getBook = (id, callback) => dispatch => {
  database
    .collection('audiolibros')
    .doc(id)
    .get()
    .then(doc => {
      dispatch({
        type: GET_BOOK_OK,
        payload: doc.data()
      });
      setTimeout(callback, 0);
    })
    .catch(err => {
    });
};

export const getAllNotifications = () => dispatch => {
  let notificationsArray = [];
  database
    .collection('notifications')
    .get()
    .then(snapshot => {
      snapshot.forEach(doc => {
        let obj = {
          idNotification: doc.id,
          ...doc.data()
        };
        notificationsArray.push(obj);
      });
      dispatch({
        type: GET_ALL_NOTIFICATIONS,
        payload: notificationsArray
      });
    })
    .catch(err => {
    });
};

export const getAllBooks = () => dispatch => {
  dispatch({ type: GET_ALL_BOOKS });
  let booksArray = [];
  database
    .collection('audiolibros')
    .get()
    .then(snapshot => {
      snapshot.forEach(doc => {
        let obj = {
          idBook: doc.id,
          books: doc.data()
        };
        booksArray.push(obj);
      });
      dispatch({
        type: GET_ALL_BOOKS_SUCCESS,
        payload: booksArray
      });
    })
    .catch(err => {
    });
};

export const updateBook = (data, callback) => dispatch => {
  const {
    audioSample,
    audioComplete,
    id,
    image,
    wideImage,
    name,
    description,
    outstanding,
    novelty,
    title,
    editorial,
    catId,
    price,
    isbn,
    appleIdentifier,
    author,
    bookStyle,
    bookType,
    duration
  } = data;
  database
    .collection('audiolibros')
    .doc(id)
    .set(
      {
        audioSample: audioSample,
        audioComplete: audioComplete,
        description: description,
        image: image,
        wideImage: wideImage,
        name: name,
        outstanding: outstanding,
        novelty: novelty,
        title: title,
        editorial: editorial,
        catId: catId,
        price: price,
        isbn: isbn,
        appleIdentifier: appleIdentifier,
        author: author,
        bookStyle: bookStyle,
        bookType: bookType,
        duration: duration
      },
      { merge: true }
    )
    .then(() => {
      dispatch({
        type: BOOK_UPDATED_OK,
        payload: 'Documento actualizado con exito!'
      });
      setTimeout(callback, 0);
    })
    .catch(err => {
      dispatch({
        type: BOOK_UPDATED_ERROR,
        payload: 'Error al intentar actualizar el documento'
      });
    });
};

export const newBook = (data, callback) => dispatch => {
  const {
    audioSample,
    audioComplete,
    image,
    wideImage,
    name,
    description,
    outstanding,
    novelty,
    title,
    editorial,
    catId,
    price,
    isbn,
    appleIdentifier,
    bookStyle,
    bookType,
    author,
    duration
  } = data;
  database
    .collection('audiolibros')
    .doc()
    .set({
      audioSample: audioSample,
      audioComplete: audioComplete,
      description: description,
      image: image,
      wideImage: wideImage,
      name: name,
      outstanding: outstanding,
      novelty: novelty,
      title: title,
      editorial: editorial,
      catId: catId,
      price: price,
      isbn: isbn,
      appleIdentifier: appleIdentifier,
      bookStyle: bookStyle,
      bookType: bookType,
      author: author,
      duration: duration
    })
    .then(() => {
      dispatch({
        type: NEW_BOOK_CREATED_OK,
        payload: 'Nuevo audiolibro creado con exito'
      });
      setTimeout(callback, 0);
    })
    .catch(err => {
    });
};

export const newCombo = (data, callback) => dispatch => {
  database
    .collection('combos')
    .doc()
    .set({
      combo: data.combo,
      dateCreated: data.dateCreated,
      dateExpired: data.dateExpired,
      image: data.image,
      title: data.title,
      price: data.price
    })
    .then(() => {
      dispatch({
        type: NEW_COMBO_CREATED_OK,
        payload: 'Nuevo combo creado con exito'
      });
      setTimeout(callback, 0);
    })
    .catch(err => {
    });
};

export const updateCombo = (data, callback) => dispatch => {
  database
    .collection('combos')
    .doc(data.id)
    .set({
      combo: data.combo,
      dateCreated: data.dateCreated,
      dateExpired: data.dateExpired,
      image: data.image,
      title: data.title,
      price: data.price
    })
    .then(() => {
      dispatch({
        type: COMBO_UPDATE_OK,
        payload: 'Combo actualizado con exito'
      });
      setTimeout(callback, 0);
    })
    .catch(err => {
    });
};

export const getAllCombos = () => dispatch => {
  dispatch({ type: GET_ALL_COMBOS });
  let combosArray = [];
  database
    .collection('combos')
    .get()
    .then(snapshot => {
      snapshot.forEach(doc => {
        let obj = {
          idCombo: doc.id,
          combos: doc.data()
        };
        combosArray.push(obj);
      });
      dispatch({
        type: GET_ALL_COMBOS_SUCCESS,
        payload: combosArray
      });
    })
    .catch(err => {
    });
};

export const getCombo = (id, callback) => dispatch => {
  database
    .collection('combos')
    .doc(id)
    .get()
    .then(doc => {
      dispatch({
        type: GET_COMBO_OK,
        payload: doc.data()
      });
      setTimeout(callback, 0);
    })
    .catch(err => {
    });
};

export const deleteCombo = (id, callback) => dispatch => {
  database
    .collection('combos')
    .doc(id)
    .delete()
    .then(() => {
      dispatch({
        type: DELETE_COMBO_OK
      });
      setTimeout(callback, 0);
    })
    .catch(err => {
      dispatch({
        type: DELETE_COMBO_ERROR
      });
    });
};

export const imgUpload = (file, title, root, path, callback) => dispatch => {
  const uploadingType =
    path === 'wide/' ? IMAGE_WIDE_UPLOADING : IMAGE_UPLOADING;
  if (file) {
    dispatch({
      type: uploadingType
    });
    let imageUploadTask = dbStore
      .ref()
      .child(`${root}${title.toLowerCase()}/${path.toLowerCase()}${file.name}`)
      .put(file);
    imageUploadTask.on(
      'state_changed',
      snapshot => {
        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        dispatch({
          type: IMAGE_UPLOAD_PROGRESS,
          payload: Math.round(progress)
        });
      },
      function(error) {
      },
      function() {
        imageUploadTask.snapshot.ref
          .getDownloadURL()
          .then(function(downloadURL) {
            dispatch({
              type: IMAGE_UPLOADED_OK,
              payload: downloadURL
            });
            setTimeout(callback, 0);
          });
      }
    );
  }
};

export const audioUpload = (file, title, filePath, callback) => dispatch => {
  if (file) {
    const TYPE =
      filePath === 'trailers'
        ? AUDIO_SAMPLE_UPLOADED_OK
        : AUDIO_COMPLETE_UPLOADED_OK;
    const PROGRESS_TYPE =
      filePath === 'trailers'
        ? AUDIO_SAMPLE_UPLOAD_PROGRESS
        : AUDIO_COMPLETE_UPLOAD_PROGRESS;
    const UPLOADING_TYPE =
      filePath === 'trailers'
        ? AUDIO_SAMPLE_UPLOADING
        : AUDIO_COMPLETE_UPLOADING;
    dispatch({
      type: UPLOADING_TYPE
    });
    let audioUploadTask = dbStore
      .ref()
      .child(`/${filePath}/${title.toLowerCase()}/${file.name}`)
      .put(file);
    audioUploadTask.on(
      'state_changed',
      snapshot => {
        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        dispatch({
          type: PROGRESS_TYPE,
          payload: Math.round(progress)
        });
      },
      function(error) {
      },
      function() {
        audioUploadTask.snapshot.ref
          .getDownloadURL()
          .then(function(downloadURL) {
            dispatch({
              type: TYPE,
              payload: downloadURL
            });
            setTimeout(callback, 0);
          });
      }
    );
  }
};

export const deleteBook = (id, callback) => dispatch => {
  database
    .collection('audiolibros')
    .doc(id)
    .delete()
    .then(() => {
      dispatch({
        type: DELETE_BOOK_OK
      });
      setTimeout(callback, 0);
    })
    .catch(err => {
      dispatch({
        type: DELETE_BOOK_ERROR
      });
    });
};

export const newPos = (data, callback) => dispatch => {
  dispatch({ type: NEW_POS });
  const { name, personInCharge, email, phone, direction, location } = data;
  database
    .collection('points_of_sale')
    .doc()
    .set({
      name,
      personInCharge,
      email,
      phone,
      direction,
      location
    })
    .then(() => {
      dispatch({
        type: NEW_POS_CREATED_OK,
        payload: 'Nuevo punto de venta creado con exito'
      });
      setTimeout(callback, 0);
    })
    .catch(err => {
      dispatch({
        type: NEW_POS_CREATED_ERROR,
        payload: 'Error al crear el punto de venta'
      });
    });
};

export const deletePos = (id, callback) => dispatch => {
  database
    .collection('points_of_sale')
    .doc(id)
    .delete()
    .then(() => {
      dispatch({
        type: DELETE_POINT_OF_SALE
      });
      setTimeout(callback, 0);
    })
    .catch(err => {
      dispatch({
        type: DELETE_POINT_OF_SALE_ERROR
      });
    });
};

export const getAllPos = () => dispatch => {
  dispatch({ type: GET_ALL_POS });
  let posArray = [];
  database
    .collection('points_of_sale')
    .get()
    .then(snapshot => {
      snapshot.forEach(doc => {
        let obj = {
          idPOS: doc.id,
          pos: doc.data()
        };
        posArray.push(obj);
      });
      dispatch({
        type: GET_ALL_POS_SUCCESS,
        payload: posArray
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ALL_POS_ERROR,
        payload: 'Error al recuperar los datos'
      });
    });
};

export const getPos = (id, callback) => dispatch => {
  database
    .collection('points_of_sale')
    .doc(id)
    .get()
    .then(doc => {
      dispatch({
        type: GET_POS_OK,
        payload: doc.data()
      });
      setTimeout(callback, 0);
    })
    .catch(err => {
    });
};

export const updatePos = (data, callback) => dispatch => {
  dispatch({ type: UPDATING_POS });
  database
    .collection('points_of_sale')
    .doc(data.id)
    .set(
      {
        name: data.name,
        personInCharge: data.personInCharge,
        email: data.email,
        phone: data.phone,
        direction: data.direction,
        location: data.location
      },
      { merge: true }
    )
    .then(() => {
      dispatch({
        type: POS_UPDATED
      });
      setTimeout(callback, 0);
    })
    .catch();
};

export const newBatch = (data, callback) => dispatch => {
  const { posID, bookID, amount, used_infinity } = data;
  dispatch({ type: NEW_BATCH });
  database
    .collection('batches')
    .add({
      date: new Date(),
      idBook: bookID,
      idPos: posID,
      amount: amount
    })
    .then(docRef => {
      createQRcodeID(amount, docRef.id, used_infinity, dispatch, callback);
    })
    .catch(err => {
      dispatch({
        type: NEW_BATCH_ERROR,
        payload: 'Error al crear lote'
      });
    });
};

function generatePIN() {
  return Math.floor(Math.random() * 90000000) + 10000000;
}

function checkPINexist() {
  let pinGenerated = generatePIN();
  return filterPIN(pinGenerated).then(pin => {
    if (pin.length > 0) {
      checkPINexist();
    } else {
      return pinGenerated;
    }
  });
}

function filterPIN(pin) {
  return getQRs().then(data => {
    return data.filter(item => {
      return parseInt(item.pin.pin, 10) === parseInt(pin, 10);
    });
  });
}

function getQRs() {
  let qrArr = [];
  return database
    .collection('qrs')
    .get()
    .then(snapshot => {
      snapshot.forEach(doc => {
        qrArr.push({
          idQR: doc.id,
          pin: doc.data()
        });
      });
      return qrArr;
    })
    .catch(err => {
    });
}

function createQRcodeID(amount, batchId, used_infinity, dispatch, callback) {
  for (let i = 0; i < amount; i++) {
    checkPINexist().then(pin => {
      if (pin) {
        database
          .collection('qrs')
          .add({
            idQR: '',
            pin: pin
          })
          .then(docRef => {
            insertDataQRxBatch(docRef.id, batchId, used_infinity, dispatch, callback, amount);
          })
          .catch(err => {
          });
      }
    });
  }
}

let qrCount = 0;

function insertDataQRxBatch(qrID, batchID, used_infinity, dispatch, callback, amount) {
  database
    .collection('qrXbatch')
    .add({
      idBatch: batchID,
      idQr: qrID,
      enabled: true,
      used: false,
      used_infinity,
    })
    .then(() => {
      qrCount = qrCount + 1;
      controlCompletion(amount, dispatch, callback);
    })
    .catch(err => {
    });
}

function controlCompletion(amount, dispatch, callback) {
  if (parseInt(qrCount, 10) === parseInt(amount, 10)) {
    qrCount = 0;
    dispatch({
      type: NEW_BATCH_SUCCESS,
      payload: 'Nuevo lote creado con exito'
    });
    setTimeout(callback, 0);
  }
}

export const getBatches = () => dispatch => {
  dispatch({
    type: GET_BATCHES
  });
  let batchesArray = [];
  database
    .collection('batches')
    .get()
    .then(snapshot => {
      snapshot.forEach(doc => {
        let batch = doc.data();
        let obj = {
          batchId: doc.id,
          amount: batch.amount,
          date: batch.date,
          idBook: batch.idBook,
          idPos: batch.idPos
        };
        batchesArray.push(obj);
      });
      dispatch({
        type: GET_BATCHES_SUCCESS,
        payload: batchesArray
      });
    })
    .catch(err => {
    });
};

export const getBatch = (batchId, callback) => dispatch => {
  dispatch({
    type: GET_BATCH
  });
  database
    .collection('batches')
    .where(firebase.firestore.FieldPath.documentId(), '==', batchId)
    .get()
    .then(snapshot => {
      snapshot.forEach(doc => {
        dispatch({
          type: GET_BATCH_SUCCESS,
          payload: doc.data()
        });
        setTimeout(callback, 0);
      });
    })
    .catch(err => {
      dispatch({
        type: GET_BATCH_ERROR,
        payload: 'error fetching data'
      });
    });
};

export const getAllQrsByBatch = () => dispatch => {
  let arr = [];
  database
    .collection('qrXbatch')
    .get()
    .then(snapshot => {
      snapshot.forEach(doc => {
        arr.push({
          id: doc.id,
          data: doc.data()
        });
      });
      dispatch({
        type: GET_ALL_QRS_BY_BATCH,
        payload: arr
      });
    })
    .catch(err => {
    });
};

export const getQrByBatch = (batchId, callback) => dispatch => {
  let arr = [];
  dispatch({
    type: GET_QR_BY_BATCH
  });
  database
    .collection('qrXbatch')
    .where('idBatch', '==', batchId)
    .get()
    .then(snapshot => {
      snapshot.forEach(doc => {
        arr.push(doc.data());
      });
      dispatch({
        type: GET_QR_BY_BATCH_SUCCESS,
        payload: arr
      });
      setTimeout(callback, 0);
    })
    .catch(err => {
      dispatch({
        type: GET_QR_BY_BATCH_SUCCESS,
        payload: 'Datos error'
      });
    });
};

export const handleQRStatus = (id, status, callback) => dispatch => {
  dispatch({ type: UPDATE_QR });
  database
    .collection('qrXbatch')
    .where('idQr', '==', id)
    .get()
    .then(snapshot => {
      snapshot.forEach(doc => {
        updateDoc(doc.id, status, dispatch, callback);
      });
    })
    .catch(err => {
    });
};

function updateDoc(docId, status, dispatch, callback) {
  database
    .collection('qrXbatch')
    .doc(docId)
    .set(
      {
        enabled: status
      },
      { merge: true }
    )
    .then(() => {
      dispatch({
        type: UPDATE_QR_STATUS
      });
      setTimeout(callback, 0);
    })
    .catch(err => {
    });
}

export const deleteBatch = (id, arr, callback) => dispatch => {
  dispatch({ type: DELETE_BATCH });
  database
    .collection('batches')
    .doc(id)
    .delete()
    .then(() => {
      deleteRecQRs(arr);
      getAllBatchesToDelete(id);
      dispatch({ type: DELETE_BATCH_SUCCESS });
      setTimeout(callback, 0);
    })
    .catch(err => {
    });
};

function deleteRecQRs(arr) {
  arr.forEach(item => {
    deleteQR(item.idQr);
  });
}

function getAllBatchesToDelete(id) {
  database
    .collection('qrXbatch')
    .where('idBatch', '==', id)
    .get()
    .then(snapshot => {
      snapshot.forEach(doc => {
        deleteBatchOnQrByBatch(doc.id);
      });
    })
    .catch(error => {
    });
}

function deleteBatchOnQrByBatch(id) {
  database
    .collection('qrXbatch')
    .doc(id)
    .delete()
    .then(() => {})
    .catch(err => {
    });
}

export const deleteQr = (idBatch, idQr, amount, callback) => dispatch => {
  dispatch({ type: DELETE_QR_BY_BATCH });
  database
    .collection('qrXbatch')
    .where('idBatch', '==', idBatch)
    .where('idQr', '==', idQr)
    .get()
    .then(snapshot => {
      snapshot.forEach(doc => {
        makeQRbyBatchdeletion(doc.id, dispatch, callback);
        updateDataOnBatches(idBatch, amount);
        deleteQR(idQr);
      });
    })
    .catch(err => {
    });
};

function updateDataOnBatches(id, amount) {
  database
    .collection('batches')
    .doc(id)
    .set(
      {
        amount: amount
      },
      { merge: true }
    )
    .then(() => {})
    .catch(err => {
    });
}

function deleteQR(id) {
  database
    .collection('qrs')
    .doc(id)
    .delete()
    .then(() => {})
    .catch(err => {
    });
}

function makeQRbyBatchdeletion(id, dispatch, callback) {
  database
    .collection('qrXbatch')
    .doc(id)
    .delete()
    .then(() => {
      dispatch({
        type: DELETE_QR_BY_BATCH_SUCCESS
      });
      setTimeout(callback, 0);
    })
    .catch(err => {
    });
}

export const newCat = (name, description, callback) => dispatch => {
  dispatch({ type: NEW_CAT });
  database
    .collection('categories')
    .add({
      name: name.toLowerCase(),
      description: description
    })
    .then(doc => {
      getCat(doc.id);
      dispatch({
        type: NEW_CAT_SUCCESS,
        payload: 'Nueva categoria creada con exito'
      });
      setTimeout(callback, 0);
    })
    .catch(err => {
      dispatch({
        type: NEW_CAT_ERROR,
        payload: 'Error al intentar crear la categoría'
      });
    });
};

export const newNotification = (title, description, image, gift, idUser, callback) => dispatch => {
  // return console.log({
  //     date: new Date(),
  //     title: title,
  //     description: description,
  //     gift: gift,
  //     image: image,
  //     idUser: idUser,
  //   })
  dispatch({ type: NEW_NOTIFICATION });
  database
    .collection('notifications')
    .add({
      date: new Date(),
      readed: false,
      title: title,
      description: description,
      gift: gift,
      image: image,
      idUser: idUser,
    })
    .then(doc => {
      dispatch({
        type: NEW_NOTIFICATION_SUCCESS,
        payload: 'Nueva notificación creada con exito'
      });
      setTimeout(callback, 0);
    })
    .catch(err => {
      console.log(err)
      dispatch({
        type: NEW_NOTIFICATION_ERROR,
        payload: 'Error al intentar crear la notificación'
      });
    });
};

const getCat = id => {
  database
    .collection('categories')
    .doc(id)
    .get()
    .then(doc => {
      let el = {
        catId: doc.id,
        categorie: doc.data()
      };
      getCats(el);
    })
    .catch(err => {
    });
};

const getCats = el => {
  let arr = [];
  let docId = null;
  database
    .collection('categories_list_order')
    .get()
    .then(snapshot => {
      snapshot.forEach(doc => {
        arr = doc.data();
        docId = doc.id;
      });
      saveCatToOrder(arr, el, docId);
    })
    .catch(err => {
    });
};

const saveCatToOrder = (arr, el, id) => {
  arr.orden.push({
    catId: el.catId,
    categorie: el.categorie
  });
  database
    .collection('categories_list_order')
    .doc(id)
    .set({ orden: arr.orden }, { merge: true })
    .then(() => {})
    .catch(e => {
    });
};

export const getAllCategories = () => dispatch => {
  dispatch({ type: GET_CATEGORIES });
  let categoriesArray = [];
  database
    .collection('categories')
    .get()
    .then(snapshot => {
      snapshot.forEach(doc => {
        categoriesArray.push({ catId: doc.id, categorie: doc.data() });
      });
      dispatch({
        type: GET_CATEGORIES_SUCCESS,
        payload: categoriesArray
      });
    })
    .catch(err => {
      dispatch({
        type: GET_CATEGORIES_ERROR,
        payload: 'Error recuperando datos'
      });
    });
};

export const deleteCat = (id, callback) => dispatch => {
  dispatch({ type: DELETE_CAT });
  database
    .collection('categories')
    .doc(id)
    .delete()
    .then(() => {
      dispatch({
        type: DELETE_CAT_SUCCESS
      });
      setTimeout(callback, 0);
    })
    .catch(err => {
      dispatch({
        type: DELETE_CAT_ERROR
      });
    });
};

export const updateCat = (id, name, description, callback) => dispatch => {
  dispatch({ type: UPDATE_CAT });
  database
    .collection('categories')
    .doc(id)
    .set(
      {
        name: name,
        description: description
      },
      { merge: true }
    )
    .then(() => {
      dispatch({
        type: UPDATE_CAT_SUCCESS,
        payload: 'Categoría actualizada con exito'
      });
      setTimeout(callback, 0);
    })
    .catch(err => {
      dispatch({
        type: UPDATE_CAT_ERROR,
        payload: 'Error al actualizar la categoría'
      });
    });
};

export const getCategory = (id, callback) => dispatch => {
  dispatch({ type: GET_CATEGORY });
  database
    .collection('categories')
    .doc(id)
    .get()
    .then(doc => {
      dispatch({
        type: GET_CATEGORY_SUCCESS,
        payload: doc.data()
      });
      setTimeout(callback, 0);
    })
    .catch(err => {
      dispatch({
        type: GET_CATEGORY_ERROR,
        payload: 'Error recuperando datos'
      });
    });
};

export const getAllQRCodes = () => dispatch => {
  dispatch({
    type: GET_ALL_QRCODES
  });
  let qrArray = [];
  database
    .collection('qrs')
    .get()
    .then(snapshot => {
      snapshot.forEach(doc => {
        qrArray.push({
          idQR: doc.id,
          pin: doc.data()
        });
      });
      dispatch({
        type: GET_ALL_QRCODES_SUCCESS,
        payload: qrArray
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ALL_QRCODES_ERROR,
        payload: 'Error al tratar de recuperar los codigos QR'
      });
    });
};

export const getCategoriesList = callback => dispatch => {
  let list = null;
  database
    .collection('categories_list_order')
    .get()
    .then(snapshot => {
      snapshot.forEach(doc => {
        list = {
          listId: doc.id,
          list: doc.data()
        };
      });
      dispatch({
        type: GET_CATEGORIES_LIST,
        payload: list
      });
      setTimeout(callback, 0);
    })
    .catch(err => {
    });
};

export const saveCatOrder = (id, arr, callback) => dispatch => {
  dispatch({ type: SAVE_CAT_ORDER });
  database
    .collection('categories_list_order')
    .doc(id)
    .set({
      orden: arr
    })
    .then(() => {
      dispatch({
        type: SAVE_CAT_ORDER_SUCCESS,
        payload: 'Orden actualizado'
      });
      setTimeout(callback, 0);
    })
    .catch(err => {
      dispatch({
        type: SAVE_CAT_ORDER_ERROR,
        payload: 'No se pudo actualizar el orden'
      });
    });
};

export const getAllSales = () => dispatch => {
  let arr = [];
  database
    .collection('sales')
    .get()
    .then(snapshot => {
      snapshot.forEach(doc => {
        arr.push({
          id: doc.id,
          data: doc.data()
        });
      });
      dispatch({
        type: GET_ALL_SALES,
        payload: arr
      });
    })
    .catch(err => {
    });
};

export const getSale = (id, callback) => dispatch => {
  dispatch({ type: GET_SALE });
  database
    .collection('sales')
    .doc(id)
    .get()
    .then(doc => {
      dispatch({
        type: GET_SALE_SUCCESS,
        payload: doc.data()
      });
      setTimeout(callback, 0);
    })
    .catch(err => {
    });
};

export const getQrDataOfSale = (idQr, callback) => dispatch => {
  database
    .collection('qrXbatch')
    .where('idQr', '==', idQr)
    .get()
    .then(snapshot => {
      snapshot.forEach(doc => {
        dispatch({
          type: GET_SALE_QR,
          payload: doc.data()
        });
        setTimeout(callback, 0);
      });
    })
    .catch(err => {
    });
};

export const getPin = idQr => dispatch => {
  database
    .collection('qrs')
    .doc(idQr)
    .get()
    .then(doc => {
      dispatch({
        type: GET_PIN,
        payload: doc.data()
      });
    })
    .catch(err => {
    });
};
