import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-responsive-modal';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import { Navigate, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from './Header';
import { Bars } from  'react-loader-spinner'
import {
  getAllBooks,
  deleteBook,
  getAllCategories,
  getAllCombos,
  deleteCombo
} from '../actions';
import logo from '../assets/logoHeader.png';

const columns = [
  {
    Header: 'ID',
    accessor: 'idCombo'
  },
  {
    id: 'comboTitle',
    Header: 'Nombre',
    Cell: row => {
      return row.original.combos.title;
    }
  },
  {
    id: 'comboPrice',
    Header: 'Precio',
    Cell: row => {
      return row.original.combos.price;
    }
  },
  {
    id: 'comboBooks',
    Header: 'Audiolibros',
    Cell: row => {
      return (
        <div className="comboBooksList">
          {row.original.combos.combo.map(item => {
            return <span key={item.books.title}>- {item.books.title}</span>;
          })}
        </div>
      );
    }
  },
  {
    id: 'creationDate',
    Header: 'Fecha Creación',
    Cell: row => {
      return row.original.combos.dateCreated;
    }
  },
  {
    id: 'expirationDate',
    Header: 'Fecha Expiración',
    Cell: row => {
      return row.original.combos.dateExpired;
    }
  },
  {
    id: 'acciones',
    Header: 'Acciones',
    Cell: row => {
      return (
        <div className="bookTableBtns">
          <Link
            to={`/combo/${row.original.idCombo}`}
            className="btn btn-info btn-sm"
            onClick={() => self.handleEditClick(row.original)}
          >
            <FontAwesomeIcon icon="pen" size="1x" />
          </Link>
          <button
            className="btn btn-danger btn-sm"
            onClick={() => self.handleDeleteClick(row.original)}
          >
            <FontAwesomeIcon icon="trash-alt" size="1x" />
          </button>
        </div>
      );
    }
  }
];
let self = null;

class Combos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      combo: {},
      filterText: ''
    };
    this.handleSearchChange = this.handleSearchChange.bind(this);
    self = this;
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  componentDidMount() {
    this.props.getAllCombos();
    this.props.getAllBooks();
    this.props.getAllCategories();
  }

  handleDeleteClick = combo => {
    this.setState({
      open: true,
      combo
    });
  };

  handleSearchChange(term) {
    this.setState({ filterText: term });
  }

  renderUsersTable = () => {
    if (this.props.loadingBooks) {
      return <Bars color="#17a2b8" height={30} width={30} />;
    }
    return (
      <ReactTable
        data={this.props.combos}
        columns={columns}
        defaultPageSize={15}
        showPageSizeOptions={false}
        className="-striped"
        previousText="Anterior"
        nextText="Siguiente"
        loadingText="Cargando..."
        noDataText="No se encontraron filas"
        pageText="Pagina"
        ofText="de"
        rowsText="filas"
      />
    );
  };

  handleDeleteCombo = () => {
    this.props.deleteCombo(this.state.combo.idCombo, () => {
      self.setState(
        {
          open: false,
          combo: {}
        },
        self.props.getAllCombos()
      );
    });
  };

  renderCombosList = () => {
    const { open } = this.state;
    return (
      <div className="componentContainer">
        <Modal open={open} onClose={this.onCloseModal} showCloseIcon={false}>
          <div className="modalContentContainer">
            <span className="modalTitle">ELIMINAR COMBO</span>
            <span className="modalText">
              ¿Está seguro que quiere eliminar el combo{' '}
              <strong>
                {this.state.combo.combos ? this.state.combo.combos.title : ''}
              </strong>
              ?
            </span>
            <div className="modalBtnsContainer">
              <button
                className="btn btn-primary"
                onClick={this.handleDeleteCombo}
              >
                Borrar
              </button>
              <button
                className="btn btn-danger"
                onClick={() => this.setState({ open: false, combo: {} })}
              >
                Cancelar
              </button>
            </div>
          </div>
        </Modal>
        <Header />
        <div className="sectionContainer">
          <div>
            <div className="headerSection">
              <span>Combos</span>
              <img src={logo} alt="Audiolibros" className="logoHeader" />
            </div>
          </div>
          <div className="booksColumns">
            <div className="booksListSubHeader">
              <Link to="/newcombo" className="btn btn-success">
                Nuevo Combo
              </Link>
            </div>
            {this.renderUsersTable()}
          </div>
        </div>
      </div>
    );
  };

  renderComponent = () => {
    return this.props.auth ? (
      this.renderCombosList()
    ) : (
      <Navigate
        to={{
          pathname: '/login',
          state: { from: this.props.location }
        }}
      />
    );
  };

  render() {
    return this.renderComponent();
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth.authenticated,
    books: state.books.books,
    msg: state.deleteMsg.deletedMsg,
    loadingCombos: state.combo.loadingCombos,
    categories: state.categories.categories,
    combos: state.combo.combos
  };
};

export default connect(
  mapStateToProps,
  { getAllBooks, deleteBook, getAllCategories, getAllCombos, deleteCombo }
)(Combos);
