import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Navigate, Link } from 'react-router-dom';
import { getAllCategories, getCategoriesList, saveCatOrder } from '../actions';
import Header from './Header';
import { Bars } from  'react-loader-spinner'
import logo from '../assets/logoHeader.png';

let self = null;

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    background: isDragging ? 'lightgreen' : 'grey',
    ...draggableStyle,
});


const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : 'lightgrey',
    padding: grid,
    width: 250,
});

export class Categories extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            categories: {},
            filterText: '',
            categoryId: null,
            items: null,
            show: false
        };
        self = this;
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    componentDidMount() {
        this.props.getAllCategories();
        this.props.getCategoriesList(() => {
            self.setState({items: self.props.catList.list.orden});
        });
    }

    onDragEnd(result) {
        if (!result.destination) {
            return;
        }

        const items = reorder(
            this.state.items,
            result.source.index,
            result.destination.index
        );

        this.setState({
            items,
        });
    }

    getCustomStyle = (snapshot) => {
        if(snapshot.isDraggingOver){

        }
    }

    renderTheDragContent = () => {
        if(this.state.items){
            return (
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => {
                            return (
                                <div
                                    ref={provided.innerRef}
                                    style={getListStyle(snapshot.isDraggingOver)}
                                >
                                {this.state.items.map((item, index) => {
                                    return (
                                        <Draggable key={item.catId} draggableId={item.catId} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                                >
                                                {item.categorie.name}
                                                </div>
                                            )}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                                </div>
                            );
                        }}
                    </Droppable>
                </DragDropContext>
            );
        }

    }


    renderLoader = () => {
        return <Bars color="#17a2b8" height={30} width={30} />;
    }

    saveOrder = () => {
        let id = this.props.catList.listId;
        let arr = this.state.items;
        this.props.saveCatOrder(id, arr, () => {
            alert('Orden actualizado');
            self.props.getAllCategories();
        });
    }

    renderCategoriesList = () => {
        return (
            <div className="componentContainer">
                <Header />
                <div className="sectionContainer">
                    <div>
                        <div className="headerSection">
                            <span>Listado de Categorias</span>
                            <img src={logo} alt="Audiolibros" className="logoHeader"/>
                        </div>
                    </div>
                    <div className="booksColumns">
                        <div className="catListTitle">
                            <h2>Orden de las categorías para el listado de la aplicación</h2>
                        </div>
                        <div className='catListHolder'>
                            {this.renderTheDragContent()}
                            <div className="orderBtnsHolder">
                                <button
                                    className="btn btn-success"
                                    onClick={this.saveOrder}
                                >
                                    Guardar
                                </button>
                                <Link
                                    to='/categories'
                                    className="btn btn-danger"
                                >
                                    Cancelar
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderComponent() {
        return this.props.auth ? (
            this.renderCategoriesList()
        ) : (
            <Navigate
                to={{
                    pathname: '/login',
                    state: { from: this.props.location }
                }}
            />
        );
    }

    render() {
        return this.renderComponent();
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth.authenticated,
    categories: state.categories.categories,
    loadingCategories: state.categories.loadingCategories,
    deletingCategory: state.categories.deletingCategory,
    catList: state.categories.categoriesList,
});

export default connect(mapStateToProps, { getAllCategories, getCategoriesList, saveCatOrder })(Categories);
