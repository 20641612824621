import {GET_ALL_QRS_BY_BATCH} from '../actions/types';

const INITIAL_STATE = {
    qrsByBatch: null
};

export default function(state=INITIAL_STATE, action) {
    switch(action.type) {
        case GET_ALL_QRS_BY_BATCH:
            return { ...state, qrsByBatch: action.payload};
        default:
            return state;
    }
}