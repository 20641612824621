import { FirebaseConfig } from './key';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/storage';

firebase.initializeApp(FirebaseConfig);
export const firestore = firebase.firestore();
export const storage = firebase.storage();


export default firebase;