import {
    CLEAR_NEW_USER_STATE
} from '../actions/types';

const INITIAL_STATE = {
    createUserOk: '',
    createUserError: '',
    loading: false
}

export default function(state=INITIAL_STATE, action) {
    switch(action.type){
        case CLEAR_NEW_USER_STATE:
            return { ...state, ...INITIAL_STATE }
        default:
            return state;
    }
}