import {
    BOOK_UPDATED_OK,
    BOOK_UPDATED_ERROR
} from '../actions/types';

const INITIAL_STATE = {
    updateMsgOk: '',
    updateMsgError: ''
}

export default function(state=INITIAL_STATE, action) {
    switch(action.type){
        case BOOK_UPDATED_OK:
            return { ...state, updateMsgOk: 'Audiolibro actualizado!!' };
        case BOOK_UPDATED_ERROR:
            return { ...state, updateMsgError: 'Error al actualizar el audiolibro' };
        default:
            return state;
    }
}