import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Navigate, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import Modal from 'react-responsive-modal';
import SearchInput, {createFilter} from 'react-search-input';
import { getAllPos, deletePos } from '../actions';
import Header from './Header';
import { Bars } from  'react-loader-spinner'
import logo from '../assets/logoHeader.png';
import { withRouter } from './withRouter';

const KEYS_TO_FILTERS = ['pos.name'];
const columns = [
    {
        Header: 'Nombre',
        accessor: 'pos.name',
    },
    {
        Header: 'Responsable',
        accessor: 'pos.personInCharge',
    },
    {
        Header: 'Email',
        accessor: 'pos.email',
    },
    {
        Header: 'Teléfono',
        accessor: 'pos.phone',
    },
    {
        Header: 'Dirección',
        accessor: 'pos.direction',
    },
    {
        Header: 'Localidad',
        accessor: 'pos.location',
    },
    {
        id: 'acciones',
        Header: 'Acciones',
        Cell: row =>{
            return (
                <div className="posTableBtns">
                    <Link
                        to={`/posedit/${row.original.idPOS}`}
                        className="btn btn-primary btn-sm"
                    >
                        <FontAwesomeIcon
                            icon='edit'
                            size='1x'
                        />
                    </Link>
                    <button
                        className="btn btn-danger btn-sm"
                        onClick={() => self.handleDeletePos(row.original)}
                    >
                        <FontAwesomeIcon
                            icon='trash-alt'
                            size='1x'
                        />
                    </button>
                </div>
            );
        }
    }
];
let self = null;

class PointsOfSale extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            book: {},
            filterText: '',
        };
        this.handleSearchChange = this.handleSearchChange.bind(this);
        self = this;
    }

    componentDidMount = () => {
        this.props.getAllPos();
    }

    renderPosTable = () => {
        if(this.props.loadingPos){
            return <Bars color="#17a2b8" height={30} width={30} />;
        }
        const filteredPos = this.props.posData.filter(createFilter(this.state.filterText, KEYS_TO_FILTERS));
        return (
            <ReactTable
                data={filteredPos}
                columns={columns}
                defaultPageSize={15}
                showPageSizeOptions={false}
                className="-striped"
                previousText= 'Anterior'
                nextText= 'Siguiente'
                loadingText= 'Cargando...'
                noDataText= 'No se encontraron filas'
                pageText= 'Pagina'
                ofText= 'de'
                rowsText= 'filas'
            />
        );
    }

    handleNewPOSClick = () => {
        return () => {
            this.props.navigate('/newpos');
        };
    }

    handleDeletePos = (data) => {
        this.props.deletePos(data.idPOS, ()=> {
            self.props.getAllPos();
        });
    }

    handleSearchChange(term) {
        this.setState({filterText: term});
    }

    onCloseModal = () => {

    }

    renderPOSList = () => {
        const { open } = this.state;
        return (
            <div className="componentContainer">
                <Header />
                <Modal
                    open={open}
                    onClose={this.onCloseModal}
                    showCloseIcon={false}
                >
                    <div className="modalContentContainer">
                        <span className="modalTitle">ELIMINAR PUNTO DE VENTA</span>
                        <span className="modalText">¿Está seguro que quiere eliminar el punto de venta <strong>{this.state.book.books ? this.state.book.books.title : ''}</strong>?</span>
                        <div className="modalBtnsContainer">
                            <button
                                className="btn btn-primary"
                                onClick={this.handleDeletePos}
                            >
                                Borrar
                            </button>
                            <button
                                className="btn btn-danger"
                                onClick={() => this.setState({open: false, book: {}})}
                            >
                                Cancelar
                            </button>
                        </div>
                    </div>
                </Modal>
                <div className="sectionContainer">
                    <div className="headerSection">
                        <div className="headerContent">
                            <div className="headerSectionTitle">Puntos de Venta</div>
                            <img src={logo} alt="Audiolibros" className="logoHeader"/>

                        </div>
                    </div>
                    <div className="usersColumns">
                        <div className="subHeader">
                            <SearchInput
                                placeholder="Buscar"
                                className="search-input appSearchInput"
                                onChange={this.handleSearchChange}
                            />
                            <button
                                className="btn btn-sm btn-success"
                                onClick={this.handleNewPOSClick()}
                            >
                                Nuevo Punto de Venta
                            </button>
                        </div>
                        {this.renderPosTable()}
                    </div>
                </div>
            </div>
        );
    }

    renderComponent = () => {
        return this.props.auth ? (
            this.renderPOSList()
        ) : (
           <Navigate
                to={{
                    pathname: '/login',
                    state: { from: this.props.location }
                }}
            />
        );
    }

    render() {
        return this.renderComponent();
    }
}

const mapStateToProps = (state) => {
    return {
        qrcodes: state.qrcodes,
        auth: state.auth.authenticated,
        posData: state.posData.posData,
        loadingPos: state.posData.loadingPos,
    };
};

export default connect(mapStateToProps, { getAllPos, deletePos })(withRouter(PointsOfSale));