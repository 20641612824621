import React, { Component } from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import { Bars } from  'react-loader-spinner';import { login } from '../actions';
import { withRouter } from './withRouter';


class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: ''
    };

  }

  handleEmailInputChange(e) {
    this.setState({ email: e.target.value });
  }

  handlePasswordInputChange(e) {
    this.setState({ password: e.target.value });
  }

  handleLoginClick = () => {
    this.setState({
      loading: true
    });
    const { email, password } = this.state;
    this.props.login({ email, password }, () => {
      this.props.navigate('/')
    });
  };

  renderLoginError = () => {
    if (this.props.authError) {
      $('.loginErrorMsg').slideDown('fast');
    } else {
      $('.loginErrorMsg').hide();
    }
  };

  renderLoader = () => {
    if (this.props.loading) {
      return <Bars />;
    }
    return (
      <button
        type="submit"
        onClick={this.handleLoginClick}
        className="btn btn-primary"
      >
        Log in
      </button>
    );
  };

  render() {
    this.renderLoginError();
    return (
      <div className="loginWrapper">
        <div className="loginContainer">
          <span className="loginTitle">Audiolibros Admin</span>
          <input
            value={this.state.email}
            type="email"
            required
            placeholder="Email"
            onChange={e => this.handleEmailInputChange(e)}
            className="form-control"
          />
          <input
            value={this.state.password}
            type="password"
            required
            placeholder="Password"
            onChange={e => this.handlePasswordInputChange(e)}
            className="form-control"
          />
          <div className="loginErrorMsg">{this.props.authError}</div>
          {this.renderLoader()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth.authenticated,
    authError: state.auth.errorMessage,
    loading: state.auth.loading
  };
};


export default connect(
  mapStateToProps,
  { login }
)(withRouter(Login));
