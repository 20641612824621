import {
    NEW_POS,
    NEW_POS_CREATED_OK,
    NEW_POS_CREATED_ERROR,
    GET_ALL_POS,
    GET_ALL_POS_SUCCESS,
    GET_ALL_POS_ERROR,
    GET_POS_OK,
    UPDATING_POS,
    POS_UPDATED
} from '../actions/types';

const INITIAL_STATE = {
    savingPos: false,
    loadingPos: false,
    posMsg: '',
    posData: [],
    uniquePos: [],
    updatingPos: false,
    posUpdatedMsg: ''
};

export default function(state=INITIAL_STATE, action) {
    switch(action.type) {
        case NEW_POS:
            return { ...state, savingPos: true, posMsg: '' };
        case NEW_POS_CREATED_OK:
            return { ...state, savingPos: false, posMsg: 'Nuevo punto de venta guardado con exito' };
        case NEW_POS_CREATED_ERROR:
            return { ...state, savingPos: false, posMsg: 'Erro al tratar de guardar el punto de venta' };
        case GET_ALL_POS:
            return { ...state, loadingPos: true, posMsg: '' };
        case GET_ALL_POS_SUCCESS:
            return { ...state, loadingPos: false,  posData: action.payload, posMsg: '' };
        case GET_ALL_POS_ERROR:
            return { ...state, loadingPos: false, posMsg: action.payload };
        case GET_POS_OK:
            return { ...state, uniquePos: action.payload };
        case UPDATING_POS:
            return { ...state, updatingPos:true, posUpdatedMsg: '' };
        case POS_UPDATED:
            return { ...state, updatingPos: false, posUpdatedMsg: 'Punto de venta Actualizado!' };
        default:
            return state;
    }
}