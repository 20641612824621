import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import _ from 'lodash';
import Select from 'react-select';
import Switch from 'react-switch';
import { Progress } from 'react-sweet-progress';
import 'react-sweet-progress/lib/style.css';
import $ from 'jquery';
import ReactAudioPlayer from 'react-audio-player';
import { FormGroup, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from './withRouter';
import {
  newBook,
  imgUpload,
  audioUpload,
  getAllBooks,
  getAllCategories
} from '../actions';
import { Navigate, Link } from 'react-router-dom';
import Header from './Header';
import { Bars } from  'react-loader-spinner'
import logo from '../assets/logoHeader.png';

let self = null;

class NewBook2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      audioSample: '',
      audioComplete: [],
      description: '',
      editorial: '',
      name: '',
      outstanding: false,
      novelty: false,
      title: '',
      appleIdentifier: '',
      msg: '',
      bookType: '',
      image: null,
      wideImage: null,
      checked: false,
      open: false,
      openChapter: false,
      uploadingFile: false,
      catID: '',
      price: '',
      isbn: '',
      bookStyle: '',
      author: '',
      duration: '',
      chaptersReady: true
    };

    self = this;
  }
  componentDidMount() {
    this.props.getAllBooks();
    this.props.getAllCategories();
  }

  handleTitleChange = e => {
    this.setState(
      {
        title: e.target.value
      },
      function() {
        this.warningName();
      }
    );
  };

  checkName = () => {
    return _.filter(this.props.books, book => {
      return book.books.title === this.state.title;
    });
  };

  warningName = () => {
    if (this.checkName().length > 0) {
      this.setState({
        open: true
      });
    }
  };

  handleOptionChange = checked => {
    this.setState({ checked });
  };

  handleNoveltyChange = novelty => {
    this.setState({ novelty });
  };

  handleDescChange = e => {
    this.setState({
      description: e.target.value
    });
  };

  handleEditorialChange = e => {
    this.setState({
      editorial: e.target.value
    });
  };

  handleImageChange = e => {
    this.props.imgUpload(
      e.target.files[0],
      this.state.title,
      '/trailers/',
      '/',
      () => {
        this.setState({
          image: this.props.imageURL
        });
      }
    );
  };

  handleWideImageChange = e => {
    this.props.imgUpload(
      e.target.files[0],
      this.state.title,
      '/trailers/',
      'wide/',
      () => {
        this.setState({
          wideImage: this.props.imageURL
        });
      }
    );
  };

  handleAudioSampleChange = (e, audioPath) => {
    this.props.audioUpload(
      e.target.files[0],
      this.state.title,
      audioPath,
      () => {
        this.setState({
          audioSample: this.props.audioSampleURL
        });
      }
    );
  };

  handleAudioChaptersChange = (e, audioPath) => {
    let cantidad = e.target.files.length;
    this.setState({chaptersReady:false});
    this.btnStatusControl();
    for (let key in e.target.files) {
      if (e.target.files.hasOwnProperty(key)) {
        this.props.audioUpload(
          e.target.files[key],
          this.state.title,
          audioPath,
          () => {
            this.setState(
              {
                audioComplete: [
                  ...this.state.audioComplete,
                  this.props.audioCompleteURL
                ]
              },
              function() {
                let ordenado = this.state.audioComplete.sort();
                if(ordenado.length === cantidad){
                  this.setState({chaptersReady: false});
                  this.btnStatusControl();
                }else{
                  this.setState({chaptersReady: true});
                  this.btnStatusControl();
                }
              }
            );
          }
        );
      }
    }
  };

  handleAudioCompleteChange = (e, audioPath) => {
    let arr = this.state.audioComplete;
    this.props.audioUpload(
      e.target.files[0],
      this.state.title,
      audioPath,
      () => {
        arr.push(this.props.audioCompleteURL);
        this.setState(
          {
            audioComplete: arr
          },
          () => {
            this.setState({ openChapter: false });
          }
        );
      }
    );
  };

  renderSelectBookType = () => {
    const optionsType = [
      { value: 'Narrado', label: 'Narrado' },
      { value: 'Narrado Plus', label: 'Narrado Plus' },
      { value: 'Dramatizado', label: 'Dramatizado' },
    ];

    let selectedOpt = optionsType.findIndex(
      x => x.value === self.state.bookType
    );

    return (
      <Select
        value={optionsType[selectedOpt]}
        options={optionsType}
        onChange={this.handleBookTypeChange}
      />
    );
  };


  handleSaveClick = () => {
    let obj = {
      audioSample: this.state.audioSample,
      audioComplete: this.state.audioComplete.sort(),
      description: this.state.description,
      editorial: this.state.editorial,
      image: this.state.image,
      wideImage: this.state.wideImage,
      name: this.state.title.toLowerCase(),
      outstanding: this.state.checked,
      novelty: this.state.novelty,
      appleIdentifier: this.state.appleIdentifier,
      title: this.state.title,
      catId: this.state.catID,
      price: this.state.price,
      isbn: this.state.isbn,
      bookStyle: this.state.bookStyle,
      bookType: this.state.bookType,
      author: this.state.author,
      duration: this.state.duration
    };
    console.log(obj)
    this.props.newBook(obj, () => {
      if (this.props.updateMsgOk !== '') {
        this.setMsgClass('successUpdate', this.props.msgOk);
      } else if (this.props.updateMsgError !== '') {
        this.setMsgClass('errorUpdate', this.props.msgError);
      }
    });
  };

  setMsgClass = (status, message) => {
    $('.updateMsg')
      .text(message)
      .addClass(status)
      .slideDown('fast', function() {
        setTimeout(() => {
          self.props.navigate('/books');
        }, 1000);
      });
  };

  renderImgProgressBar = () => {
    if (this.props.imageUploading) {
      return (
        <Progress
          percent={this.props.imgProgress}
          theme={{
            error: {
              symbol: this.props.imgProgress + '%',
              trailColor: 'pink',
              color: 'red'
            },
            default: {
              symbol: this.props.imgProgress + '%',
              trailColor: 'lightblue',
              color: 'blue'
            },
            active: {
              symbol: this.props.imgProgress + '%',
              trailColor: 'yellow',
              color: 'orange'
            },
            success: {
              symbol: this.props.imgProgress + '%',
              trailColor: 'lime',
              color: 'green'
            }
          }}
        />
      );
    }
  };

  renderWideImgProgressBar = () => {
    if (this.props.uploadingWideImg) {
      return (
        <Progress
          percent={this.props.imgProgress}
          theme={{
            error: {
              symbol: this.props.imgProgress + '%',
              trailColor: 'pink',
              color: 'red'
            },
            default: {
              symbol: this.props.imgProgress + '%',
              trailColor: 'lightblue',
              color: 'blue'
            },
            active: {
              symbol: this.props.imgProgress + '%',
              trailColor: 'yellow',
              color: 'orange'
            },
            success: {
              symbol: this.props.imgProgress + '%',
              trailColor: 'lime',
              color: 'green'
            }
          }}
        />
      );
    }
  };

  renderProgressBar = bookType => {
    const WICH_ONE =
      bookType === 'sample'
        ? this.props.audioSampleProgress
        : this.props.audioCompleteProgress;
    return (
      <Progress
        percent={WICH_ONE}
        theme={{
          error: {
            symbol: WICH_ONE + '%',
            trailColor: 'pink',
            color: 'red'
          },
          default: {
            symbol: WICH_ONE + '%',
            trailColor: 'lightblue',
            color: 'blue'
          },
          active: {
            symbol: WICH_ONE + '%',
            trailColor: 'yellow',
            color: 'orange'
          },
          success: {
            symbol: WICH_ONE + '%',
            trailColor: 'lime',
            color: 'green'
          }
        }}
      />
    );
  };

  renderAudioSampleProgressBar = () => {
    if (this.props.audioSampleUploading) {
      return this.renderProgressBar('sample');
    }
  };

  renderAudioCompleteProgressBar = () => {
    if (this.props.audioCompleteUploading) {
      return this.renderProgressBar('complete');
    }
  };

  btnStatusControl = () => {
    if (
      this.state.title === '' ||
      this.state.catID === '' ||
      this.props.imageUploading ||
      this.props.audioSampleUploading ||
      this.props.audioCompleteUploading ||
      this.state.chaptersReady
    ) {
      return true;
    } else {
      return false;
    }
  };

  handleCatChange = obj => {
    this.setState({ catID: obj.value });
  };

  handlePriceChange = e => {
    this.setState({
      price: e.target.value
    });
  };

  handleIsbnChange = e => {
    this.setState({
      isbn: e.target.value
    });
  };

  handleAppleIdentifierChange = e => {
    this.setState({
      appleIdentifier: e.target.value
    });
  };

  handleAuthorChange = e => {
    this.setState({
      author: e.target.value
    });
  };

  handleDurationChange = e => {
    this.setState({
      duration: e.target.value
    });
  };

  handleBookStyleChange = obj => {
    this.setState({ bookStyle: obj.value });
  };
  handleBookTypeChange = obj => {
    this.setState({ bookType: obj.value });
  };

  renderSelectBookStyle = () => {
    const optionsStyle = [
      {
        value: 'abreviado',
        label: 'Abreviado'
      },
      {
        value: 'completo',
        label: 'Completo'
      }
    ];

    return (
      <Select options={optionsStyle} onChange={this.handleBookStyleChange} />
    );
  };

  renderSelectBookType = () => {
    const optionsType = [
      { value: 'Narrado', label: 'Narrado' },
      { value: 'Narrado Plus', label: 'Narrado Plus' },
      { value: 'Dramatizado', label: 'Dramatizado' },
    ];

    let selectedOpt = optionsType.findIndex(
      x => x.value === self.state.bookType
    );

    return (
      <Select
        value={optionsType[selectedOpt]}
        options={optionsType}
        onChange={this.handleBookTypeChange}
      />
    );
  };


  renderSelectCategory = () => {
    if (this.props.categories.length > 0) {
      let optionsCat = [];
      this.props.categories.forEach(function(cat) {
        optionsCat.push({
          value: cat.catId,
          label: cat.categorie.name
        });
      });
      return <Select options={optionsCat} onChange={this.handleCatChange} />;
    }
    return <Bars color="#17a2b8" height={30} width={30} />;
  };

  handleCloseChapterModal = () => {
    this.setState({
      openChapter: false
    });
  };

  renderCloseBtn = () => {
    if (this.props.audioCompleteUploading) {
      return <Bars color="#17a2b8" height={30} width={30} />;
    }
    return (
      <button
        className="btn btn-danger btn-sm"
        onClick={this.handleCloseChapterModal}
      >
        Cerrar
      </button>
    );
  };

  handleDeleteChapter = index => {
    let arr = this.state.audioComplete;
    arr.splice(index, 1);
    this.setState({ audioComplete: arr });
  };

  renderChapters = () => {
    if (this.state.audioComplete.length === 0) {
      return <span>No hay capítulos cargados actualmente</span>;
    }
    return this.state.audioComplete.map((audio, index) => {
      return (
        <div key={audio} className="charpterListItem">
          <span className="chapterTitle">Capítulo {index + 1}</span>
          <div className="">
            <ReactAudioPlayer src={audio} autoPlay={false} controls />
          </div>
          <button
            className="btn btn-danger"
            onClick={() => {
              this.handleDeleteChapter(index);
            }}
          >
            Borrar
          </button>
        </div>
      );
    });
  };

  renderBookEditForm = () => {
    const { open, openChapter } = this.state;
    return (
      <div className="componentContainer">
        <Modal open={open} onClose={() => {}} showCloseIcon={false}>
          <div className="modalContentContainer">
            <span className="txtModalNewBook">
              ¡Ya existe el audiolibro {this.state.title}!
            </span>
            <span className="txt2ModalNewBook">
              Por favor elija otro nombre
            </span>
            <div className="modalBtnsContainer2">
              <button
                className="btn btn-primary"
                onClick={() => this.setState({ open: false, title: '' })}
              >
                Aceptar
              </button>
            </div>
          </div>
        </Modal>

        <Modal open={openChapter} onClose={() => {}} showCloseIcon={false}>
          <div className="formGroupDualChapter">
            <span
              className={this.state.title === '' ? 'warningTitle' : 'hidden'}
            >
              Debe ingresar un titulo antes de continuar
            </span>
            <div className="unGrupo">
              <div className="formGroupDualItem">
                <FormGroup>
                  <Input
                    disabled={this.state.title === '' ? true : false}
                    type="file"
                    onChange={e => this.handleAudioCompleteChange(e, 'books')}
                  />
                </FormGroup>
                {this.renderAudioCompleteProgressBar()}
              </div>
            </div>
            {this.renderCloseBtn()}
          </div>
        </Modal>

        <Header />
        <div className="sectionContainer">
          <div>
            <div className="headerSection">
              <span>Carga de Nuevo Audiolibro</span>
              <img src={logo} alt="Audiolibros" className="logoHeader" />
            </div>
          </div>
          <div>
            <div className="editFormContainer">
              <div className="formGroup">
                <span className="formLabel">Titulo</span>
                <input
                  className="form-control"
                  type="text"
                  value={this.state.title}
                  onChange={e => this.handleTitleChange(e)}
                />
              </div>

              <div className="formGroup">
                <span className="formLabel">Editorial</span>
                <input
                  className="form-control"
                  type="text"
                  value={this.state.editorial}
                  onChange={e => this.handleEditorialChange(e)}
                />
              </div>

              <div className="formGroup">
                <span className="formLabel">Descripción</span>
                <textarea
                  className="form-control"
                  value={this.state.description}
                  onChange={e => this.handleDescChange(e)}
                />
              </div>

              <div className="formGroup">
                <span className="formLabel">Genero</span>
                {this.renderSelectCategory()}
              </div>

              <div className="formGroup">
                <span className="formLabel">ISBN</span>
                <input
                  className="form-control"
                  type="text"
                  value={this.state.isbn}
                  onChange={e => this.handleIsbnChange(e)}
                />
              </div>

              <div className="formGroup">
                <span className="formLabel">Identificador Apple</span>
                <input
                  className="form-control"
                  type="text"
                  value={this.state.appleIdentifier}
                  onChange={e => this.handleAppleIdentifierChange(e)}
                />
              </div>

              <div className="formGroup">
                <span className="formLabel">Autor</span>
                <input
                  className="form-control"
                  type="text"
                  value={this.state.author}
                  onChange={e => this.handleAuthorChange(e)}
                />
              </div>

              <div className="formGroup">
                <span className="formLabel">Duración</span>
                <input
                  className="form-control"
                  type="text"
                  value={this.state.duration}
                  onChange={e => this.handleDurationChange(e)}
                />
              </div>

              <div className="formGroup">
                <span className="formLabel">Tipo</span>
                {this.renderSelectBookStyle()}
              </div>

              <div className="formGroup">
                <span className="formLabel">Estilo</span>
                {this.renderSelectBookType()}
              </div>

              <div className="formGroup">
                <span className="formLabel">Precio</span>
                <input
                  className="form-control"
                  type="number"
                  value={this.state.price}
                  onChange={e => this.handlePriceChange(e)}
                />
              </div>

              <div className="formGroup">
                <span className="formLabel">Imagen</span>
                <div className="formGroupDual">
                  <div className="formGroupDualItem">
                    <FormGroup>
                      <Input
                        disabled={this.state.title === '' ? true : false}
                        type="file"
                        onChange={e => this.handleImageChange(e)}
                      />
                    </FormGroup>
                    {this.renderImgProgressBar()}
                  </div>
                  <div className="formGroupDualItem">
                    <img
                      className="editImage"
                      src={this.state.downloadUrl}
                      alt={this.state.title}
                    />
                  </div>
                </div>
              </div>

              <div className="formGroup">
                <span className="formLabel">Imagen Destacada</span>
                <div className="formGroupDual">
                  <div className="formGroupDualItem">
                    <FormGroup>
                      <Input
                        disabled={this.state.title === '' ? true : false}
                        type="file"
                        onChange={e => this.handleWideImageChange(e)}
                      />
                    </FormGroup>
                    {this.renderWideImgProgressBar()}
                  </div>
                  <div className="formGroupDualItem">
                    <img
                      className="editImage"
                      src={this.state.downloadUrl}
                      alt={this.state.title}
                    />
                  </div>
                </div>
              </div>

              <div className="formGroup">
                <span className="formLabel">Audio Demo</span>
                <div className="formGroupDual">
                  <div className="formGroupDualItem">
                    <FormGroup>
                      <Input
                        disabled={this.state.title === '' ? true : false}
                        type="file"
                        onChange={e =>
                          this.handleAudioSampleChange(e, 'trailers')
                        }
                      />
                    </FormGroup>
                    {this.renderAudioSampleProgressBar()}
                  </div>
                  <div className="formGroupDualItem">
                    <ReactAudioPlayer
                      src={this.state.audioSample}
                      autoPlay={false}
                      controls
                    />
                  </div>
                </div>
              </div>

              <div className="formGroup">
                <span className="formLabel">Audio Completo (Capítulos)</span>
                <div className="formGroupDual">
                  <div className="formGroupDualItem">
                    <FormGroup>
                      <Input
                        multiple
                        disabled={this.state.title === '' ? true : false}
                        type="file"
                        onChange={e =>
                          this.handleAudioChaptersChange(e, 'books')
                        }
                      />
                    </FormGroup>
                    {this.renderAudioCompleteProgressBar()}
                  </div>
                  <div className="formGroupDualItem" />
                </div>
              </div>

              <div className="formGroup radioGroupForm">
                <span className="formLabel">Destacado</span>
                <div className="switchContainer">
                  <Switch
                    onChange={this.handleOptionChange}
                    checked={this.state.checked}
                    id="normal-switch"
                  />
                  <span>{this.state.checked ? 'SI' : 'NO'}</span>
                </div>
              </div>

              <div className="formGroup radioGroupForm">
                <span className="formLabel">Novedad</span>
                <div className="switchContainer">
                  <Switch
                    onChange={this.handleNoveltyChange}
                    checked={this.state.novelty}
                    id="normal-switch"
                  />
                  <span>{this.state.novelty ? 'SI' : 'NO'}</span>
                </div>
              </div>

              <div className="keypad">
                <button
                  disabled={this.btnStatusControl()}
                  className="btn btn-success"
                  onClick={this.handleSaveClick}
                >
                  Guardar
                </button>
                <Link to="/books" className="btn btn-danger">
                  Cancelar
                </Link>
                <span className="updateMsg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderComponent = () => {
    return this.props.auth ? (
      this.renderBookEditForm()
    ) : (
      <Navigate
        to={{
          pathname: '/login',
          state: { from: this.props.location }
        }}
      />
    );
  };
  render() {
    return this.renderComponent();
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth.authenticated,
    books: state.books.books,
    msgOk: state.bookUpdate.updateMsgOk,
    msgError: state.bookUpdate.updateMsgError,
    imgProgress: state.uploadProgress.imgProgress,
    audioSampleProgress: state.uploadProgress.audioSampleProgress,
    audioCompleteProgress: state.uploadProgress.audioCompleteProgress,
    imageURL: state.imageUpload.imageUrl,
    wideImageURL: state.imageUpload.wideImageURL,
    imageUploading: state.imageUpload.uploadingImg,
    uploadingWideImg: state.imageUpload.uploadingWideImg,
    audioSampleURL: state.audioUpload.audioSampleUrl,
    audioCompleteURL: state.audioUpload.audioCompleteUrl,
    audioSampleUploading: state.audioUpload.uploadingSampleAudio,
    audioCompleteUploading: state.audioUpload.uploadingCompleteAudio,
    categories: state.categories.categories
  };
};

export default connect(
  mapStateToProps,
  {
    newBook,
    imgUpload,
    audioUpload,
    getAllBooks,
    getAllCategories
  }
)(withRouter(NewBook2));
