import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Navigate, Link } from 'react-router-dom';
import LazyLoad from 'react-image-lazy-load';
import {
    getAllUsers,
    getSale,
    getBook,
    getPos,
    getQrDataOfSale,
    getPin
} from '../actions';
import Header from './Header';
let self = null;

class SaleDetail extends Component {
    constructor(props){
        super(props);
        self = this;
        this.state = {
            localPointOfSale: '',
            mpVoucher: '',
            saleQrData: null,
            bookImage: ''
        };
    }

    componentDidMount = () => {
        const { params } = this.props;
        this.props.getSale(params.id, () => {
            self.props.getBook(self.props.sale.idBook, () => {
                self.setState({bookImage: self.props.book.image});
            });
            if(self.props.sale.pointOfSale === 'app'){
                self.setState({localPointOfSale: 'App', mpVoucher: self.props.sale.app.mpVoucher});
            }else{
                self.props.getPin(self.props.sale.idQr);
                self.props.getPos(self.props.sale.pointOfSale, () => {
                    self.setState({localPointOfSale: self.props.pointOfSale.name});
                });
                self.props.getQrDataOfSale(self.props.sale.idQr, () => {
                    self.setState({saleQrData: self.props.saleQr});
                });
            }
        });
    }


    renderDetails = () => {
        if(!this.props.loadingSale){
            let theDate = '';
            if(self.props.sale.date){
                let curdate = new Date(null);
                curdate.setTime((self.props.sale.date.seconds)*1000);
                theDate = curdate.toLocaleDateString() + ' - ' + curdate.toLocaleTimeString();
            }

            return (
                <div className="detailContainer">
                    <span className="detailTitle"><strong>Titulo:</strong> {this.props.book.title}</span>
                    {this.renderImage()}
                    <span className="buyDate"><strong>Fecha de compra:</strong> {theDate}</span>
                    <span className="pointOfSale"><strong>Punto de venta:</strong> {this.state.localPointOfSale}</span>
                    {this.state.mpVoucher === '' ? '' : <span className="mpVoucher"><strong>Comprobante de MP: </strong>{this.state.mpVoucher}</span>}
                    {this.renderSaleQrData()}
                </div>
            );
        }
    }

    renderImage = () => {
        return (
            <LazyLoad
                originalSrc={this.state.bookImage}
                loaderImage={true}
                imageProps={{
                    src: require('../assets/25.gif'),
                    alt: 'Audiolibros',
                    className: 'detailImage'
                }}
            />
        );
    }

    renderSaleQrData = () => {
        if(this.state.saleQrData !== null){
            return (
                <div className="extraData">
                    <span className="extraDataBatchId"><strong>Pool ID:</strong> {this.state.saleQrData.idBatch}</span>
                    <span className="extraDataQrId"><strong>ID QR:</strong> {this.state.saleQrData.idQr}</span>
                    <span className="extraDataPin"><strong>PIN:</strong> {this.props.pin.pin}</span>
                    <span className="extraDataUser"><strong>Usuario:</strong> {this.props.sale.user}</span>
                    <div className="saleBtnHolder">
                        <Link
                            className="btn btn-info btn-sm"
                            to={`/batch/${this.state.saleQrData.idBatch}`}
                        >
                            Ver Pool
                        </Link>
                        <Link
                            className="btn btn-primary btn-sm"
                            to={'/'}
                        >
                            Volver
                        </Link>
                    </div>
                </div>
            );
        }
    }

    redirectToPool = () => {

    }

    renderSaleDetail = () => {
        return (
            <div className="componentContainer">
                <Header />
                <div className="homeContainer">
                    <div className="homeTitle">Detalle de la venta</div>
                    <div className="homeContent">
                        {this.renderDetails()}

                    </div>
                </div>

            </div>
        );
    }

    renderComponent = () => {
        return this.props.auth ? (
            this.renderSaleDetail()
        ) : (
           <Navigate
                to={{
                    pathname: '/login',
                    state: { from: this.props.location }
                }}
            />
        );
    }

    render() {
        return this.renderComponent();
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth.authenticated,
        sale: state.sales.sale,
        loadingSale: state.sales.loadingSale,
        book: state.book.book,
        pointOfSale: state.posData.uniquePos,
        saleQr: state.sales.saleQr,
        pin: state.pin.pin
    };
};

export default connect(
    mapStateToProps,
    {
        getAllUsers,
        getSale,
        getBook,
        getPos,
        getQrDataOfSale,
        getPin,
    }
)(SaleDetail);