import React, { Component } from 'react';
import ModernDatepicker from 'react-modern-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { Navigate, Link } from 'react-router-dom';
import $ from 'jquery';
import { Progress } from 'react-sweet-progress';
import { FormGroup, Input } from 'reactstrap';
import { Bars } from  'react-loader-spinner'
import { getCombo, getAllBooks, imgUpload, updateCombo } from '../actions';
import Header from './Header';
import { withRouter } from './withRouter';
import logo from '../assets/logoHeader.png';
import './NewCombo.css';

let self = null;

export class ComboEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dateExpired: null,
      dateCreated: null,
      image: null,
      comboBooks: [],
      term: '',
      title: '',
      price: '',
      id: ''
    };

    self = this;
  }

  componentDidMount() {
    this.props.getAllBooks();
    const {
      match: { params }
    } = this.props;
    this.props.getCombo(params.id, () => {
      self.setState({
        id: params.id,
        dateExpired: this.props.combo.dateExpired,
        dateCreated: this.props.combo.dateCreated,
        image: this.props.combo.image,
        comboBooks: this.props.combo.combo,
        term: '',
        title: this.props.combo.title,
        price: this.props.combo.price
      });
    });
  }

  renderDatePicker = () => {
    return (
      <ModernDatepicker
        date={this.state.dateExpired}
        format={'DD-MM-YYYY'}
        showBorder
        onChange={date => this.handleDateChange(date)}
        placeholder={'Seleccione una fecha'}
      />
    );
  };

  handleDateChange = date => {
    this.setState({ dateExpired: date });
  };

  renderComboForm = () => {
    return (
      <div className="componentContainer">
        <Header />
        <div className="sectionContainer">
          <div>
            <div className="headerSection">
              <span>Edición Combo</span>
              <img src={logo} alt="Audiolibros" className="logoHeader" />
            </div>
          </div>
          <div className="booksColumns">
            <div>{this.renderNewComboForm()}</div>
          </div>
        </div>
      </div>
    );
  };

  handleImageChange = e => {
    this.props.imgUpload(
      e.target.files[0],
      this.state.title,
      '/combos/',
      '/',
      () => {
        this.setState({
          image: this.props.imageURL
        });
      }
    );
  };

  handleSearchChange = e => {
    this.setState({ term: e.target.value });
  };

  handleAddBooktoCombo = book => {
    let bookArr = this.state.comboBooks;
    bookArr.push(book);
    this.setState({
      comboBooks: bookArr
    });
  };

  handleRemoveBooktoCombo = book => {
    let bookArr = this.state.comboBooks.filter(combo => {
      return combo.idBook !== book.idBook;
    });

    this.setState({ comboBooks: bookArr });
  };

  renderBooksList = () => {
    if (this.props.books) {
      return this.props.books
        .filter(book => {
          return book.books.name.indexOf(this.state.term) > -1;
        })
        .map(item => {
          return this.renderItemBookList(item);
        });
    }
  };

  renderItemBookList = book => {
    return (
      <li className="booksListItem" key={book.idBook}>
        <div className="booksListItemDiv">
          <span className="booksListItemText">{book.books.title}</span>
          <button
            className="booksListItemIcon"
            onClick={() => this.handleAddBooktoCombo(book)}
          >
            <FontAwesomeIcon icon="plus" size="1x" />
          </button>
        </div>
      </li>
    );
  };

  renderComboBooksList = () => {
    if (this.state.comboBooks.length > 0) {
      return this.state.comboBooks.map(book => {
        return (
          <li key={book.idBook}>
            <div className="booksListItemDiv">
              <span className="booksListItemText">{book.books.title}</span>
              <button
                className="booksListItemIcon comboMinusIcon"
                onClick={() => this.handleRemoveBooktoCombo(book)}
              >
                <FontAwesomeIcon icon="minus" size="1x" />
              </button>
            </div>
          </li>
        );
      });
    }
    return null;
  };

  handleNameChange = e => {
    this.setState({
      title: e.target.value
    });
  };

  handlePriceChange = e => {
    this.setState({
      price: e.target.value
    });
  };

  handleUpdateCombo = () => {
    const obj = {
      id: this.state.id,
      combo: this.state.comboBooks,
      image: this.state.image,
      title: this.state.title,
      price: this.state.price,
      dateExpired: this.state.dateExpired.toLocaleString(),
      dateCreated: this.state.dateCreated.toLocaleString()
    };

    this.props.updateCombo(obj, () => {
      if (this.props.comboUpdatedOk) {
        this.setMsgClass('successUpdate');
      }
    });
  };

  setMsgClass = status => {
    $('.combo-created-ok')
      .addClass(status)
      .slideDown('fast', function() {
        setTimeout(() => {
          self.props.navigate('/combos');
        }, 1000);
      });
  };

  renderNewComboForm = () => {
    return (
      <div className="editFormContainer">
        <div className="formGroup">
          <span className="formLabel">Nombre</span>
          <FormGroup>
            <Input
              value={this.state.title}
              type="text"
              onChange={e => this.handleNameChange(e)}
            />
          </FormGroup>
        </div>
        <div className="formGroup">
          <span className="formLabel">Precio</span>
          <FormGroup>
            <Input
              value={this.state.price}
              type="text"
              onChange={e => this.handlePriceChange(e)}
            />
          </FormGroup>
        </div>
        <div className="formGroup">
          <span className="formLabel">Imagen</span>
          <div className="formGroupDual">
            <div className="formGroupDualItem">
              <FormGroup>
                <Input
                  disabled={this.state.title === '' ? true : false}
                  type="file"
                  onChange={e => this.handleImageChange(e)}
                />
              </FormGroup>
              {this.renderImgProgressBar()}
            </div>
            <div className="formGroupDualItem">
              <img
                className="editImage"
                src={this.state.image}
                alt={this.state.title}
              />
            </div>
          </div>
        </div>
        <div className="formGroup">
          <span className="formLabel">
            Fecha expiración
            <small> ( Por defecto, 7 días apartir de hoy )</small>
          </span>
          {this.renderDatePicker()}
        </div>
        <div className="formGroup">
          <div className="comboBooksSelectionZone">
            <div className="booksList">
              <span className="booksListTitle">Listado de Audiolibros</span>
              <input
                type="text"
                placeholder="Buscar"
                className="form-control"
                onChange={e => this.handleSearchChange(e)}
              />
              <ul className="booksListUL">{this.renderBooksList()}</ul>
            </div>
            <div className="booksInComboList">
              <span className="comboListTitle">Audiolibros en el combo</span>
              <ul className="booksListULcombo">
                {this.renderComboBooksList()}
              </ul>
            </div>
          </div>
        </div>
        <div className="formGroup">
          <div className="comboBtnsContainer">
            <button
              disabled={this.accessButton()}
              onClick={this.handleUpdateCombo}
              className="btn btn-success"
            >
              Actualizar
            </button>
            <Link to="/combos" className="btn btn-danger">
              Cancelar
            </Link>
          </div>
          <span className="combo-created-ok">Combo actualizado con exito</span>
        </div>
      </div>
    );
  };

  accessButton = () => {
    if (
      this.state.title === '' ||
      this.state.comboBooks.length === 0 ||
      this.state.image === null
    ) {
      return true;
    } else {
      return false;
    }
  };

  renderImgProgressBar = () => {
    if (this.props.imageUploading) {
      return (
        <Progress
          percent={this.props.imgProgress}
          theme={{
            error: {
              symbol: this.props.imgProgress + '%',
              trailColor: 'pink',
              color: 'red'
            },
            default: {
              symbol: this.props.imgProgress + '%',
              trailColor: 'lightblue',
              color: 'blue'
            },
            active: {
              symbol: this.props.imgProgress + '%',
              trailColor: 'yellow',
              color: 'orange'
            },
            success: {
              symbol: this.props.imgProgress + '%',
              trailColor: 'lime',
              color: 'green'
            }
          }}
        />
      );
    }
  };

  renderLoader = () => {
    if (this.props.creatingCat) {
      return (
        <div className="newCatLoaderCont">
          <Bars color="#17a2b8" height={30} width={30} />
        </div>
      );
    }
  };

  renderComponent() {
    return this.props.auth ? (
      this.renderComboForm()
    ) : (
      <Navigate
        to={{
          pathname: '/login',
          state: { from: this.props.location }
        }}
      />
    );
  }

  render() {
    return this.renderComponent();
  }
}

const mapStateToProps = state => ({
  auth: state.auth.authenticated,
  combo: state.combo.combo,
  books: state.books.books,
  imgProgress: state.uploadProgress.imgProgress,
  imageURL: state.imageUpload.imageUrl,
  imageUploading: state.imageUpload.uploadingImg,
  comboUpdatedOk: state.combo.comboUpdatedOk
});

export default connect(
  mapStateToProps,
  { getCombo, getAllBooks, imgUpload, updateCombo }
)(withRouter(ComboEdit));
