import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Navigate, Link } from 'react-router-dom';
import {
    getAllBooks,
    getAllUsers,
    getAllPos,
    getBatches,
    getAllQrsByBatch,
    getAllSales
} from '../actions';
import Header from './Header';
import logo from '../assets/logoHeader.png';

class Home extends Component {
    componentDidMount() {
        this.props.getAllBooks();
        this.props.getAllUsers();
        this.props.getAllPos();
        this.props.getBatches();
        this.props.getAllQrsByBatch();
        this.props.getAllSales();
    }

    renderHome = () => {
        return (
            <div className="componentContainer">
                <Header />
                <div className="sectionContainer">
                    <div>
                        <div className="headerSection headerBooks">
                            <span>Inicio</span>
                            <img src={logo} alt="Audiolibros" className="logoHeader"/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderUsersRegistered = () => {
        return (
            <div className="homeEachContent">
                <span className="homeEachTitle">Usuarios registrados en la aplicación</span>
                <br/>
                <span className="numbers">{this.props.users.length}</span>
                <Link
                    to="/users"
                    className="btn btn-info"
                >
                    Ver
                </Link>
            </div>
        );
    }

    renderBooksActives = () => {
        return (
            <div className="homeEachContent">
                <span className="homeEachTitle">Libros publicados en la aplicación</span>
                <br/>
                <span className="numbers">{this.props.books.length}</span>
                <Link
                    to="/books"
                    className="btn btn-info"
                >
                    Ver
                </Link>
            </div>
        );
    }

    renderComponent = () => {
        return this.props.auth ? (
            this.renderHome()
        ) : (
            <Navigate
                to={{
                    pathname: '/login',
                    state: { from: this.props.location }
                }}
            />
        );
    }

    render() {
        return this.renderComponent();
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth.authenticated,
        books: state.books.books,
        users: state.users.users,
        pointOfSales: state.posData.posData,
        batches: state.batches.batches,
        qrsByBatch: state.qrsByBatch.qrsByBatch,
        sales: state.sales.sales
    };
};

export default connect(
    mapStateToProps,
    {
        getAllBooks,
        getAllUsers,
        getAllPos,
        getBatches,
        getAllQrsByBatch,
        getAllSales
    }
)(Home);