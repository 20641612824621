import React, { Component } from 'react';
import { Bars } from  'react-loader-spinner'
import Select from 'react-select';
import ReactTable from 'react-table-6';
import { Link } from 'react-router-dom';
import 'react-table-6/react-table.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let self = null;

const columns = [
  {
    id: 'product',
    Header: 'Producto',
    Cell: row => {
      let book = self.props.books.filter(book => {
        return book.idBook === row.original.idBook;
      });
      return book[0].books.title;
    }
  },
  {
    id: 'pointOfSale',
    Header: 'Punto de venta',
    Cell: row => {
      let pos = self.props.pointOfSales.filter(point => {
        return point.idPOS === row.original.idPos;
      });
      return pos[0].pos.name;
    }
  },
  {
    id: 'qr_used',
    Header: 'QR usados',
    Cell: row => {
      if (self.props.qrsByBatch) {
        let info = self.props.qrsByBatch
          .filter(item => {
            return item.data.idBatch === row.original.batchId;
          })
          .filter(item => {
            return item.data.used === true;
          });
        return info.length;
      }
    }
  },
  {
    id: 'qr_available',
    Header: 'QR disponibles',
    Cell: row => {
      if (self.props.qrsByBatch) {
        let info = self.props.qrsByBatch
          .filter(item => {
            return item.data.idBatch === row.original.batchId;
          })
          .filter(item => {
            return item.data.used === false;
          });
        return info.length;
      }
    }
  },
  {
    id: 'amount',
    Header: 'Total',
    Cell: row => {
      if (self.props.qrsByBatch) {
        let info = self.props.qrsByBatch
          .filter(item => {
            return item.data.idBatch === row.original.batchId;
          })
          .filter(item => {
            return item.data.used === true;
          });
        return info.length;
      }
    }
  },
  {
    id: 'actions',
    Header: 'Acciones',
    Cell: row => {
      return (
        <div className="qrTableBtns">
          <Link
            to={`/batch/${row.original.batchId}`}
            className="btn btn-primary btn-sm"
          >
            <FontAwesomeIcon icon="eye" size="1x" />
            <span className="seeBatchDetailBtn">Ver</span>
          </Link>
        </div>
      );
    }
  }
];

class MainStatus extends Component {
  constructor(props) {
    super(props);
    self = this;

    this.state = {
      bookFilter: '',
      pointOfSaleFilter: ''
    };
  }

  renderSelectProduct = () => {
    if (this.props.books.length > 0) {
      let optionsBook = [
        {
          value: '',
          label: 'Todos'
        }
      ];
      this.props.books.forEach(function(book) {
        optionsBook.push({
          value: book.idBook,
          label: book.books.title
        });
      });
      let selectedOpt = optionsBook.findIndex(
        x => x.value === self.state.bookFilter
      );
      return (
        <Select
          value={optionsBook[selectedOpt]}
          options={optionsBook}
          onChange={this.handleBookChange}
        />
      );
    }
    return <Bars color="#17a2b8" height={30} width={30} />;
  };

  handleBookChange = obj => {
    this.setState({
      bookFilter: obj.value,
      pointOfSaleFilter: ''
    });
  };

  renderSelectPos = () => {
    if (this.props.pointOfSales.length > 0) {
      let optionsPos = [
        {
          value: '',
          label: 'Todos'
        }
      ];
      this.props.pointOfSales.forEach(function(pos) {
        optionsPos.push({
          value: pos.idPOS,
          label: pos.pos.name
        });
      });
      let selectedOpt = optionsPos.findIndex(
        x => x.value === self.state.pointOfSaleFilter
      );
      return (
        <Select
          value={optionsPos[selectedOpt]}
          options={optionsPos}
          onChange={this.handlePosChange}
        />
      );
    }
    return <Bars color="#17a2b8" height={30} width={30} />;
  };

  handlePosChange = obj => {
    this.setState({
      bookFilter: '',
      pointOfSaleFilter: obj.value
    });
  };

  renderStatusTable = () => {
    let filteredData = null;

    if (this.state.bookFilter !== '') {
      filteredData = this.props.batches.filter(batch => {
        return batch.idBook === this.state.bookFilter;
      });
    }

    if (this.state.pointOfSaleFilter !== '') {
      filteredData = this.props.batches.filter(batch => {
        return batch.idPos === this.state.pointOfSaleFilter;
      });
    }

    let result = filteredData === null ? this.props.batches : filteredData;

    return (
      <ReactTable
        data={result}
        columns={columns}
        defaultPageSize={15}
        showPageSizeOptions={false}
        className="-striped"
        previousText="Anterior"
        nextText="Siguiente"
        loadingText="Cargando..."
        noDataText="No se encontraron filas"
        pageText="Pagina"
        ofText="de"
        rowsText="filas"
      />
    );
  };

  clearFilters = () => {
    this.setState({
      bookFilter: '',
      pointOfSaleFilter: ''
    });
  };

  render() {
    return (
      <div className="mainHomeContainer">
        <div className="filterBoxContainer">
          <div className="formGroup">
            <span className="formLabel">Filtrar por producto</span>
            {this.renderSelectProduct()}
          </div>
          <div className="formGroup">
            <span className="formLabel">Filtrar por punto de venta</span>
            {this.renderSelectPos()}
          </div>
          <div className="formGroup">
            <br />
            <button className="btn btn-info" onClick={this.clearFilters}>
              Limpiar
            </button>
          </div>
        </div>
        <div className="homeTableHolder">{this.renderStatusTable()}</div>
      </div>
    );
  }
}

export default MainStatus;
