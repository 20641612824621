import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from '../actions';
import { withRouter } from './withRouter';

class Logout extends Component {
    componentDidMount() {
        this.props.logout('ok logout', () => {
            this.props.navigate('/')
        });
    }

    render() {
        return (
            <div></div>
        );
    }
}


export default connect(null, {logout})(withRouter(Logout));