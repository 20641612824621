import {
    LOGIN_LOADING,
    LOGIN_OK,
    LOGIN_ERROR,
    LOG_OUT_OK,
} from '../actions/types';

const INITIAL_STATE = {
    authenticated: '',
    errorMessage: '',
    loading: false
}
export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case LOGIN_LOADING:
            return { ...state, errorMessage:'', loading: true}
        case LOGIN_OK:
            return { ...state, authenticated: action.payload, loading: false }
        case LOGIN_ERROR:
            return { ...state, errorMessage: action.payload, loading: false  }
        case LOG_OUT_OK:
            return { ...state, ...INITIAL_STATE }
        default:
            return state;
    }
}