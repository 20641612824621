import {
    GET_ALL_QRCODES,
    GET_ALL_QRCODES_SUCCESS,
    GET_ALL_QRCODES_ERROR,
    UPDATE_QR_STATUS,
    UPDATE_QR,
} from '../actions/types';

const INITIAL_STATE = {
    qrcodes: [],
    msg: '',
    loadingQR: false,
    updattingQr: false,
};

export default function (state = INITIAL_STATE, action) {
    switch(action.type) {
        case GET_ALL_QRCODES:
            return { 
                ...state, 
                loadingQR: true,
                msg: ''
            };
        case GET_ALL_QRCODES_SUCCESS:
            return {
                ...state,
                loadingQR: false,
                qrcodes: action.payload
            };
        case GET_ALL_QRCODES_ERROR:
            return {
                ...state,
                loadingQR: false,
                qrcodes: action.payload
            };
        case UPDATE_QR:
            return { ...state, msg: '', updattingQr: true};
        case UPDATE_QR_STATUS:
            return { ...state, msg: '', updattingQr: false};
        default:
            return state;
    } 
}