import { GET_ALL_BOOKS, GET_ALL_BOOKS_SUCCESS } from '../actions/types';

const INITIAL_STATE = {
    loadingBooks: false,
    books: []
}

export default function(state=INITIAL_STATE, actions) {
    switch(actions.type){
        case GET_ALL_BOOKS:
            return { ...state, loadingBooks: true }
        case GET_ALL_BOOKS_SUCCESS:
            return { ...state, books: actions.payload, loadingBooks: false}
        default:
            return state;
    }
}