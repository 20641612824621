import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchInput, {createFilter} from 'react-search-input';
import { Navigate, Link } from 'react-router-dom';
import { getAllUsers, deleteUser } from '../actions';
import Header from './Header';
import { Bars } from  'react-loader-spinner';
import logo from '../assets/logoHeader.png';
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { withRouter } from './withRouter';

const KEYS_TO_FILTERS = ['email'];
const columns = [
    {
        Header: 'ID',
        accessor: 'idUser',
    },
    {
        Header: 'Email',
        accessor: 'email'
    },
    {
        id: 'authorized',
        Header: 'Autorizado',
        Cell: row => (
            <div>
                {self.renderAuthorizedUser(row)}
            </div>
        )
    },
    {
        id:'booksPurchased',
        Header: 'Libros Comprados',
        Cell: row => (
            <div>
                {self.renderBooksCount(row.original)}
            </div>
        )
    },
    {
        id: 'acciones',
        Header: 'Acciones',
        Cell: row => (
            <div className="qrTableBtns">
                <Link
                    to={`/user/${row.original.idUser}`}
                    className="btn btn-primary btn-sm"
                >
                    <FontAwesomeIcon
                        icon='eye'
                        size='1x'
                    />
                </Link>
                <Link
                    to={`/notification/${row.original.idUser}`}
                    className="btn btn-primary btn-sm"
                >
                    <FontAwesomeIcon
                        icon={faBell}
                        size='1x'
                    />
                </Link>
            </div>
        )
    }
];

let self = null;

class Users2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            editing: true,
            filterText: '',
            isAdmin: false,
        };
        self = this;
        this.handleSearchChange = this.handleSearchChange.bind(this);
    }

    componentDidMount() {
        this.props.getAllUsers();
    }

    renderAuthorizedUser = (row) => {
        if(row.original.authorized && !row.original.isAdmin){
            return <span>Autorizado</span>;
        }else if(!row.original.authorized && !row.original.isAdmin){
            return <span>No Autorizado</span>;
        }else if(row.original.isAdmin){
            return <span> - </span>;
        }
    }

    renderBooksCount = (user) => {
        if(user.books && !user.isAdmin){
            return <span>{user.books.length}</span>;
        }else if(user.isAdmin){
            return <span>-</span>;
        }else{
            return <span>0</span>;
        }
    }

    handleDelete = (info) => {
        this.props.deleteUser(info.idUser, () => {
            this.props.getAllUsers();
        });
    }

    renderUsersTable = () => {
        if(this.props.users.length === 0){
            return <Bars color="#17a2b8" height={30} width={30} />;
        }
        const standardUser = _.filter(this.props.users, (user) => {
            return user.isAdmin === this.state.isAdmin;
        });
        const filteredUsers = standardUser.filter(createFilter(this.state.filterText, KEYS_TO_FILTERS));
        return (
            <ReactTable
                data={filteredUsers}
                columns={columns}
                defaultPageSize={15}
                showPageSizeOptions={false}
                className="-striped"
                previousText= 'Anterior'
                nextText= 'Siguiente'
                loadingText= 'Cargando...'
                noDataText= 'No se encontraron filas'
                pageText= 'Pagina'
                ofText= 'de'
                rowsText= 'filas'
            />
        );
    }

    handleSearchChange(term) {
        this.setState({filterText: term});
    }

    renderBtnTxt = () => {
        return this.state.isAdmin ? 'Ver Usuarios' : 'Ver Administradores';
    }

    switchUsers = () => {
        this.setState({
            isAdmin: !this.state.isAdmin
        });
    }

    renderUsersList = () => {
        return (
            <div className="componentContainer">
                <Header />
                <div className="sectionContainer">
                    <div className="headerSection">
                        <div className="headerContent">
                            <div className="headerSectionTitle">Usuarios</div>
                            <img src={logo} alt="Audiolibros" className="logoHeader"/>
                        </div>
                    </div>
                    <div className="usersColumns">
                        <div className="subHeader">
                            <SearchInput
                                placeholder="Buscar"
                                className="search-input appSearchInput"
                                onChange={this.handleSearchChange}
                            />
                            <button
                                className="btn btn-success"
                                onClick={() => this.switchUsers()}
                            >
                                {this.renderBtnTxt()}
                            </button>
                        </div>
                        {this.renderUsersTable()}
                    </div>
                </div>
            </div>
        );
    }
    renderComponent = () => {
        return this.props.auth ? (
            this.renderUsersList()
        ) : (
           <Navigate
                to={{
                    pathname: '/login',
                    state: { from: this.props.location }
                }}
            />
        );
    }

    render() {
        return this.renderComponent();
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth.authenticated,
        users: state.users.users
    };
};

export default connect(mapStateToProps, {getAllUsers, deleteUser})(withRouter(Users2));