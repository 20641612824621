import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faHome,
  faUsers,
  faBook,
  faSignOutAlt,
  faTrashAlt,
  faEdit,
  faQrcode,
  faShoppingCart,
  faEye,
  faEyeSlash,
  faDownload,
  faSitemap,
  faPlus,
  faFile,
  faLayerGroup,
  faMinus,
  faPen
} from '@fortawesome/free-solid-svg-icons';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './Login';
import Home from './Home';
import BooksList from './BooksList';
import NewBook2 from './NewBook2';
import BookEdit from './BookEdit';
import UserEdit from './UserEdit';
import Users2 from './Users2';
import About from './About';
import NewQRCode from './NewQRCode';
import Logout from './Logout';
import Qrcodes from './Qrcodes';
import PointsOfSale from './PointsOfSale';
import NewPOS from './NewPOS';
import Notifications from './Notifications';
import NewNotification from './NewNotification';
import BatchDetail from './BatchDetail';
import Categories from './Categories';
import NewCategory from './NewCategory';
import CategoryEdit from './CategoryEdit';
import CategoriesList from './CategoriesList';
import SaleDetail from './SaleDetail';
import PointsOfSaleEdit from './PointOfSaleEdit';
import ReportSalesByProduct from './ReportSalesByProduct';
import ReportSalesByPosProduct from './ReporSalesByPosProduct';
import ReportSalesByDate from './ReportSalesByDate';
import ReportSalesByPeriod from './ReportSalesByPeriod';
import ReportSalesByPeriodProduct from './ReportSalesByPeriodProduct';
import Combos from './Combos';
import Combo from './Combo';
import NewCombo from './NewCombo';
import './App.css';

library.add(
  faHome,
  faUsers,
  faBook,
  faSignOutAlt,
  faTrashAlt,
  faEdit,
  faQrcode,
  faShoppingCart,
  faEye,
  faEyeSlash,
  faDownload,
  faSitemap,
  faPlus,
  faFile,
  faLayerGroup,
  faMinus,
  faPen
);

class App extends Component {
  render() {
    return (
      <BrowserRouter>
      {/* <Route path="/" element={<Home />}></Route> */}

      <Routes>
        {/* <div className="wrapper"> */}
          {/* <Route exact path="/" element={Home} /> */}
          <Route path="/" element={<Home />}></Route>
          <Route exact path="/login" element={<Login />}></Route>
          <Route exact path="/users" element={<Users2 />} />
          <Route exact path="/user/:id" element={<UserEdit />} />
          <Route exact path="/books" element={<BooksList />} />
          <Route exact path="/newbook" element={<NewBook2 />} />
          <Route exact path="/book/:id" element={<BookEdit />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/qrcodes" element={<Qrcodes />} />
          <Route exact path="/newqrcode" element={<NewQRCode />} />
          <Route exact path="/pos" element={<PointsOfSale />} />
          <Route exact path="/posedit/:id" element={<PointsOfSaleEdit />} />
          <Route exact path="/newpos" element={<NewPOS />} />

          <Route exact path="/notifications" element={<Notifications />} />
          <Route exact path="/newNotification" element={<NewNotification />} />
          <Route exact path="/notification/:idUser" element={<NewNotification />} />

          <Route exact path="/batch/:id" element={<BatchDetail />} />
          <Route exact path="/category/:id" element={<CategoryEdit />} />
          <Route exact path="/categories" element={<Categories />} />
          <Route exact path="/categorieslist" element={<CategoriesList />} />
          <Route exact path="/newcategory" element={<NewCategory />} />
          <Route exact path="/saledetail/:id" element={<SaleDetail />} />
          <Route
            exact
            path="/salesbyproduct"
            element={<ReportSalesByProduct />}
          />
          <Route
            exact
            path="/salesbyposproduct"
            element={<ReportSalesByPosProduct />}
          />
          <Route exact path="/salesbydate" element={<ReportSalesByDate />} />
          <Route exact path="/salesbyperiod" element={<ReportSalesByPeriod />} />
          <Route
            exact
            path="/salesbyperiodproduct"
            element={<ReportSalesByPeriodProduct />}
          />
          <Route excat path="/combos" element={<Combos />} />
          <Route excat path="/combo/:id" element={<Combo />} />
          <Route excat path="/newcombo" element={<NewCombo />} />
          <Route exact path="/logout" element={<Logout />} />
        {/* </div> */}
      {/* </Router> */}
      </Routes>
      </BrowserRouter>

    );
  }
}

export default App;
