import {
    DELETE_BOOK_OK,
    DELETE_BOOK_ERROR,
} from '../actions/types';

const INITIAL_STATE = {
    deletedMsg: ''
}

export default function(state=INITIAL_STATE, action) {
    switch (action.type) {
        case DELETE_BOOK_OK:
            return { ...state, deletedMsg: 'Documento borrado' };
        case DELETE_BOOK_ERROR:
            return { ...state, deletedMsg: 'Error al borrar el documento' };
        default:
            return state;
    }
}