import React, {Component} from 'react';
import { Navigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import _ from 'lodash';
import Header from './Header';
import { Bars } from  'react-loader-spinner'

import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import { getBatches, getAllBooks, getAllPos, getQrByBatch, deleteBatch, getAllQrsByBatch } from '../actions';
import logo from '../assets/logoHeader.png';
import { withRouter } from './withRouter';

const columns = [
    {
        Header: 'ID Lote',
        accessor: 'batchId',
    },
    {
        Header: 'Unidades por lote',
        accessor: 'amount',
    },
    {
        id: 'bookTitle',
        Header: 'Audiolibro',
        Cell: row => {
            let dato =_.filter(self.props.books, (book) => {
                return book.idBook === row.original.idBook;
            });
            if(dato.length !== 0){
                return dato[0].books.title;
            }

        },
        filterable: true
    },
    {
        id: 'posName',
        Header: 'Punto de Venta',
        Cell: row => {
            let dato =_.filter(self.props.posData, (pos) => {
                return pos.idPOS === row.original.idPos;
            });
            if(dato.length !== 0){
                return dato[0].pos.name;
            }

        },
        filterable: true,
    },
    {
        id: 'date',
        Header: 'Fecha',
        Cell: row => {
            let curdate = new Date(null);
            curdate.setTime((row.original.date.seconds)*1000);
            return (
                curdate.toLocaleDateString()
            );
        },
        filterable: true,
    },
    {
        id: 'acciones',
        Header: 'Acciones',
        Cell: row => (
            <div className="qrTableBtns">
                <Link
                    to={`/batch/${row.original.batchId}`}
                    className="btn btn-primary btn-sm"
                >
                    <FontAwesomeIcon
                        icon='eye'
                        size='1x'
                    />
                </Link>
                <button
                    className="btn btn-danger btn-sm"
                    onClick={() => self.handleDeleteClick(row.original)}
                >
                    <FontAwesomeIcon
                        icon='trash-alt'
                        size='1x'
                    />
                </button>
            </div>
        )
    }
];

let self = null;

class Qrcodes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            book: {},
            filterText: '',
            filtrados: null
        };
        this.handleSearchChange = this.handleSearchChange.bind(this);
        self = this;
    }

    componentDidMount = () => {
        this.props.getAllQrsByBatch();
        this.props.getBatches();
        this.props.getAllBooks();
        this.props.getAllPos();
    }

    handleDeleteClick = (row) => {
        this.props.getQrByBatch(row.batchId, () => {
            self.props.deleteBatch(row.batchId, self.props.qrs, function(){
                self.props.getBatches();
                self.props.getAllBooks();
                self.props.getAllPos();
            });
        });
    }

    handleNewQRClick = () => {
        return () => {
            this.props.navigate('/newqrcode');
        };
    }

    renderStage = () => {
        if(this.props.loadingBatches){
            return <Bars color="#17a2b8" height={30} width={30} />;
        }
        return this.renderList();
    }

    handleSearchChange(term) {
        this.setState({filterText: term});
    }

    filterData = (e) => {
        if(e[0] !== undefined){
            switch(e[0].id){
                case 'bookTitle':
                    return self.filterByBook(e[0].value);
                case 'posName':
                    return self.filterByPos(e[0].value);
                case 'date':
                    return self.filterByDate(e[0].value);
                default:
                    return self.props.batches;
            }
        }else if(e.length === 0){
            self.setState({
                filtrados: null
            });
        }

    }

    filterByBook = (value) => {
        let result = [];
        let books = _.filter(self.props.books, (book) => {
            return book.books.title.toLowerCase().includes(value.toLowerCase());
        }).map((item)=>{
            return item.idBook;
        });


        _.forEach(self.props.batches, function (n, key) {
            _.forEach(books, function (n2, key2) {
               if (n.idBook.toString() === n2.toString()) {
                  result.push(n);
               }
            });
        });

        self.setState({
            filtrados: result
        });
    }

    filterByPos = (value) => {
        let result = [];
        let poss = _.filter(self.props.posData, (pos) => {
            return pos.pos.name.toLowerCase().includes(value.toLowerCase());
        }).map((item)=>{
            return item.idPOS;
        });

        _.forEach(self.props.batches, function (n) {
            _.forEach(poss, function (n2) {
               if (n.idPos.toString() === n2.toString()) {
                  result.push(n);
               }
            });
        });

        self.setState({
            filtrados: result
        });
    }

    filterByDate = (value) => {
        let result = [];
        _.forEach(self.props.batches, function (n, key) {
            let curdate = new Date(null);
            curdate.setTime((n.date.seconds)*1000);
            let batchDate = curdate.toLocaleDateString();

            if (batchDate.toString().includes(value.toString())) {
               result.push(n);
            }
         });

         self.setState({
            filtrados: result
        });
    }

    renderList = () => {
        let filteredBatches;
        if(this.state.filtrados !== null){
            filteredBatches = this.state.filtrados;
        }else{
            filteredBatches = self.props.batches;
        }
        return (
            <ReactTable
                data={filteredBatches}
                columns={columns}
                defaultPageSize={15}
                showPageSizeOptions={false}
                sortable={false}
                className="-striped"
                previousText= 'Anterior'
                nextText= 'Siguiente'
                loadingText= 'Cargando...'
                noDataText= 'No se encontraron filas'
                pageText= 'Pagina'
                ofText= 'de'
                rowsText= 'filas'
                onFilteredChange={(e) => {
                    this.filterData(e);
                }}
            />
        );
    }

    renderQrcodesList = () => {
        return (
            <div className="componentContainer">
                <Header />
                <div className="sectionContainer">
                    <div className="headerSection">
                        <div className="headerContent">
                            <div className="headerSectionTitle">
                                <span>Códigos QR</span>
                            </div>
                            <img src={logo} alt="Audiolibros" className="logoHeader"/>
                        </div>
                    </div>
                    <div className="usersColumns">
                        <div className="qrCodesListTopBar">
                            <button
                                className="btn btn-sm btn-success"
                                onClick={this.handleNewQRClick()}
                            >
                                Nuevo Lote de Códigos QR
                            </button>
                        </div>
                        {this.renderStage()}
                    </div>
                </div>
            </div>
        );
    }

    renderComponent = () => {
        return this.props.auth ? (
            this.renderQrcodesList()
        ) : (
           <Navigate
                to={{
                    pathname: '/login',
                    state: { from: this.props.location }
                }}
            />
        );
    }

    render() {
        return this.renderComponent();
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth.authenticated,
        batches: state.batches.batches,
        books: state.books.books,
        posData: state.posData.posData,
        loadingBatches: state.batches.loadingBatches,
        qrs: state.batches.qrsByBatch,
    };
};

export default connect(
    mapStateToProps,
    {
        getBatches,
        getAllBooks,
        getAllPos,
        getQrByBatch,
        deleteBatch,
        getAllQrsByBatch
    }
)(withRouter(Qrcodes));