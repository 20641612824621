import { combineReducers } from 'redux';
import auth from './LoginReducer';
import users from './UsersReducer';
import notifications from './NotificationsReducer';
import user from './UserReducer';
import userCreation from './NewUserReducer';
import books from './BooksReducer';
import book from './GetBookReducer';
import bookUpdate from './BookUpdateReducer';
import bookCreated from './NewBookReducer';
import uploadProgress from './UploadProgressReducer';
import deleteMsg from './DeleteBookReducer';
import clearState from './ClearState';
import imageUpload from './UploadImageReducer';
import audioUpload from './UploadAudioReducer';
import qrcodes from './QRCodesReducer';
import posData from './PosReducer';
import batches from './BatchesReducer';
import categories from './CategoriesReducer';
import qrsByBatch from './QrsByBatchReducer';
import sales from './SalesReducer';
import pin from './GetPinReducer';
import combo from './CombosReducer';

const rootReducer = combineReducers({
  auth,
  notifications,
  users,
  user,
  userCreation,
  books,
  book,
  bookUpdate,
  bookCreated,
  uploadProgress,
  deleteMsg,
  clearState,
  imageUpload,
  audioUpload,
  qrcodes,
  posData,
  batches,
  categories,
  qrsByBatch,
  sales,
  pin,
  combo
});

export default rootReducer;
