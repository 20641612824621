import {
  IMAGE_WIDE_UPLOADING,
  IMAGE_UPLOADING,
  IMAGE_UPLOADED_OK,
  IMAGE_UPLOADED_ERROR
} from '../actions/types';

const INITIAL_STATE = {
  imageUrl: '',
  uploadError: '',
  uploadingImg: false,
  uploadingWideImg: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case IMAGE_UPLOADING:
      return { ...state, imageUrl: '', uploadError: '', uploadingImg: true };
    case IMAGE_WIDE_UPLOADING:
      return {
        ...state,
        imageUrl: '',
        uploadError: '',
        uploadingWideImg: true
      };
    case IMAGE_UPLOADED_OK:
      return {
        ...state,
        imageUrl: action.payload,
        uploadingImg: false,
        uploadingWideImg: false
      };
    case IMAGE_UPLOADED_ERROR:
      return {
        ...state,
        uploadError: 'error cargando la imagen al servidor',
        uploadingImg: false,
        uploadingWideImg: false
      };
    default:
      return state;
  }
}
