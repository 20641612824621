export const LOGIN_LOADING = 'login_loading';
export const LOGIN_OK = 'login_ok';
export const LOGIN_ERROR = 'login_error';
export const LOG_OUT_OK = 'log_out_ok';

export const GET_ALL_NOTIFICATIONS = 'get_all_notifications';
export const GET_ALL_NOTIFICATIONS_SUCCESS = 'get_all_notifications_success';
export const GET_ALL_NOTIFICATIONS_ERROR = 'get_all_notifications_error';
export const NEW_NOTIFICATION = 'new_notifications';
export const NEW_NOTIFICATION_SUCCESS = 'new_notifications_success';
export const NEW_NOTIFICATION_ERROR = 'new_notifications_error';

export const GET_ALL_USERS = 'get_all_users';
export const GET_USER = 'get_user';
export const NEW_USER_CREATION_PROCCESS = 'new_user_creation_proccess';
export const CLEAR_NEW_USER_STATE = 'clear_new_user_state';
export const NEW_USER_OK = 'new-user_ok';
export const NEW_USER_ERROR = 'new-user_error';
export const UPDATE_USER = 'update_user';
export const UPDATE_USER_SUCCESS = 'update_user_success';
export const UPDATE_USER_ERROR = 'update_user_error';
export const DELETE_USER = 'delete_user';
export const DELETE_USER_SUCCESS = 'delete_user_success';
export const DELETE_USER_ERROR = 'delete_user_error';

export const GET_BOOK_OK = 'get_book_ok';
export const GET_ALL_BOOKS = 'get_all_books';
export const GET_ALL_BOOKS_SUCCESS = 'get_all_books_success';
export const BOOK_UPDATED_OK = 'book_updated_ok';
export const BOOK_UPDATED_ERROR = 'book_updated_error';
export const NEW_BOOK_CREATED_OK = 'new_book_created_ok';
export const NEW_BOOK_CREATED_ERROR = 'new_book_created_error';
export const IMAGE_UPLOAD_PROGRESS = 'image_upload_progress';
export const DELETE_BOOK_OK = 'delete_book_ok';
export const DELETE_BOOK_ERROR = 'delete_book_error';

export const IMAGE_UPLOADED_OK = 'image_uploaded_ok';
export const IMAGE_UPLOADED_ERROR = 'image_uploaded_error';
export const IMAGE_UPLOADING = 'image_uploading';
export const IMAGE_WIDE_UPLOADING = 'image_wide_uploading';

export const AUDIO_SAMPLE_UPLOADED_OK = 'audio_sample_uploaded_ok';
export const AUDIO_COMPLETE_UPLOADED_OK = 'audio_complete_uploaded_ok';
export const AUDIO_UPLOADED_ERROR = 'audio_uploaded_error';
export const AUDIO_SAMPLE_UPLOADING = 'audio_sample_uploading';
export const AUDIO_COMPLETE_UPLOADING = 'audio_complete_uploading';
export const AUDIO_SAMPLE_UPLOAD_PROGRESS = 'audio_sample_upload_progress';
export const AUDIO_COMPLETE_UPLOAD_PROGRESS = 'audio_complete_upload_progress';

export const GET_ALL_QRCODES = 'get_all_qrcodes';
export const GET_ALL_QRCODES_SUCCESS = 'get_all_qrcodes_success';
export const GET_ALL_QRCODES_ERROR = 'get_all_qrcodes_error';

export const NEW_POS_CREATED_OK = 'new_pos_created_ok';
export const NEW_POS_CREATED_ERROR = 'new_pos_created_error';
export const NEW_POS = 'new_pos';

export const GET_ALL_POS = 'get_all_pos';
export const GET_ALL_POS_SUCCESS = 'get_all_pos success';
export const GET_ALL_POS_ERROR = 'get_all_pos_error';
export const GET_POS_OK = 'get_pos_ok';
export const DELETE_POINT_OF_SALE = 'delete_point_of_sale';
export const DELETE_POINT_OF_SALE_ERROR = 'delete_point_of_sale_error';

export const NEW_BATCH = 'new_batch';
export const NEW_BATCH_SUCCESS = 'new_batch_success';
export const NEW_BATCH_ERROR = 'new_batch_error';

export const GET_BATCHES = 'get_batches';
export const GET_BATCHES_SUCCESS = 'get_batches_success';
export const GET_BATCHES_ERROR = 'get_batches_error';

export const GET_QR_BY_BATCH = 'get_qr_by_batch';
export const GET_QR_BY_BATCH_SUCCESS = 'get_qr_by_batch_success';
export const GET_QR_BY_BATCH_ERROR = 'get_qr_by_batch_error';

export const GET_BATCH = 'get_batch';
export const GET_BATCH_SUCCESS = 'get_batch_success';
export const GET_BATCH_ERROR = 'get_batch_error';

export const UPDATE_QR_STATUS = 'set_qr_status';
export const UPDATE_QR = 'update_qr';

export const DELETE_QR_BY_BATCH = 'delete_qr_by_batch';
export const DELETE_QR_BY_BATCH_SUCCESS = 'delete_qr_by_batch_success';

export const DELETE_BATCH = 'delete_batch';
export const DELETE_BATCH_SUCCESS = 'delete_batch_success';

export const GET_CATEGORIES_LIST = 'get_categories_list';
export const SAVE_CAT_ORDER = 'save_cat_order';
export const SAVE_CAT_ORDER_SUCCESS = 'save_cat_order_success';
export const SAVE_CAT_ORDER_ERROR = 'save_cat_order_error';
export const NEW_CAT = 'new_cat';
export const NEW_CAT_SUCCESS = 'new_cat_success';
export const NEW_CAT_ERROR = 'new_cat_error';
export const GET_CATEGORIES = 'get_categories';
export const GET_CATEGORIES_SUCCESS = 'get_categories_success';
export const GET_CATEGORIES_ERROR = 'get_categories_error';
export const DELETE_CAT = 'delete_cat';
export const DELETE_CAT_SUCCESS = 'delete_cat_success';
export const DELETE_CAT_ERROR = 'delete_cat_error';
export const UPDATE_CAT = 'update_cat';
export const UPDATE_CAT_SUCCESS = 'update_cat_success';
export const UPDATE_CAT_ERROR = 'update_cat_error';
export const GET_CATEGORY = 'get_category';
export const GET_CATEGORY_SUCCESS = 'get_category_success';
export const GET_CATEGORY_ERROR = 'get_category_error';

export const GET_ALL_QRS = 'get_all_qrs';

export const GET_ALL_QRS_BY_BATCH = 'get_all_qrs_by_batch';

export const GET_ALL_SALES = 'get_all_sales';
export const GET_SALE = 'get_sale';
export const GET_SALE_SUCCESS = 'get_sale_success';

export const GET_SALE_QR = 'get_sale_qr';

export const GET_PIN = 'get_pin';

export const UPDATING_POS = 'updating_pos';
export const POS_UPDATED = 'pos_updated';

export const NEW_COMBO_CREATED_OK = 'new_combo_created_ok';
export const GET_ALL_COMBOS = 'get_all_combos';
export const GET_ALL_COMBOS_SUCCESS = 'get_all-combos_success';
export const DELETE_COMBO_OK = 'delete_combo_ok';
export const DELETE_COMBO_ERROR = 'delete_combo_error';
export const GET_COMBO_OK = 'get_combo_ok';
export const COMBO_UPDATE_OK = 'combo_update_ok';
