import React, {Component} from 'react';
import $ from 'jquery';
import html2canvas from 'html2canvas';
import { Navigate } from 'react-router-dom';
//import QRCode from 'qrcode.react';
import { QRCode } from 'react-qr-svg';
import { withRouter } from './withRouter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import 'react-responsive-modal/styles.css';
import Modal from 'react-responsive-modal';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import {
    getQrByBatch,
    getBatch,
    getBook,
    handleQRStatus,
    getPos,
    deleteQr,
    getAllQRCodes
} from '../actions';
import Header from './Header';
import { Bars, Audio } from  'react-loader-spinner'
import logo from '../assets/logoHeader.png';


let self = null;
const columns = [
    {
        id: 'qr',
        Header: 'QR',
        Cell: row => {
            return self.renderQR(row.original.idQr);
        }
    },
    {
        id: 'qrStatus',
        Header: 'Estado',
        Cell: row => (
            <div className="insideRow">
                {row.original.enabled ? 'ACTIVO' : 'INACTIVO'}
            </div>
        )
    },
    {
        id: 'pin',
        Header: 'PIN',
        Cell: row => {
            let info = self.renderPIN(row.original.idQr);
            if(info.length > 0){
                return info[0].pin.pin;
            }else{
                return 'no pin';
            }
        }
    },
    {
        id: 'used',
        Header: 'Usado',
        Cell: row => (
            <div className="insideRow">
                {row.original.used ? 'SI' : 'NO'}
            </div>
        )
    },
    {
        id: 'actions',
        Header: 'Acciones',
        Cell: row => (
            <div className="tableBatchBtns">
                {self.renderActivateBtn(row)}
                {self.renderDeleteBtn(row)}
                {self.renderDownloadBtn(row)}
            </div>
        )
    }
];
class BatchDetail extends Component {
    constructor(props){
        super(props);
        this.state = {
            batch: [],
            showData: false,
            updattingQRid: null,
            delettingQRid: null,
            open: false,
            QRid: '',
            url: '',
            masive: false
        };
        self = this;
    }

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false, url: '' });
    };

    componentDidMount = () => {
        this.getQrByBatch();
        this.getBatch();
        this.props.getAllQRCodes();
    }

    downloadQR = (row) => {
        this.setState({
            QRid: row.idQr
        }, () => {
            self.onOpenModal();
        });
    }

    renderPIN = (id) => {
        return this.props.qrcodes.filter((qr) => {
            return qr.idQR === id;
        });
    }

    renderDownloadBtn = (row) => {
        return (
            <button
                onClick={() => self.downloadQR(row.original)}
                className='btn btn-success btn-sm'
            >
                <FontAwesomeIcon
                    icon='download'
                    size='1x'
                />
            </button>
        );
    }

    renderActivateBtn = (row) => {
        if(this.props.updatting && (this.state.updattingQRid === row.original.idQr)){
            return (
                <div className="loaderUpdateHolder">
                    <Audio
                        color="#002c33"
                        height={20}
                        width={20}
                    />
                </div>
            );
        }
        return (
            <button
                onClick={() => self.handleQRStatus(row.original)}
                className={self.handleBtnStyle(row.original)}
            >
                <FontAwesomeIcon
                    icon={self.handleBtnIcon(row.original)}
                    size='1x'
                />
            </button>
        );
    }

    renderDeleteBtn = (row) => {
        if(this.props.deletting && (this.state.delettingQRid === row.original.idQr)){
            return (
                <div className="loaderUpdateHolder">
                    <Audio
                        color="#002c33"
                        height={20}
                        width={20}
                    />
                </div>
            );
        }
        return (
            <button
                className="btn btn-danger btn-sm"
                onClick={() => self.handleDeleteClick(row.original)}
            >
                <FontAwesomeIcon
                    icon='trash-alt'
                    size='1x'
                />
            </button>
        );
    }

    handleStatusText = (row) => {
        return row.enabled ? 'Desactivar' : 'Activar';
    }

    handleBtnStyle = (row) => {
        return row.enabled ? 'btn btn-info btn-sm' : 'btn btn-success btn-sm';
    }

    handleBtnIcon = (row) => {
        return row.enabled ? 'eye-slash' : 'eye';
    }

    getQrByBatch = () => {
        const { params } = this.props;
        this.props.getQrByBatch(params.id, () =>{

        });
    }

    getBatch = () => {
        const { params } = this.props;
        this.props.getBatch(params.id, () => {
            self.setState({
                batch: self.props.batch
            }, function () {
                self.props.getBook(self.state.batch.idBook, function(){});
                self.props.getPos(self.state.batch.idPos, function(){
                    self.setState({showData: true});
                });
            });
        });
    }


    handleQRStatus = (row) => {
        this.setState({
            updattingQRid: row.idQr
        }, () => {
            self.props.handleQRStatus(row.idQr, !row.enabled, () => {
                self.getQrByBatch();
            });
        });
    }

    handleDeleteClick = (row) => {
        this.setState({
            delettingQRid: row.idQr
        }, () => {
            let amount = self.props.batch.amount - 1;
            self.props.deleteQr(row.idBatch, row.idQr, amount, function(){
                self.getQrByBatch();
                self.getBatch();
            });
        });

    }

    renderQR = (id) => {
        const obj = {
            idQR: id
        };
        return (
            <div>
                <QRCode
                    bgColor="#FFFFFF"
                    fgColor="#000000"
                    level="Q"
                    style={{ width: 50 }}
                    value={JSON.stringify(obj)}
                />
            </div>
        );
    }

    renderTable = () => {
        return (
            <ReactTable
                data={this.props.qrs}
                columns={columns}
                defaultPageSize={15}
                showPageSizeOptions={false}
                sortable={false}
                className="-striped"
                previousText= 'Anterior'
                nextText= 'Siguiente'
                loadingText= 'Cargando...'
                noDataText= 'No se encontraron filas'
                pageText= 'Pagina'
                ofText= 'de'
                rowsText= 'filas'
            />
        );
    }


    renderDownloableQR = () => {
        const obj = {
            idQR: this.state.QRid
        };
        return (
            <>
                <QRCode
                    bgColor="#FFFFFF"
                    fgColor="#000000"
                    level="Q"
                    style={{ width: 128 }}
                    value={JSON.stringify(obj)}
                />
            </>
        );
    }

    getCanvasData = () => {
        html2canvas($('#masiveDownloadQrsHolder')[0],{
            windowWidth: 100,
            windowHeight: 100,
        }).then((canvas) => {
          console.log(canvas.toDataURL('image/png'))
            this.setState({url: canvas.toDataURL('image/png')});
        });
    }

    showQrsToDownload = () => {
        this.setState({
            open: true
        });
    }

    handleDownloadQrs = () => {
        var _self = this;
        return this.props.qrs.map(qr => {
            const obj = {
                idQR: qr.idQr
            };
            const info = _self.renderPIN(qr.idQr);
            return (
                <div style={{ margin: '20px', textAlign:'center' }} key={qr.idQr}>
                    <QRCode
                        bgColor="#FFFFFF"
                        fgColor="#000000"
                        level="Q"
                        style={{ width: 100 }}
                        value={JSON.stringify(obj)}
                    />
                    { info && info.length > 0 && info[0].pin && <div style={{fontSize:"13px"}}>PIN: { info[0].pin.pin }</div> }
                </div>
            );
        });
    }

    renderDownBtnGenerated = () => {
        if(this.state.url !== ''){
            return (
                <div className="holderBtnsDownload">
                    <a
                        href={this.state.url}
                        download={`${self.state.QRid}.png`}
                        className="btn btn-info btn-sm"
                        onClick={() => self.onCloseModal()}
                    >
                        Guardar
                    </a>
                    <button
                        className="btn btn-danger btn-sm"
                        onClick={() => self.onCloseModal()}
                    >
                        Cancelar
                    </button>
                </div>
            );
        }
        return (
            <Audio color="#17a2b8" height={20} width={20} />
        );
    }

    getPIN = () => {
        return this.props.qrcodes.filter((qr) => {
            return qr.idQR === this.state.QRid;
        });
    }

    renderDownloablePIN = () => {
        let info = this.getPIN();
        if(info.length > 0){
        return <span className="pin">{info[0].pin.pin}</span>;
        }else{
            return 'no pin';
        }
    }

    renderBatchDetail = () => {
        const { open } = this.state;
        return (
<>
            <div className="componentContainer">
                <Modal
                    open={open}
                    onClose={self.onCloseModal}
                    showCloseIcon={false}
                    onAnimationEnd={(e) => {self.getCanvasData() }}
                >
                    {self.renderMasiveQrsDownload()}
                </Modal>
                <Header />
                <div className="sectionContainer">
                    <div className="headerSection">
                        <div className="headerContent">
                            <div className="headerSectionTitle">Detalle de Lote</div>
                        </div>
                        <img src={logo} alt="Audiolibros" className="logoHeader"/>
                    </div>
                    <div className="usersColumns">
                        {self.renderBatchData()}
                        {self.renderTable()}
                    </div>
                </div>
            </div>
            </>
        );

    }

    renderMasiveQrsDownload = () => {
        return (
            <div className="masiveDownload">
                <div id="masiveDownloadQrsHolder" className="masiveDownloadQrs">
                    {this.handleDownloadQrs()}
                </div>
                <div className="masiveDownloadBtns">
                    {this.renderDownBtnGenerated()}
                </div>
            </div>
        );
    }

    renderBatchData = () => {
        if(this.state.showData){
            return (
                <div className="batchDetailHeader">
                    <div className="batchDetailSubHeader">
                        <div className="batchDetailGroup">
                            <span>Punto de Venta: <strong>{this.props.pos.name}</strong></span>
                        </div>
                        <div className="batchDetailGroup">
                            <span>Audiolibro: <strong>{this.props.book.title}</strong></span>
                        </div>
                        <div className="batchDetailGroup">
                            <span>Unidades: <strong>{this.props.batch.amount}</strong></span>
                        </div>
                    </div>
                    <div>
                        <button
                            className="btn btn-primary"
                            // onClick={this.showQrsToDownload}
                            onClick={() => self.showQrsToDownload()}
                        >
                            Descargar QRs
                        </button>
                    </div>
                </div>
            );
        }
        return (
            <div className="mainLoaderHolder">
                <Bars color="#17a2b8" height={30} width={30} />
            </div>
        );
    }

    renderComponent = () => {
        return this.props.auth ? (
            this.renderBatchDetail()
        ) : (
            <Navigate
                to={{
                    pathname: '/login',
                    state: { from: this.props.location }
                }}
            />
        );
    }

    render() {
        return this.renderComponent();
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth.authenticated,
        qrs: state.batches.qrsByBatch,
        batch: state.batches.batch,
        book: state.book.book,
        pos: state.posData.uniquePos,
        updatting: state.qrcodes.updattingQr,
        deletting: state.batches.deletingQrbyBatch,
        qrcodes: state.qrcodes.qrcodes,
    };
};

export default connect(
    mapStateToProps,
    {
        getQrByBatch,
        getBatch,
        getBook,
        getPos,
        handleQRStatus,
        deleteQr,
        getAllQRCodes
    }
)(withRouter(BatchDetail));