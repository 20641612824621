import React, { Component } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { Bars } from  'react-loader-spinner'
import Select from 'react-select';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

let self = null;

const columns = [
  {
    id: 'date',
    Header: 'Fecha',
    Cell: row => {
      let curdate = new Date(null);
      curdate.setTime(row.original.data.date.seconds * 1000);
      let finalDate = curdate.toLocaleDateString();
      return finalDate;
    }
  },
  {
    id: 'amount',
    Header: 'Total',
    Cell: row => {
      return self.amount;
    }
  }
];

class Sales extends Component {
  constructor(props) {
    super(props);
    self = this;

    this.state = {
      bookFilter: '',
      pointOfSaleFilter: '',
      startDate: new Date(),
      endDate: '',
      amount: 0
    };
  }

  renderSelectProduct = () => {
    if (this.props.books.length > 0) {
      let optionsBook = [
        {
          value: '',
          label: 'Todos'
        }
      ];
      this.props.books.forEach(function(book) {
        optionsBook.push({
          value: book.idBook,
          label: book.books.title
        });
      });
      let selectedOpt = optionsBook.findIndex(
        x => x.value === self.state.bookFilter
      );
      return (
        <Select
          value={optionsBook[selectedOpt]}
          options={optionsBook}
          onChange={this.handleBookChange}
        />
      );
    }
    return <Bars color="#17a2b8" height={30} width={30} />;
  };

  handleBookChange = obj => {
    this.setState({
      bookFilter: obj.value,
      pointOfSaleFilter: '',
      startDate: '',
      endDate: ''
    });
  };

  renderSelectPos = () => {
    if (this.props.pointOfSales.length > 0) {
      let optionsPos = [
        {
          value: '',
          label: 'Todos'
        },
        {
          value: 'app',
          label: 'App'
        }
      ];
      this.props.pointOfSales.forEach(function(pos) {
        optionsPos.push({
          value: pos.idPOS,
          label: pos.pos.name
        });
      });
      let selectedOpt = optionsPos.findIndex(
        x => x.value === self.state.pointOfSaleFilter
      );
      return (
        <Select
          value={optionsPos[selectedOpt]}
          options={optionsPos}
          onChange={this.handlePosChange}
        />
      );
    }
    return <Bars color="#17a2b8" height={30} width={30} />;
  };

  handlePosChange = obj => {
    this.setState({
      bookFilter: '',
      pointOfSaleFilter: obj.value,
      startDate: '',
      endDate: ''
    });
  };

  renderStatusTable = () => {
    let filteredData = null;

    if (this.state.startDate !== '') {
      const selectedTime = this.state.startDate.getTime();
      let filtered = this.props.sales.filter(sale => {
        return sale.data.date.seconds * 1000 > selectedTime;
      });
      filteredData = filtered;
      this.amount = filtered.length;
    }

    let result = filteredData === null ? [{}] : filteredData[0];

    let myArr = [];
    // filteredData.forEach(r => {
    //   myArr.push(r);
    // });

    if (result !== undefined) {
      myArr.push(result);
    }
    console.log('filteredData', filteredData)
    console.log('result', result)
    console.log('myArr', myArr)

    return (
      <ReactTable
        data={myArr}
        columns={columns}
        defaultPageSize={15}
        showPageSizeOptions={false}
        className="-striped"
        previousText="Anterior"
        nextText="Siguiente"
        loadingText="Cargando..."
        noDataText="No se encontraron filas"
        pageText="Pagina"
        ofText="de"
        rowsText="filas"
      />
    );
  };

  clearFilters = () => {
    this.setState({
      bookFilter: '',
      pointOfSaleFilter: '',
      startDate: new Date(),
      endDate: ''
    });
  };

  handleApplyDatePicker = picker => {
    this.setState({
      startDate: picker.startDate._d
    });
  };

  renderDateFilter = () => {
    return (
      <div className="datePickerContainer">
        <DateRangePicker
          singleDatePicker={true}
          showDropdowns
          onApply={(ev, picker) => this.handleApplyDatePicker(picker)}
          locale={{
            cancelLabel: 'Cancelar',
            applyLabel: 'Aceptar',
            daysOfWeek: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
            monthNames: [
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre'
            ]
          }}
        >
          <button className="btn btn-primary">Seleccionar fecha</button>
        </DateRangePicker>
      </div>
    );
  };

  render() {
    return (
      <div className="mainHomeContainer">
        <div className="filterBoxContainer">
          <div className="formGroup dateFilterHolder">
            <span className="formLabel">Filtrar por fecha</span>
            {this.renderDateFilter()}
          </div>
          <div className="formGroup datesHolder">
            <span>
              {this.state.startDate === ''
                ? ''
                : `${this.state.startDate.toLocaleDateString()}`}
            </span>
          </div>
          <div className="formGroup">
            <br />
            <button className="btn btn-info" onClick={this.clearFilters}>
              Limpiar
            </button>
          </div>
        </div>
        <div className="homeTableHolder">{this.renderStatusTable()}</div>
      </div>
    );
  }
}

export default Sales;
