import {GET_PIN} from '../actions/types';

const INITIAL_STATE = {
    pin: []
};

export default function(state=INITIAL_STATE, action) {
    switch(action.type){
        case GET_PIN:
            return { ...state, pin: action.payload};
        default:
            return state;
    }
}