import { GET_BOOK_OK } from '../actions/types';

const INITIAL_STATE = {
    book: []
};

export default function(state=INITIAL_STATE, action){
    
    switch(action.type){
        case GET_BOOK_OK:
            return { ...state, book: action.payload };
        default:
            return state;
    }
}