import {
    NEW_BOOK_CREATED_OK,
    NEW_BOOK_CREATED_ERROR
} from '../actions/types';

const INITIAL_STATE = {
    newBookMsg: ''
}

export default function(state=INITIAL_STATE, action) {
    switch(action.type) {
        case NEW_BOOK_CREATED_OK:
            return { ...state, newBookMsg: 'Nuevo libro creado con exito' };
        case NEW_BOOK_CREATED_ERROR:
            return { ...state, newBookMsg: 'Error al crear el audiolibro' };
        default:
            return state;
    }
}