import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Bars } from  'react-loader-spinner';
import { newNotification, getUser, imgUpload } from '../actions';
import Switch from 'react-switch';
import Header from './Header';
import logo from '../assets/logoHeader.png';
import { withRouter } from './withRouter';
import { FormGroup, Input } from 'reactstrap';
import { Progress } from 'react-sweet-progress';

let self = null;
export class NewNotification extends Component {
    constructor(props) {
        super(props);

        this.state = {
            title: '',
            description: '',
            gift: false,
            image:null,
            idUser: null,
            user:null
        };

        self = this;
    }


    componentDidMount = () => {
      const { params } = this.props;
      if (params && params.idUser) {
        this.setState({idUser: params.idUser});
        this.props.getUser(params.idUser, () => {
          this.setState({user: self.props.user});
        });
      }

  }


  btnStatusControl = () => {
    if (
      this.state.title === '' ||
      this.props.imageUploading
    ) {
      return true;
    } else {
      return false;
    }
  };


    handleImageChange = e => {
      this.props.imgUpload(
        e.target.files[0],
        this.state.title,
        '/notifications/',
        '/',
        (x) => {
          this.setState({
            image: this.props.imageURL
          });
        }
      );
    };

    renderImgProgressBar = () => {
      if (this.props.imageUploading) {
        return (
          <Progress
            percent={this.props.imgProgress}
            theme={{
              error: {
                symbol: this.props.imgProgress + '%',
                trailColor: 'pink',
                color: 'red'
              },
              default: {
                symbol: this.props.imgProgress + '%',
                trailColor: 'lightblue',
                color: 'blue'
              },
              active: {
                symbol: this.props.imgProgress + '%',
                trailColor: 'yellow',
                color: 'orange'
              },
              success: {
                symbol: this.props.imgProgress + '%',
                trailColor: 'lime',
                color: 'green'
              }
            }}
          />
        );
      }
    };


    renderCategoryForm = () => {
      return (
          <div className="componentContainer">
              <Header />
              <div className="sectionContainer">
                  <div>
                      <div className="headerSection">
                          <span>Nueva Notificación</span>
                          <img src={logo} alt="Audiolibros" className="logoHeader"/>
                      </div>

                  </div>
                  <div className="booksColumns">
                      <div>
                          {this.renderNewCatForm()}
                      </div>
                  </div>
              </div>
          </div>
      );
  }

    handleTitleChange = (e) => {
        this.setState({title: e.target.value});
    }

    handleDescriptionChange = (e) => {
        this.setState({description: e.target.value});
    }

    handleCreateNotification = () => {
        const { title, description, image, gift, idUser } = this.state;
        this.props.newNotification(title, description, image, gift, idUser, () => {
            self.setState(
                {
                    showMsg: true
                },
                () => {
                    setTimeout(() => {
                        self.props.navigate('/notifications');
                    }, 1000);
                }
            );
        });
    }

    handleDescChange = e => {
      this.setState({
        description: e.target.value
      });
    };

    handleCancelNotification = () => {
        return () => {
            this.props.navigate('/notifications');
        };
    }
    handleGiftChange = gift => {
      this.setState({ gift });
    };

    renderLoader = () => {
        if(this.props.creatingCat){
            return <div className="newCatLoaderCont"><Bars color="#17a2b8" height={30} width={30} /></div>;
        }
    }

    renderNewCatCreationMsgOK = () => {
        return this.props.newCatMsgOK ? <span className="catMsgOK">{this.props.newCatMsgOK}</span> : '';
    }

    renderNewCatCreationMsgERROR = () => {
        return this.props.newCatMsgERROR ? <span className="catMsgERROR">{this.props.newCatMsgERROR}</span> : '';
    }

    renderNewCatForm = () => {
        return (
            <div className="catForm">
                <div className="formGroup">
                    <label className="formLabel">Usuario</label>
                    <input
                        value={this.state.user?.email}
                        type="text"
                        readOnly={true}
                        className="form-control"
                    />
                </div>
                <div className="formGroup">
                    <label className="formLabel">Título</label>
                    <input
                        value={this.state.title}
                        type="text"
                        className="form-control"
                        onChange={(e) => this.handleTitleChange(e)}
                    />
                </div>
                <div className="formGroup">
                    <label className="formLabel">Descripción</label>
                    <textarea
                        className="form-control"
                        value={this.state.description}
                        onChange={e => this.handleDescChange(e)}
                      />
                </div>
                <div className="formGroup radioGroupForm">
                  <span className="formLabel">Es Regalo?</span>
                  <div className="switchContainer">
                    <Switch
                      onChange={this.handleGiftChange}
                      checked={this.state.gift}
                      id="normal-switch"
                    />
                    <span>{this.state.gift ? 'SI' : 'NO'}</span>
                  </div>
                </div>
                <div className="formGroup">
                <span className="formLabel">Imagen</span>
                <div className="formGroupDual">
                  <div className="formGroupDualItem">
                    <FormGroup>
                      <Input
                        disabled={this.state.title === '' ? true : false}
                        type="file"
                        onChange={e => this.handleImageChange(e)}
                      />
                    </FormGroup>
                    {this.renderImgProgressBar()}
                  </div>
                  <div className="formGroupDualItem">
                    <img
                      className="editImage"
                      src={this.state.downloadUrl}
                      alt={this.state.title}
                    />
                  </div>
                </div>
              </div>

                <div className="formGroup btnGroup">
                    <button
                        className="btn btn-success"
                        onClick={this.handleCreateNotification}
                        disabled={this.btnStatusControl()}
                    >
                        Crear
                    </button>
                    <button
                        className="btn btn-danger"
                        onClick={this.handleCancelNotification()}
                    >
                        Cancelar
                    </button>
                </div>
                <div className="formGroup">
                    {this.renderLoader()}
                    {this.state.showMsg ? this.renderNewCatCreationMsgOK() : null}
                    {this.state.showMsg ? this.renderNewCatCreationMsgERROR(): null}
                </div>
            </div>
        );
    }

    renderComponent() {
        return this.props.auth ? (
            this.renderCategoryForm()
        ) : (
           <Navigate
                to={{
                    pathname: '/login',
                    state: { from: this.props.location }
                }}
            />
        );
    }

    render() {
        return this.renderComponent();
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth.authenticated,
    newCatMsgOK: state.categories.newCatMsgOK,
    user: state.user.user,
    newCatMsgERROR: state.categories.newCatMsgError,
    creatingCat: state.categories.creatingCat,
    imgProgress: state.uploadProgress.imgProgress,
    imageURL: state.imageUpload.imageUrl,
    imageUploading: state.imageUpload.uploadingImg,

});

export default connect(mapStateToProps, {getUser, newNotification, imgUpload})(withRouter(NewNotification));
