import { GET_ALL_USERS } from '../actions/types';

const INITIAL_STATE = {
  users: []
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ALL_USERS:
      return { ...state, users: action.payload  };
    default:
      return state;
  }
}