import {
    IMAGE_UPLOAD_PROGRESS,
    AUDIO_COMPLETE_UPLOAD_PROGRESS,
    AUDIO_SAMPLE_UPLOAD_PROGRESS,
} from '../actions/types';

const INITIAL_STATE = {
    imgProgress: 0,
    audioProgress: 0,
}

export default function (state=INITIAL_STATE, action) {
    switch (action.type) {
        case IMAGE_UPLOAD_PROGRESS:
            return { ...state, imgProgress:action.payload };
        case AUDIO_SAMPLE_UPLOAD_PROGRESS:
            return { ...state, audioSampleProgress:action.payload };
        case AUDIO_COMPLETE_UPLOAD_PROGRESS:
            return { ...state, audioCompleteProgress:action.payload };
        default:
            return state;
    }
}