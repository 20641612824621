import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import Header from './Header';
import { newPos } from '../actions';
import { Bars } from  'react-loader-spinner'
import { withRouter } from './withRouter';

let self = null;

class NewPOS extends Component {
    constructor(props){
        super(props);

        this.state= {
            name: '',
            personInCharge: '',
            email: '',
            phone: '',
            direction: '',
            location: '',
            open: false,
        };

        self = this;
    }

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    handleCancel = () => {
      this.props.navigate('/pos');
    }

    handleSave = () => {
        if(this.state.name !== ''){
            const {name, personInCharge, email, phone, direction, location} = this.state;
            this.props.newPos({name, personInCharge, email, phone, direction, location}, function() {
                setTimeout(function(){
                    self.props.navigate('/pos');
                },1500);
            });
        }else{
            this.setState({open: true});
        }
    }

    handleAddressChange = (e) => {
        this.setState({
            direction: e.target.value
        });
    }

    handleEmailChange = (e) => {
        this.setState({
            email: e.target.value
        });
    }

    handleLocationChange = (e) => {
        this.setState({
            location: e.target.value
        });
    }

    handlePersonInChargeChange = (e) => {
        this.setState({
            personInCharge: e.target.value
        });
    }

    handlePhoneChange = (e) => {
        this.setState({
            phone: e.target.value
        });
    }

    handleNameChange = (e) => {
        this.setState({
            name: e.target.value
        });
    }

    renderLoader = () => {
        if(this.props.savingPos){
            return <Bars color="#17a2b8" height={30} width={30} />;
        }
    }
    renderPosMsg = () => {
        return (
            <span className="posMsg">{this.props.posMsg}</span>
        );
    }

    renderPOSForm = () => {
        const { open } = this.state;
        return (
            <div className="componentContainer">
                <Header />
                <Modal
                    open={open}
                    onClose={this.onCloseModal}
                    showCloseIcon={false}
                >
                    <div className="modalContentContainer">
                        <span className="modalTitle">Debe ingresar al menos un nombre para crear un nuevo punto de venta</span>
                        <div className="modalBtnsContainer">
                            <button
                                className="btn btn-primary"
                                onClick={() => this.setState({open: false})}
                            >
                                Aceptar
                            </button>
                        </div>
                    </div>
                </Modal>
                <div className="sectionContainer">
                    <div className="headerSection">
                        <div className="headerContent">
                            <div className="headerSectionTitle">Nuevo Punto de Venta</div>
                        </div>
                    </div>
                    <div className="usersColumns">
                        <div className="posForm">
                            <div className="posFormGroup">
                                <span>Nombre</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => this.handleNameChange(e)}
                                />
                            </div>
                            <div className="posFormGroup">
                                <span>Persona responsable</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => this.handlePersonInChargeChange(e)}
                                />
                            </div>
                            <div className="posFormGroup">
                                <span>Email</span>
                                <input
                                    type="email"
                                    className="form-control"
                                    onChange={(e) => this.handleEmailChange(e)}
                                />
                            </div>
                            <div className="posFormGroup">
                                <span>Teléfono</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => this.handlePhoneChange(e)}
                                />
                            </div>
                            <div className="posFormGroup">
                                <span>Dirección</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => this.handleAddressChange(e)}
                                />
                            </div>
                            <div className="posFormGroup">
                                <span>Localidad</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => this.handleLocationChange(e)}
                                />
                            </div>
                            <div className="posFormGoup btnGroup">
                                <button
                                    className="btn btn-success"
                                    onClick={this.handleSave}
                                >
                                    Guardar
                                </button>
                                <button
                                    className="btn btn-danger"
                                    onClick={this.handleCancel}
                                >
                                    Cancelar
                                </button>
                            </div>
                            <div className="posFormGroup loaderHolder">
                                {this.renderLoader()}
                            </div>
                            <div className="posFormGroup posMsgHolder">
                                {this.renderPosMsg()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderComponent = () => {
        return this.props.auth ? (
            this.renderPOSForm()
        ) : (
           <Navigate
                to={{
                    pathname: '/login',
                    state: { from: this.props.location }
                }}
            />
        );
    }

    render() {
        return this.renderComponent();
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth.authenticated,
        savingPos: state.posData.savingPos,
        posMsg: state.posData.posMsg,
    };
};

export default connect(mapStateToProps, { newPos })(withRouter(NewPOS));