import React, {Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import Modal from 'react-responsive-modal';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import SearchInput, {createFilter} from 'react-search-input';
import { Navigate, Link } from 'react-router-dom';
import {connect} from 'react-redux';
import Header from './Header';
import { Bars } from  'react-loader-spinner'
import {getAllBooks, deleteBook, getAllCategories} from '../actions';
import logo from '../assets/logoHeader.png';

const KEYS_TO_FILTERS = ['books.title'];
const columns = [
    {
        Header: 'ID',
        accessor: 'idBook',
    },
    {
        id: 'bookTitle',
        Header: 'Titulo',
        accessor: 'books.title'
    },
    {
        id: 'category',
        Header: 'Categoría',
        Cell: row => {
            let cat =  _.filter(self.props.categories, (category) => {
                return category.catId === row.original.books.catId;
            });
            if(cat.length > 0){
                return <span>{cat[0].categorie.name}</span>;
            }
            return null;
        }
    },
    {
        id:'outstanding',
        Header: 'Destacado',
        Cell: row => {
            if(row.original.books.outstanding){
                return <span>Si</span>;
            }else{
                return <span>No</span>;
            }
        }
    },
    {
        id:'novelty',
        Header: 'Novedad',
        Cell: row => {
            if(row.original.books.novelty){
                return <span>Si</span>;
            }else{
                return <span>No</span>;
            }
        }
    },
    {
        id: 'acciones',
        Header: 'Acciones',
        Cell: row => (
            <div className="bookTableBtns">
                <Link
                    to={`/book/${row.original.idBook}`}
                    className="btn btn-primary btn-sm"
                >
                    <FontAwesomeIcon
                        icon='edit'
                        size='1x'
                    />
                </Link>
                <button
                    className="btn btn-danger btn-sm"
                    onClick={() => self.handleDeleteClick(row.original)}
                >
                    <FontAwesomeIcon
                        icon='trash-alt'
                        size='1x'
                    />
                </button>
            </div>
        )
    }
];
let self = null;

class BooksList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            book: {},
            filterText: '',
        };
        this.handleSearchChange = this.handleSearchChange.bind(this);
        self = this;
    }

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    componentDidMount() {
        this.props.getAllBooks();
        this.props.getAllCategories();
    }

    handleDeleteClick = (book) => {
        this.setState({
            open: true,
            book: book
        });
    }

    handleSearchChange(term) {
        this.setState({filterText: term});
    }

    renderUsersTable = () => {
        if(this.props.loadingBooks){
            return <Bars color="#17a2b8" height={30} width={30} />;
        }
        const filteredBooks = this.props.books.filter(createFilter(this.state.filterText, KEYS_TO_FILTERS));
        const books = _.sortBy(filteredBooks, (book) => {
            return !book.books.outstanding;
        });
        return (
            <ReactTable
                data={books}
                columns={columns}
                defaultPageSize={15}
                showPageSizeOptions={false}
                className="-striped"
                previousText= 'Anterior'
                nextText= 'Siguiente'
                loadingText= 'Cargando...'
                noDataText= 'No se encontraron filas'
                pageText= 'Pagina'
                ofText= 'de'
                rowsText= 'filas'
            />
        );
    }

    handleDeleteBook = () => {
        this.props.deleteBook(this.state.book.idBook, () => {
            self.setState({
                open: false,
                book: {}
            }, self.props.getAllBooks());

        });
    }

    renderBooksList = () => {
        const { open } = this.state;
        return (
            <div className="componentContainer">
                <Modal
                    open={open}
                    onClose={this.onCloseModal}
                    showCloseIcon={false}
                >
                <div className="modalContentContainer">
                    <span className="modalTitle">ELIMINAR AUDIOLIBRO</span>
                    <span className="modalText">¿Está seguro que quiere eliminar el audiolibro <strong>{this.state.book.books ? this.state.book.books.title : ''}</strong>?</span>
                    <div className="modalBtnsContainer">
                        <button
                            className="btn btn-primary"
                            onClick={this.handleDeleteBook}
                        >
                            Borrar
                        </button>
                        <button
                            className="btn btn-danger"
                            onClick={() => this.setState({open: false, book: {}})}
                        >
                            Cancelar
                        </button>
                    </div>
                </div>
                </Modal>
                <Header />
                <div className="sectionContainer">
                    <div>
                        <div className="headerSection">
                            <span>Libros</span>
                            <img src={logo} alt="Audiolibros" className="logoHeader"/>
                        </div>
                    </div>
                    <div className="booksColumns">
                        <div className="booksListSubHeader">
                            <SearchInput
                                placeholder="Buscar"
                                className="search-input appSearchInput"
                                onChange={this.handleSearchChange}
                            />
                            <Link
                                to="/newbook"
                                className="btn btn-success"
                            >
                                Nuevo Libro
                            </Link>
                        </div>
                        {this.renderUsersTable()}
                    </div>
                </div>
            </div>
        );
    }

    renderComponent = () => {
        return this.props.auth ? (
            this.renderBooksList()
        ) : (
           <Navigate
                to={{
                    pathname: '/login',
                    state: { from: this.props.location }
                }}
            />
        );
    }

    render() {
        return this.renderComponent();
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth.authenticated,
        books: state.books.books,
        msg: state.deleteMsg.deletedMsg,
        loadingBooks: state.books.loadingBooks,
        categories: state.categories.categories,
    };
};

export default connect(mapStateToProps, {getAllBooks, deleteBook, getAllCategories})(BooksList);