import { 
    AUDIO_SAMPLE_UPLOADED_OK,
    AUDIO_COMPLETE_UPLOADED_OK,
    AUDIO_UPLOADED_ERROR,
    AUDIO_SAMPLE_UPLOADING,
    AUDIO_COMPLETE_UPLOADING,
} from '../actions/types';

const INITIAL_STATE = {
  audioSampleUrl: '',
  audioCompleteUrl: '',
  uploadError: '',
  uploadingSampleAudio: false,
  uploadingCompleteAudio: false,
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AUDIO_SAMPLE_UPLOADING:
      return { 
        ...state, 
        audioSampleUrl: '', 
        uploadError: '', 
        uploadingSampleAudio: true, 
      } 
    case AUDIO_COMPLETE_UPLOADING:
      return { 
        ...state, 
        audioCompleteUrl: '', 
        uploadError: '', 
        uploadingCompleteAudio: true, 
      }
    case AUDIO_SAMPLE_UPLOADED_OK:
      return { 
        ...state, 
        audioSampleUrl: action.payload, 
        uploadingSampleAudio: false  
      }
    case AUDIO_COMPLETE_UPLOADED_OK:
      return { 
        ...state, 
        audioCompleteUrl: action.payload, 
        uploadingCompleteAudio: false  
      }
    case AUDIO_UPLOADED_ERROR:
      return { 
        ...state, 
        'uploadError': 'error cargando el audio al servidor', 
        uploadingSampleAudio: false,
        uploadingCompleteAudio: false 
      }
    default:
      return state;
  }
}