import React, {Component} from 'react';
import Switch from 'react-switch';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Header from './Header';
import { getUser, updateUser, getAllBooks } from '../actions';
import { Bars } from  'react-loader-spinner';
import { withRouter } from './withRouter';

let self = null;

class UserEdit extends Component {
    constructor(props){
        super(props);

        this.state= {
            email: '',
            idUser: '',
            books: [],
            checked: false,
            checkedAuth: false,
            showMsg: false,
        };

        self = this;
    }

    componentDidMount = () => {
        this.props.getAllBooks();
        const { params } = this.props;
        this.props.getUser(params.id, () => {
          if (self.props.user.books && self.props.user.books.length > 0) {
            if(self.props.books && self.props.books.length > 0){
              let _myBooks = [];
              self.props.user.books.forEach((myBook, i) => {
                let found = self.props.books.filter(book => { return book.idBook === myBook.idBook });
                if (found.length > 0) {
                  _myBooks[i] = {...found[0], ...myBook};
                }
              });
              self.setState({books: _myBooks});
            }
          }


            self.setState({
                email: self.props.user.email,
                checked: self.props.user.isAdmin,
                checkedAuth: self.props.user.authorized,
                idUser: params.id
            });
        });
    }

    handleCancel = () => {
        this.props.navigate('/users');
    }

    handleSave = () => {
        if(this.state.email !== ''){
            const {idUser, email, checked, checkedAuth} = this.state;
            this.props.updateUser(idUser, email, checked, checkedAuth, function() {
                self.setState({showMsg: true});
                setTimeout(function(){
                    self.props.navigate('/users');
                },1500);
            });
        }else{
            alert('No puede guardar el usuario sin un email');
        }
    }

    handleEmailChange = (e) => {
        this.setState({
            email: e.target.value
        });
    }

    handleOptionChange = (checked) => {
        this.setState({ checked });
    }

    handleOptionAuthChange = (checked) => {
        this.setState({ checkedAuth: checked });
    }

    renderLoader = () => {
        if(this.props.savingPos){
            return <Bars color="#17a2b8" height={30} width={30} />;
        }
    }

    renderEditUserMsgOK = () => {
        if(this.props.updateUserOK && this.state.showMsg){
            return (
                <span className="posMsg">{this.props.updateUserOK}</span>
            );
        };
    }

    renderEditUserMsgERROR = () => {
        if(this.props.updateUserERROR && this.state.showMsg){
            return (
                <span className="posMsg">{this.props.updateUserERROR}</span>
            );
        };
    }

    renderUserForm = () => {
        return (
            <div className="componentContainer">
                <Header />
                <div className="sectionContainer">
                    <div className="headerSection">
                        <div className="headerContent">
                            <div className="headerSectionTitle">Edición de Usuario</div>
                        </div>
                    </div>
                    <div className="usersColumns">
                        <div className="posForm">
                            <div className="posFormGroup">
                                <span>Email</span>
                                <input
                                    value={this.state.email}
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => this.handleEmailChange(e)}
                                />
                            </div>

                            <div className="posFormGroup">
                                <span>Esta autorizado</span>
                                <div className="switchContainer">
                                    <Switch
                                        onChange={this.handleOptionAuthChange}
                                        checked={this.state.checkedAuth}
                                        id="normal-switch"
                                    />
                                    <span>{this.state.checkedAuth ? 'SI' : 'NO'}</span>
                                </div>
                            </div>

                            <div className="posFormGroup">
                                <span>Es administrador</span>
                                <div className="switchContainer">
                                    <Switch
                                        onChange={this.handleOptionChange}
                                        checked={this.state.checked}
                                        id="normal-switch"
                                    />
                                    <span>{this.state.checked ? 'SI' : 'NO'}</span>
                                </div>
                            </div>

                            <div className="posFormGroup">
                                <span>Libros Comprados</span>
                                <div style={{backgroundColor:'#cecece'}}>
                                {
                                  this.state.books.map(book => {
                                    return <>
                                      <div>- { book.books.title }</div>
                                    </>
                                  })
                                }
                                  </div>
                            </div>


                            <div className="posFormGoup btnGroup">
                                <button
                                    className="btn btn-success"
                                    onClick={this.handleSave}
                                >
                                    Guardar
                                </button>
                                <button
                                    className="btn btn-danger"
                                    onClick={this.handleCancel}
                                >
                                    Cancelar
                                </button>
                            </div>
                            <div className="posFormGroup loaderHolder">
                                {this.renderLoader()}
                            </div>
                            <div className="posFormGroup posMsgHolder">
                                {this.renderEditUserMsgOK()}
                                {this.renderEditUserMsgERROR()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderComponent = () => {
        return this.props.auth ? (
            this.renderUserForm()
        ) : (
           <Navigate
                to={{
                    pathname: '/login',
                    state: { from: this.props.location }
                }}
            />
        );
    }

    render() {
        return this.renderComponent();
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth.authenticated,
        user: state.user.user,
        books: state.books.books,
        updatingUser: state.user.updatingUser,
        updateUserOK: state.user.updateUserOK,
        updateUserERROR: state.user.updateUserERROR
    };
};

export default connect(mapStateToProps, { getUser, updateUser, getAllBooks })(withRouter(UserEdit));