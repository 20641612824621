import React, {Component} from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Navigate } from 'react-router-dom';
import Header from './Header';
import { getAllBooks, getAllPos, newBatch } from '../actions';
import { Bars } from  'react-loader-spinner';
import { withRouter } from './withRouter';

let self = null;
class NewQRCode extends Component {
    constructor(props) {
        super(props);
        this.state={
            posID: '',
            bookID: '',
            amount: '',
            used_infinity: false,
            showQR: false,
        };

        self = this;
    }


    componentDidMount = () => {
        this.props.getAllBooks();
        this.props.getAllPos();
    }

    renderBooksSelect = () => {
        if(this.props.books.length > 0){
            let optionsBooks = [];
            this.props.books.forEach(function(book){
                optionsBooks.push(
                    {
                        value: book.idBook,
                        label: book.books.title
                    }
                );
            });
            return (
                <Select
                    options={optionsBooks}
                    onChange={this.handleBookChange}
                />
            );
        }
        return <Bars color="#17a2b8" height={30} width={30} />;
    }

    renderSelectPointOfSale = () => {
        if(this.props.posData.length > 0){
            let optionsPos = [];
            this.props.posData.forEach(function(pos){
                optionsPos.push(
                    {
                        value: pos.idPOS,
                        label: pos.pos.name
                    }
                );
            });
            return (
                <Select
                    options={optionsPos}
                    onChange={this.handlePOSChange}
                />
            );
        }
        return <Bars color="#17a2b8" height={30} width={30} />;
    }

    handleBookChange = (obj) => {
        this.setState({bookID: obj.value});
    }

    handlePOSChange = (obj) => {
        this.setState({posID: obj.value});
    }

    handleAmountChange = (e) => {
        this.setState({amount: e.target.value});
    }

    handleInfinityChange = (e) => {
        this.setState({used_infinity: e.target.checked});
    }

    renderQRForm = () => {
        return (
            <div className="componentContainer">
                <Header />
                <div className="sectionContainer">
                    <div className="headerSection">
                        <div className="headerContent">
                            <div className="headerSectionTitle">Nuevo Lote de Códigos QR</div>
                        </div>
                    </div>
                    <div className="usersColumns">
                        <div className="qrForm">
                            <div className="qrFormGroup">
                                <span>Libro</span>
                                {this.renderBooksSelect()}
                            </div>
                            <div className="qrFormGroup">
                                <span>Punto de Venta</span>
                                {this.renderSelectPointOfSale()}
                            </div>
                            <div className="qrFormGroup">
                                <span>Cantidad de Ventas</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => this.handleAmountChange(e)}
                                />
                            </div>
                            <div className="qrFormGroup infinityHolder">
                                <span>Uso infinito</span>
                                <input
                                    type="checkbox"
                                    id="infinityCheck"
                                    onChange={(e) => this.handleInfinityChange(e)}/>
                            </div>
                            <div className="qrFormGroup">
                                {this.renderGenerateBtn()}
                            </div>
                            <div className="qrFormGroup">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    cancelQRGeneration = () => {
        self.props.navigate('/qrcodes');
    }

    renderGenerateBtn = () => {
        if(!this.props.savingBatch && this.props.batchMsg === ''){
            return (
                <div className="btnsGenerateQRHolder">
                    <button
                        onClick={this.generateQR}
                        className="btn btn-info"
                    >
                        Generar
                    </button>
                    <button
                        onClick={this.cancelQRGeneration}
                        className="btn btn-danger"
                    >
                        Cancelar
                    </button>
                </div>
            );
        }else if(!this.props.savingBatch && this.props.batchMsg !== ''){
            return <span className="batchMsg">{this.props.batchMsg}</span>;
        }
        return <Bars color="#17a2b8" height={30} width={30} />;
    }

    generateQR = () => {
        const {posID, bookID, amount, used_infinity} = this.state;
        this.props.newBatch({posID, bookID, amount, used_infinity}, () => {
            setTimeout(function() {
                self.props.navigate('/qrcodes');
            },1500);
        });
    }

    renderComponent = () => {
        return this.props.auth ? (
            this.renderQRForm()
        ) : (
           <Navigate
                to={{
                    pathname: '/login',
                    state: { from: this.props.location }
                }}
            />
        );
    }

    render() {
        return this.renderComponent();
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth.authenticated,
        books: state.books.books,
        posData: state.posData.posData,
        savingBatch: state.batches.savingBatch,
        batchMsg: state.batches.batchMsg,
    };
};

export default connect(mapStateToProps,{ getAllBooks, getAllPos, newBatch })(withRouter(NewQRCode));