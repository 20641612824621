import React, { Component } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import { Bars } from  'react-loader-spinner'
import Select from 'react-select';
import ReactTable from 'react-table-6';
import { Link } from 'react-router-dom';
import 'react-table-6/react-table.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let self = null;

const columns = [
  {
    id: 'date',
    Header: 'Fecha',
    Cell: row => {
      let curdate = new Date(null);
      curdate.setTime(row.original.data.date.seconds * 1000);
      let finalDate =
        curdate.toLocaleDateString() + ' - ' + curdate.toLocaleTimeString();
      let formatedDate = finalDate.split('-');
      return formatedDate[0];
    }
  },
  {
    id: 'product',
    Header: 'Producto',
    Cell: row => {
      let book = self.props.books.filter(book => {
        return book.idBook === row.original.data.idBook;
      });
      return book[0].books.title;
    }
  },
  {
    id: 'pointOfSale',
    Header: 'Punto de Venta',
    Cell: row => {
      if (row.original.data.appSale) {
        return 'App';
      } else if (!row.original.data.appSale) {
        let pos = self.props.pointOfSales.filter(point => {
          return point.idPOS === row.original.data.pointOfSale;
        });
        return (pos && pos[0] && pos[0].pop && pos[0].pos.name) || '';
      }
    }
  },
  {
    id: 'actions',
    Header: 'Acciones',
    Cell: row => {
      return (
        <div className="qrTableBtns">
          <Link
            to={`/saledetail/${row.original.id}`}
            className="btn btn-primary btn-sm"
          >
            <FontAwesomeIcon icon="eye" size="1x" />
            <span className="seeBatchDetailBtn">Ver</span>
          </Link>
        </div>
      );
    }
  }
];

class Sales extends Component {
  constructor(props) {
    super(props);
    self = this;

    this.state = {
      bookFilter: '',
      pointOfSaleFilter: '',
      startDate: '',
      endDate: ''
    };
  }

  renderSelectProduct = () => {
    if (this.props.books.length > 0) {
      let optionsBook = [
        {
          value: '',
          label: 'Todos'
        }
      ];
      this.props.books.forEach(function(book) {
        optionsBook.push({
          value: book.idBook,
          label: book.books.title
        });
      });
      let selectedOpt = optionsBook.findIndex(
        x => x.value === self.state.bookFilter
      );
      return (
        <Select
          value={optionsBook[selectedOpt]}
          options={optionsBook}
          onChange={this.handleBookChange}
        />
      );
    }
    return <Bars color="#17a2b8" height={30} width={30} />;
  };

  handleBookChange = obj => {
    this.setState({
      bookFilter: obj.value,
      pointOfSaleFilter: '',
      startDate: '',
      endDate: ''
    });
  };

  renderSelectPos = () => {
    if (this.props.pointOfSales.length > 0) {
      let optionsPos = [
        {
          value: '',
          label: 'Todos'
        },
        {
          value: 'app',
          label: 'App'
        }
      ];
      this.props.pointOfSales.forEach(function(pos) {
        optionsPos.push({
          value: pos.idPOS,
          label: pos.pos.name
        });
      });
      let selectedOpt = optionsPos.findIndex(
        x => x.value === self.state.pointOfSaleFilter
      );
      return (
        <Select
          value={optionsPos[selectedOpt]}
          options={optionsPos}
          onChange={this.handlePosChange}
        />
      );
    }
    return <Bars color="#17a2b8" height={30} width={30} />;
  };

  handlePosChange = obj => {
    this.setState({
      bookFilter: '',
      pointOfSaleFilter: obj.value,
      startDate: '',
      endDate: ''
    });
  };

  renderStatusTable = () => {
    let filteredData = null;

    if (this.state.bookFilter !== '') {
      filteredData = this.props.sales.filter(sale => {
        return sale.data.idBook === this.state.bookFilter;
      });
    }

    if (this.state.pointOfSaleFilter !== '') {
      filteredData = this.props.sales.filter(sale => {
        return sale.data.pointOfSale === this.state.pointOfSaleFilter;
      });
    }

    if (this.state.startDate !== '' && this.state.endDate !== '') {
      let filtered = this.props.sales.filter(
        function(e) {
          let curdate = new Date(null);
          curdate.setTime(e.data.date.seconds * 1000);
          return moment(curdate).isBetween(
            self.state.startDate,
            self.state.endDate,
            null,
            []
          );
        },
        [this.state.startDate, this.state.endDate]
      );
      filteredData = filtered;
    }

    let result = filteredData === null ? this.props.sales : filteredData;
    let orderedResult = result.sort(function(a, b) {
      return new Date(b.data.date.seconds) - new Date(a.data.date.seconds);
    });

    return (
      <ReactTable
        data={orderedResult}
        columns={columns}
        defaultPageSize={15}
        showPageSizeOptions={false}
        className="-striped"
        previousText="Anterior"
        nextText="Siguiente"
        loadingText="Cargando..."
        noDataText="No se encontraron filas"
        pageText="Pagina"
        ofText="de"
        rowsText="filas"
      />
    );
  };

  clearFilters = () => {
    this.setState({
      bookFilter: '',
      pointOfSaleFilter: '',
      startDate: '',
      endDate: ''
    });
  };

  handleApplyDatePicker = picker => {
    this.setState({
      startDate: picker.startDate._d,
      endDate: picker.endDate._d
    });
  };

  renderDateFilter = () => {
    return (
      <div className="datePickerContainer">
        <DateRangePicker
          showDropdowns
          onApply={(ev, picker) => this.handleApplyDatePicker(picker)}
          locale={{
            cancelLabel: 'Cancelar',
            applyLabel: 'Aceptar',
            daysOfWeek: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
            monthNames: [
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre'
            ]
          }}
        >
          <button className="btn btn-primary">Seleccionar fecha</button>
        </DateRangePicker>
      </div>
    );
  };

  render() {
    return (
      <div className="mainHomeContainer">
        <div className="filterBoxContainer">
          <div className="formGroup">
            <span className="formLabel">Filtrar por producto</span>
            {this.renderSelectProduct()}
          </div>
          <div className="formGroup myFormGroup">
            <span className="formLabel">Filtrar por punto de venta</span>
            {this.renderSelectPos()}
          </div>
          <div className="formGroup dateFilterHolder">
            <span className="formLabel">Filtrar por fecha</span>
            {this.renderDateFilter()}
          </div>
          <div className="formGroup datesHolder">
            <span>
              {this.state.startDate === ''
                ? ''
                : `Desde: ${this.state.startDate.toLocaleDateString()}`}
            </span>
            <span>
              {this.state.endDate === ''
                ? ''
                : `Hasta: ${this.state.endDate.toLocaleDateString()}`}
            </span>
          </div>
          <div className="formGroup">
            <br />
            <button className="btn btn-info" onClick={this.clearFilters}>
              Limpiar
            </button>
          </div>
        </div>
        <div className="homeTableHolder">{this.renderStatusTable()}</div>
      </div>
    );
  }
}

export default Sales;
