import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import SalesByPeriod from './SalesByPeriod';
import {
    getAllBooks,
    getAllUsers,
    getAllPos,
    getBatches,
    getAllQrsByBatch,
    getAllSales
} from '../actions';
import Header from './Header';
import logo from '../assets/logoHeader.png';

class ReportSalesByPeriod extends Component {
    componentDidMount() {
        this.props.getAllBooks();
        this.props.getAllUsers();
        this.props.getAllPos();
        this.props.getBatches();
        this.props.getAllQrsByBatch();
        this.props.getAllSales();
    }

    renderHome = () => {
      return (
        <div className="componentContainer">
        <Header />
        <div className="sectionContainer">
            <div className="headerSection">
                <div className="headerContent">
                    <div className="headerSectionTitle">Ventas totales por período</div>
                    <img src={logo} alt="Audiolibros" className="logoHeader"/>
                </div>
            </div>
            <div className="usersColumns">
              <SalesByPeriod
                books={this.props.books}
                pointOfSales={this.props.pointOfSales}
                batches={this.props.batches}
                qrsByBatch={this.props.qrsByBatch}
                sales={this.props.sales}
            />
            </div>
        </div>
    </div>
      );
    }


    renderComponent = () => {
        return this.props.auth ? (
            this.renderHome()
        ) : (
           <Navigate
                to={{
                    pathname: '/login',
                    state: { from: this.props.location }
                }}
            />
        );
    }

    render() {
        return this.renderComponent();
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth.authenticated,
        books: state.books.books,
        users: state.users.users,
        pointOfSales: state.posData.posData,
        batches: state.batches.batches,
        qrsByBatch: state.qrsByBatch.qrsByBatch,
        sales: state.sales.sales
    };
};

export default connect(
    mapStateToProps,
    {
        getAllBooks,
        getAllUsers,
        getAllPos,
        getBatches,
        getAllQrsByBatch,
        getAllSales
    }
)(ReportSalesByPeriod);