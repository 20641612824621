import { GET_ALL_SALES, GET_SALE, GET_SALE_SUCCESS, GET_SALE_QR } from '../actions/types';

const INITIAL_STATE = {
    sales: [],
    loadingSale: false,
    sale: [],
    saleQr: []
};

export default function (state=INITIAL_STATE, action) {
    switch(action.type) {
        case GET_ALL_SALES:
            return { ...state, sales: action.payload };
        case GET_SALE:
            return { ...state, loadingSale: true };
        case GET_SALE_SUCCESS:
            return { ...state, loadingSale: false, sale: action.payload };
        case GET_SALE_QR:
            return { ...state, saleQr: action.payload };
        default:
            return { ...state };
    }
}

