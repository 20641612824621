import {
    NEW_USER_OK, 
    NEW_USER_ERROR,
    NEW_USER_CREATION_PROCCESS,
    CLEAR_NEW_USER_STATE
} from '../actions/types';

const INITIAL_STATE = {
    createUserOk: '',
    createUserError: '',
    loading: false
}

export default function(state=INITIAL_STATE, action) {
    switch(action.type){
        case NEW_USER_CREATION_PROCCESS:
            return { ...state, loading: true, createUserOk: '', createUserError: '' }
        case NEW_USER_OK:
            return { ...state, createUserOk: action.payload, loading: false };
        case NEW_USER_ERROR:
            return { ...state, createUserError: action.payload, loading: false };
        case CLEAR_NEW_USER_STATE:
            return { ...state, ...INITIAL_STATE} 
        default:
            return state;
    }
}