import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Header from './Header';

class About extends Component {
    renderAbout = () => {
        return (
            <div className="componentContainer">
                <Header />
                <div>About</div>
            </div>
        );
    }
    renderComponent = () => {
        return this.props.auth ? (
            this.renderAbout()
        ) : (
           <Navigate
                to={{
                    pathname: '/login',
                    state: { from: this.props.location }
                }}
            />
        );
    }

    render() {
        return this.renderComponent();
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth.authenticated,
    };
};

export default connect(mapStateToProps)(About);