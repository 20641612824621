import { useNavigate } from 'react-router-dom';

import { useParams } from "react-router-dom";

// function withParams(Component) {
//   return props => <Component {...props} params={useParams()} />;
// }

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const navigate = useNavigate();

    return (
      <Component
        params={useParams()}
        navigate={navigate}
        {...props}
        />
    );
  };

  return Wrapper;
};