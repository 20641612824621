import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Bars } from  'react-loader-spinner'
import { newCat } from '../actions';
import Header from './Header';
import logo from '../assets/logoHeader.png';
import { withRouter } from './withRouter';

let self = null;
export class NewCategory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            description: '',
            showMsg: false,
        };

        self = this;
    }

    renderCategoryForm = () => {
        return (
            <div className="componentContainer">
                <Header />
                <div className="sectionContainer">
                    <div>
                        <div className="headerSection">
                            <span>Nueva Categoria</span>
                            <img src={logo} alt="Audiolibros" className="logoHeader"/>
                        </div>

                    </div>
                    <div className="booksColumns">
                        <div>
                            {this.renderNewCatForm()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    handleNameChange = (e) => {
        this.setState({name: e.target.value.toUpperCase()});
    }

    handleDescriptionChange = (e) => {
        this.setState({description: e.target.value});
    }

    handleCreateCat = () => {
        const { name, description } = this.state;
        this.props.newCat(name, description, () => {
            self.setState(
                {
                    showMsg: true
                },
                () => {
                    setTimeout(() => {
                        self.props.navigate('/categories');
                    }, 1000);
                }
            );
        });
    }

    handleCancelCat = () => {
        return () => {
            this.props.navigate('/categories');
        };
    }

    renderLoader = () => {
        if(this.props.creatingCat){
            return <div className="newCatLoaderCont"><Bars color="#17a2b8" height={30} width={30} /></div>;
        }
    }

    renderNewCatCreationMsgOK = () => {
        return this.props.newCatMsgOK ? <span className="catMsgOK">{this.props.newCatMsgOK}</span> : '';
    }

    renderNewCatCreationMsgERROR = () => {
        return this.props.newCatMsgERROR ? <span className="catMsgERROR">{this.props.newCatMsgERROR}</span> : '';
    }

    renderNewCatForm = () => {
        return (
            <div className="catForm">
                <div className="formGroup">
                    <label className="formLabel">Nombre</label>
                    <input
                        value={this.state.name}
                        type="text"
                        className="form-control"
                        placeholder="Nombre"
                        onChange={(e) => this.handleNameChange(e)}
                    />
                </div>
                <div className="formGroup">
                    <label className="formLabel">Descripción</label>
                    <input
                        value={this.state.description}
                        type="text"
                        className="form-control"
                        placeholder="Descripción"
                        onChange={(e) => this.handleDescriptionChange(e)}
                    />
                </div>
                <div className="formGroup btnGroup">
                    <button
                        className="btn btn-success"
                        onClick={this.handleCreateCat}
                    >
                        Crear
                    </button>
                    <button
                        className="btn btn-danger"
                        onClick={this.handleCancelCat()}
                    >
                        Cancelar
                    </button>
                </div>
                <div className="formGroup">
                    {this.renderLoader()}
                    {this.state.showMsg ? this.renderNewCatCreationMsgOK() : null}
                    {this.state.showMsg ? this.renderNewCatCreationMsgERROR(): null}
                </div>
            </div>
        );
    }

    renderComponent() {
        return this.props.auth ? (
            this.renderCategoryForm()
        ) : (
           <Navigate
                to={{
                    pathname: '/login',
                    state: { from: this.props.location }
                }}
            />
        );
    }

    render() {
        return this.renderComponent();
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth.authenticated,
    newCatMsgOK: state.categories.newCatMsgOK,
    newCatMsgERROR: state.categories.newCatMsgError,
    creatingCat: state.categories.creatingCat,
});

export default connect(mapStateToProps, {newCat})(withRouter(NewCategory));
