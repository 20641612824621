import { GET_ALL_NOTIFICATIONS } from '../actions/types';

const INITIAL_STATE = {
  notifications: []
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ALL_NOTIFICATIONS:
      return { ...state, notifications: action.payload  };
    default:
      return state;
  }
}