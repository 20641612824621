import {
    NEW_BATCH,
    NEW_BATCH_ERROR,
    NEW_BATCH_SUCCESS,
    GET_BATCHES,
    GET_BATCHES_ERROR,
    GET_BATCHES_SUCCESS,
    GET_QR_BY_BATCH,
    GET_QR_BY_BATCH_SUCCESS,
    GET_QR_BY_BATCH_ERROR,
    GET_BATCH,
    GET_BATCH_SUCCESS,
    GET_BATCH_ERROR,
    DELETE_QR_BY_BATCH,
    DELETE_QR_BY_BATCH_SUCCESS,
    DELETE_BATCH,
    DELETE_BATCH_SUCCESS,
} from '../actions/types';

const INITIAL_STATE = {
    savingBatch: false,
    loadingBatches: false,
    loadingQrsByBatch: false,
    loadingBatch: false,
    deletingQrbyBatch: false,
    batchMsg: '',
    batches: [],
    qrsByBatch: [],
    batch: [],
    megaDeletion: '',
};

export default function(state=INITIAL_STATE, action) {
    switch(action.type){
        case NEW_BATCH:
            return { ...state, savingBatch: true, batchMsg: '' };
        case NEW_BATCH_SUCCESS:
            return { ...state, savingBatch: false, batchMsg: action.payload };
        case NEW_BATCH_ERROR:
            return { ...state, savingBatch: false, batchMsg: action.payload };
        case GET_BATCHES:
            return { ...state, loadingBatches: true, batchMsg: '' };
        case GET_BATCHES_SUCCESS:
            return { ...state, loadingBatches: false, batches: action.payload, batchMsg: '' };
        case GET_BATCHES_ERROR:
            return { ...state, loadingBatches: false, batchMsg: 'Error recuperando los datos' };
        case GET_QR_BY_BATCH:
            return { ...state, loadingQrsByBatch: true, batchMsg: '' };
        case GET_QR_BY_BATCH_SUCCESS:
            return { ...state, loadingQrsByBatch: false, qrsByBatch: action.payload, batchMsg: '' };
        case GET_QR_BY_BATCH_ERROR:
            return { ...state, loadingQrsByBatch: false, batchMsg: 'Error recuperando los datos' };
        case GET_BATCH:
            return { ...state, loadingBatch: true, batchMsg: '' };
        case GET_BATCH_SUCCESS:
            return { ...state, loadingBatch: false, batch: action.payload, batchMsg: '' };
        case GET_BATCH_ERROR:
            return { ...state, loadingBatch: false, batchMsg: 'Error recuperando los datos' };
        case DELETE_QR_BY_BATCH:
            return { ...state, deletingQrbyBatch: true };
        case DELETE_QR_BY_BATCH_SUCCESS:
            return { ...state, deletingQrbyBatch: false };
        case DELETE_BATCH:
            return { ...state,  megaDeletion: 'borrando'};
        case DELETE_BATCH_SUCCESS:
            return { ...state, megaDeletion: 'Todo borrado' };
        default:
            return state;
    }
}