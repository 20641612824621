import {
  GET_ALL_COMBOS,
  GET_ALL_COMBOS_SUCCESS,
  NEW_COMBO_CREATED_OK,
  GET_COMBO_OK,
  COMBO_UPDATE_OK
} from '../actions/types';

const INITIAL_STATE = {
  loadingCombos: false,
  comboCreatedOk: false,
  comboUpdatedOk: false,
  combos: [],
  combo: null
};

export default function(state = INITIAL_STATE, actions) {
  switch (actions.type) {
    case GET_ALL_COMBOS:
      return { ...state, loadingCombos: true, comboCreatedOk: false };
    case GET_ALL_COMBOS_SUCCESS:
      return { ...state, combos: actions.payload, loadingCombos: false };
    case NEW_COMBO_CREATED_OK:
      return { ...state, comboCreatedOk: true };
    case GET_COMBO_OK:
      return { ...state, combo: actions.payload };
    case COMBO_UPDATE_OK:
      return { ...state, comboUpdatedOk: true };
    default:
      return state;
  }
}
