import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import logo from '../assets/logo-simple-white.png';
import { faBell } from "@fortawesome/free-solid-svg-icons";


class Header extends Component {
  render() {
    return (
      <div className="sideBar">
        <div className="fixedContent">
          <div className="sidebarHeader">
            <img src={logo} alt="Audiolibros" className="logoSidebar"/>
          </div>
          <ul>
            <li>
              <Link to="/" className="likTag">
                <div className="headerLink">
                  <div className="iconHolder">
                    <FontAwesomeIcon icon="home" size="1x" />
                  </div>
                  <div className="spanHolder">
                    <span>Inicio</span>
                  </div>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/users" className="likTag">
                <div className="headerLink">
                  <div className="iconHolder">
                    <FontAwesomeIcon icon="users" size="1x" />
                  </div>
                  <div className="spanHolder">
                    <span>Usuarios</span>
                  </div>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/books" className="likTag">
                <div className="headerLink">
                  <div className="iconHolder">
                    <FontAwesomeIcon icon="book" size="1x" />
                  </div>
                  <div className="spanHolder">
                    <span>Audiolibros</span>
                  </div>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/categories" className="likTag">
                <div className="headerLink">
                  <div className="iconHolder">
                    <FontAwesomeIcon icon="sitemap" size="1x" />
                  </div>
                  <div className="spanHolder">
                    <span>Categorias</span>
                  </div>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/combos" className="likTag">
                <div className="headerLink">
                  <div className="iconHolder">
                    <FontAwesomeIcon icon="layer-group" size="1x" />
                  </div>
                  <div className="spanHolder">
                    <span>Combos</span>
                  </div>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/qrcodes" className="likTag">
                <div className="headerLink">
                  <div className="iconHolder">
                    <FontAwesomeIcon icon="qrcode" size="1x" />
                  </div>
                  <div className="spanHolder">
                    <span>Códigos QR</span>
                  </div>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/pos" className="likTag">
                <div className="headerLink">
                  <div className="iconHolder">
                    <FontAwesomeIcon icon="shopping-cart" size="1x" />
                  </div>
                  <div className="spanHolder">
                    <span>Puntos de Venta</span>
                  </div>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/notifications" className="likTag">
                <div className="headerLink">
                  <div className="iconHolder">
                    {/* <FontAwesomeIcon icon={['fa', 'bell']} size="1x" /> */}
                    <FontAwesomeIcon icon={faBell} size="1x" />
                  </div>
                  <div className="spanHolder">
                    <span>Notificaciones</span>
                  </div>
                </div>
              </Link>
            </li>
            <li>
              <div className="headerLink reportsHolderTitle">
                <div className="spanHolder reportsTitle">
                  <span>Reportes</span>
                </div>
              </div>
            </li>
            <li>
              <Link to="/salesbyproduct" className="likTag">
                <div className="headerLink">
                  <div className="iconHolder">
                    <FontAwesomeIcon icon="file" size="1x" />
                  </div>
                  <div className="spanHolder">
                    <span className="smallTitle">Ventas por producto</span>
                  </div>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/salesbyposproduct" className="likTag">
                <div className="headerLink">
                  <div className="iconHolder">
                    <FontAwesomeIcon icon="file" size="1x" />
                  </div>
                  <div className="spanHolder">
                    <span className="smallTitle">
                      Ventas por punto venta / producto
                    </span>
                  </div>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/salesbydate" className="likTag">
                <div className="headerLink">
                  <div className="iconHolder">
                    <FontAwesomeIcon icon="file" size="1x" />
                  </div>
                  <div className="spanHolder">
                    <span className="smallTitle">Totales por fecha</span>
                  </div>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/salesbyperiod" className="likTag">
                <div className="headerLink">
                  <div className="iconHolder">
                    <FontAwesomeIcon icon="file" size="1x" />
                  </div>
                  <div className="spanHolder">
                    <span className="smallTitle">Totales por período</span>
                  </div>
                </div>
              </Link>
            </li>
          </ul>
          <Link to="/logout" className="likTag">
            <div className="headerLink">
              <div className="iconHolder">
                <FontAwesomeIcon icon="sign-out-alt" size="1x" />
              </div>
              <div className="spanHolder">
                <span>Salir</span>
              </div>
            </div>
          </Link>
        </div>
      </div>
    );
  }
}

export default Header;
