import React from 'react';
import ReactDOM from 'react-dom/client';

import { Provider } from 'react-redux';
import ReduxThunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import WebFont from 'webfontloader';
// import './index.css';
import App from './components/App';
// import registerServiceWorker from './registerServiceWorker';
import reducers from './reducers';


// ReactDOM.render(
//     <Provider store={store}>
//         <App />
//     </Provider>,
//     document.getElementById('root')
// );
// registerServiceWorker();


import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';


WebFont.load({
  google: {
      families:[
          'Raleway', 'sans-serif'
      ]
  }
});

const store = createStore(
  reducers,
  {
      auth: {authenticated: localStorage.getItem('token')}
  },
  applyMiddleware(ReduxThunk));


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    {/* <React.StrictMode> */}
      <App />
    {/* </React.StrictMode> */}
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
