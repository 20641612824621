import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Bars } from  'react-loader-spinner'
import { updateCat, getCategory } from '../actions';
import Header from './Header';
import { withRouter } from './withRouter';

let self = null;
export class CategoryEdit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            description: '',
            showMsg: false,
        };

        self = this;
    }

    componentDidMount = () => {
        const { params } = this.props;
        this.props.getCategory(params.id,() => {
            this.setState({
                name: this.props.category.name,
                description: this.props.category.description
            });
        });
    }

    renderCategoryForm = () => {
        return (
            <div className="componentContainer">
                <Header />
                <div className="sectionContainer">
                    <div>
                        <div className="headerSection headerBooks">
                            <span>Edición de Categoria</span>
                        </div>

                    </div>
                    <div className="booksColumns">
                        <div>
                            {this.renderUpdateCatForm()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    handleNameChange = (e) => {
        this.setState({name: e.target.value});
    }

    handleDescriptionChange = (e) => {
        this.setState({description: e.target.value});
    }

    handleUpdateCat = () => {
        const { name, description } = this.state;
        const { params } = this.props;
        this.props.updateCat(params.id, name, description, () => {
            self.setState(
                {
                    showMsg: true
                },
                () => {
                    setTimeout(() => {
                        self.props.navigate('/categories');
                    }, 1000);
                }
            );
        });
    }

    handleCancelCat = () => {
        return () => {
            this.props.navigate('/categories');
        };
    }

    renderLoader = () => {
        if(this.props.updatingCategory){
            return <div className="newCatLoaderCont"><Bars color="#17a2b8" height={30} width={30} /></div>;
        }
    }

    renderUpdateCatMsgOK = () => {
        return this.props.updateCategoryOK ? <span className="catMsgOK">{this.props.updateCategoryOK}</span> : '';
    }

    renderUpdateCatMsgERROR = () => {
        return this.props.updateCategoryERROR ? <span className="catMsgERROR">{this.props.updateCategoryERROR}</span> : '';
    }

    renderUpdateCatForm = () => {
        if(this.props.loadingCategory){
            return <div className="newCatLoaderCont"><Bars color="#17a2b8" height={30} width={30} /></div>;
        }
        return (
            <div className="catForm">
                <div className="formGroup">
                    <label className="formLabel">Nombre</label>
                    <input
                        value={this.state.name}
                        type="text"
                        className="form-control"
                        placeholder="Nombre"
                        onChange={(e) => this.handleNameChange(e)}
                    />
                </div>
                <div className="formGroup">
                    <label className="formLabel">Descripción</label>
                    <input
                        value={this.state.description}
                        type="text"
                        className="form-control"
                        placeholder="Descripción"
                        onChange={(e) => this.handleDescriptionChange(e)}
                    />
                </div>
                <div className="formGroup btnGroup">
                    <button
                        className="btn btn-success"
                        onClick={this.handleUpdateCat}
                    >
                        Actualizar
                    </button>
                    <button
                        className="btn btn-danger"
                        onClick={this.handleCancelCat()}
                    >
                        Cancelar
                    </button>
                </div>
                <div className="formGroup">
                    {this.renderLoader()}
                    {this.state.showMsg ? this.renderUpdateCatMsgOK() : null}
                    {this.state.showMsg ? this.renderUpdateCatMsgERROR(): null}
                </div>
            </div>
        );
    }

    renderComponent() {
        return this.props.auth ? (
            this.renderCategoryForm()
        ) : (
           <Navigate
                to={{
                    pathname: '/login',
                    state: { from: this.props.location }
                }}
            />
        );
    }

    render() {
        return this.renderComponent();
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth.authenticated,
        updateCategoryOK: state.categories.updateCategoryOK,
        updateCategoryERROR: state.categories.updateCategoryERROR,
        updatingCategory: state.categories.updatingCategory,
        category: state.categories.category,
        loadingCategory: state.categories.loadingCategory,
    };
};

export default connect(mapStateToProps, {updateCat, getCategory})(withRouter(CategoryEdit));
